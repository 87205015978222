import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { axiosInstance } from '../../../axios';
import { Redirect } from 'react-router-dom';
import '../../../styles/register.scss';

export const ProviderQualifications = (props) => {
    const [workAuth, setWorkAuth] = useState(true);
    const [license, setLicense] = useState(true);
    const [services, setServices] = useState([true, true, true, true]);
    const [isLoading, setIsLoading] = useState(false);
    const [complete, setComplete] = useState(false);

    const toggleService = (idx) => {
        const copy = [...services];
        copy[idx] = !services[idx];
        setServices(copy);
    };

    const isFormSubmitable = () => {
        return services.includes(true) && license && workAuth;
    };

    const payload = () => {
        return {
            authorization: {
                licensing: license,
                work_authorization: workAuth,
            },
            services: {
                cleaning: services[0],
                xrays: services[1],
                whitening: services[2],
                scanning: services[3],
            },
        };
    };

    const submitForm = async () => {
        if (!isFormSubmitable()) {
            return;
        }
        setIsLoading(true);
        try {
            await axiosInstance.post(
                '/onboard/provider/qualifications',
                payload()
            );
            setComplete(true);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    };

    return (
        <div>
            {complete && <Redirect to="/register/provider/network" />}
            <div className="hu-hero">
                <p className="accent-underline">Step 2 of 3</p>
                <h3 className="is-bold pad-top-half">Qualifications</h3>
            </div>
            <div className="pad-top-double container">
                <div className="pad-bottom">
                    <h6 className="is-medium">
                        Please select all eligible services you can provide.{' '}
                    </h6>
                    <p className="is-small">
                        Note: You must have a valid in-state hygiene and
                        radiology license to apply as a Hu hygienist.
                    </p>
                    <FormGroup>
                        <FormControlLabel
                            label="Dental cleaning"
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={services[0]}
                                    onChange={() => toggleService(0)}
                                />
                            }></FormControlLabel>
                        <FormControlLabel
                            label="Dental x-rays"
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={services[1]}
                                    onChange={() => toggleService(1)}
                                />
                            }></FormControlLabel>
                        <FormControlLabel
                            label="Teeth whitening application"
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={services[2]}
                                    onChange={() => toggleService(2)}
                                />
                            }></FormControlLabel>
                        <FormControlLabel
                            label="Digital scanning"
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={services[3]}
                                    onChange={() => toggleService(3)}
                                />
                            }></FormControlLabel>
                    </FormGroup>
                </div>
                <div className="pad-bottom">
                    <h6 className="is-medium">
                        Do you have the required licensing to provide services
                        in your desired city?
                    </h6>
                    <FormGroup>
                        <FormControlLabel
                            label={
                                <div className="pad-top">
                                    <p className="is-medium">Yes</p>
                                    <p className="is-small">
                                        I have the required licensing to provide
                                        services in the state I am applying to.
                                    </p>
                                </div>
                            }
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={license}
                                    onChange={() => setLicense(true)}
                                />
                            }></FormControlLabel>
                        <FormControlLabel
                            label={
                                <div className="pad-top">
                                    <p className="is-medium">No</p>
                                    <p className="is-small">
                                        I do not have the required licensing to
                                        provide services in the state I am
                                        applying to.
                                    </p>
                                </div>
                            }
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={!license}
                                    onChange={() => setLicense(false)}
                                />
                            }></FormControlLabel>
                    </FormGroup>
                </div>
                <div className="pad-bottom">
                    <h6 className="is-medium">
                        Are you legally authorized to work in the United States?
                    </h6>
                    <FormGroup>
                        <FormControlLabel
                            label="Yes"
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={workAuth}
                                    onChange={() => setWorkAuth(true)}
                                />
                            }></FormControlLabel>
                        <FormControlLabel
                            label="No"
                            control={
                                <Checkbox
                                    className="arch-checkbox"
                                    checked={!workAuth}
                                    onChange={() => setWorkAuth(false)}
                                />
                            }></FormControlLabel>
                    </FormGroup>
                </div>
                <div className="bottom-buttons pad-bottom-double">
                    <div></div>
                    <div
                        className={`button is-primary ${
                            isLoading ? 'is-loading' : ''
                        }`}
                        onClick={submitForm}
                        disabled={!isFormSubmitable() || isLoading}>
                        Continue
                    </div>
                </div>
            </div>
        </div>
    );
};
