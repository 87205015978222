import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import { Footer } from '../shared/Footer';
import { QADropdown } from '../shared/QADropdown';
import { MailchimpField } from '../shared/MailchimpField';
import { BarButtonSelector } from '../profile/BarButtonSelector';
import { Route, Switch, Redirect } from 'react-router-dom';

export const FAQ = () => {
    return (
        <div id="landing-page">
            <Helmet>
                <title>YourTeeth</title>
            </Helmet>
            <Navbar links faq />
            <section className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="columns is-centered is-center-horizontal">
                            <div className="column is-6 is-full-mobile">
                                <h1 className="is-bold is-white is-text-centered">
                                    Frequently Asked Questions
                                </h1>
                                <h5 className="pad-top-half is-white is-text-centered">
                                    Can’t find an answer? Contact us at
                                    support@yourteeth.club for further support.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body main">
                <div className="container">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <BarButtonSelector
                                headings={[
                                    'YourTeeth Experience',
                                    'COVID-19 Safety Protocol',
                                ]}
                                urls={['/faq', '/faq/covid']}>
                                <Switch>
                                    <Route path="/faq" exact>
                                        <div className="pad-top">
                                            <h2 className="is-bold is-black is-text-centered">
                                                Before Your Visit
                                            </h2>
                                            <div className="pad-top pad-bottom">
                                                <QADropdown
                                                    first
                                                    title="What should I do before my visit?">
                                                    <p>
                                                        After filling out your
                                                        personal info, medical
                                                        history, and payment
                                                        information, all you
                                                        need to do is book your
                                                        appointment. Next,
                                                        simply upload pictures
                                                        of your smile for remote
                                                        review by our Clinical
                                                        Dental Board. No pre or
                                                        post appointment home
                                                        cleaning is necessary,
                                                        as our team is fully
                                                        trained in setting up
                                                        and cleaning up as
                                                        unobtrusively as
                                                        possible.
                                                    </p>
                                                </QADropdown>
                                                <QADropdown title="What services do you offer?">
                                                    <p>
                                                        Our most popular service
                                                        is the general cleaning
                                                        package which includes a
                                                        virtual yet personalized
                                                        dental assessment, and a
                                                        full dental cleaning. We
                                                        also offer the most
                                                        advanced whitening
                                                        technology to perfect
                                                        your smile.
                                                    </p>
                                                </QADropdown>
                                                <QADropdown title="Do you accept dental insurance?">
                                                    <p>
                                                        We currently do not
                                                        accept dental insurance.
                                                        At YourTeeth, we believe
                                                        that humans should treat
                                                        humans, not insurance
                                                        companies or big
                                                        corporations. Our care
                                                        and treatment
                                                        recommendations are
                                                        solely based on what is
                                                        best for you, the
                                                        patient, and not subject
                                                        to insurance guidelines
                                                        or constraints. This
                                                        freedom brings you the
                                                        highest level of care,
                                                        and is core to the
                                                        YourTeeth mission.
                                                    </p>
                                                </QADropdown>
                                                {/* <QADropdown title="Does Hu offer x-rays?">
                                                    <p>
                                                        We are planning on
                                                        rolling out our x-ray
                                                        service in early 2021.
                                                        If you are looking for
                                                        x-rays, add your email
                                                        to our mailing list, and
                                                        we'll get a Hu x-ray
                                                        professional over to you
                                                        as soon it launches.
                                                    </p>
                                                </QADropdown> */}
                                                <QADropdown
                                                    last
                                                    title="What is your cancellation policy?">
                                                    <p>
                                                        If you need to cancel or
                                                        reschedule an
                                                        appointment, please
                                                        provide notice at least
                                                        24 hours in advance of
                                                        the planned service time
                                                        to avoid a fee.
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To ensure the health and
                                                        safety of both our
                                                        clients and
                                                        Professionals, if you
                                                        are feeling ill please
                                                        cancel your appointment.
                                                        No notice is required
                                                        and the cancelation fee
                                                        will be waived.
                                                        Similarly, we are
                                                        requiring pros and
                                                        clients alike to wear
                                                        proper PPE during all
                                                        appointments. If a
                                                        client does not have a
                                                        mask and/or if a pro
                                                        arrives without the
                                                        proper required PPE, the
                                                        service will be
                                                        cancelled.
                                                    </p>
                                                </QADropdown>
                                            </div>
                                            <h2 className="is-bold is-black is-text-centered pad-top">
                                                During Your Visit
                                            </h2>
                                            <div className="pad-top pad-bottom">
                                                <QADropdown
                                                    first
                                                    title="What can I expect during my visit?">
                                                    <p>
                                                        The YourTeeth
                                                        professional you booked
                                                        will arrive with all of
                                                        the necessary equipment
                                                        to provide you with the
                                                        best quality care. Just
                                                        sit back and relax!
                                                    </p>
                                                </QADropdown>
                                                <QADropdown
                                                    last
                                                    title="How much room does my YourTeeth care team need to set up?">
                                                    <p>
                                                        All we need is a level
                                                        space eight feet in
                                                        length and four feet in
                                                        width :)
                                                    </p>
                                                </QADropdown>
                                            </div>
                                            <h2 className="is-bold is-black is-text-centered pad-top">
                                                After Your Visit
                                            </h2>
                                            <div className="pad-top pad-bottom">
                                                <QADropdown
                                                    first
                                                    title="What happens after my visit?">
                                                    <p>
                                                        You’ll be sent a
                                                        notification to review
                                                        your YourTeeth
                                                        professional as well as
                                                        given the option to tip
                                                        if you feel your
                                                        appointment surpassed
                                                        expectations. Your
                                                        appointment details will
                                                        be visible to you in
                                                        your “booking history,”
                                                        so you can always refer
                                                        back when booking a
                                                        future appointment.
                                                    </p>
                                                </QADropdown>
                                                <QADropdown title="What if I need more treatment?">
                                                    <p>
                                                        YourTeeth is partnering
                                                        with the highest quality
                                                        dental specialty offices
                                                        to provide our patients
                                                        with great rates if a
                                                        YourTeeth dentist
                                                        recommends that you see
                                                        a specialist.
                                                    </p>
                                                </QADropdown>
                                                <QADropdown
                                                    last
                                                    title="What if I am not satisfied with my experience?">
                                                    <p>
                                                        At YourTeeth, we offer a
                                                        100% satisfaction
                                                        guarantee. If you are
                                                        not satisfied with your
                                                        YourTeeth experience, we
                                                        will refund you 100%.
                                                        All we ask is for your
                                                        feedback to help us
                                                        improve in the future :)
                                                    </p>
                                                </QADropdown>
                                            </div>
                                            <h2 className="is-bold is-black is-text-centered pad-top">
                                                More Information
                                            </h2>
                                            <div className="pad-top pad-bottom">
                                                <QADropdown
                                                    first
                                                    title="Does a licensed dentist review and supervise my treatment?">
                                                    <p>
                                                        Your treatment will be
                                                        approved by our team of
                                                        highly-vetted, state
                                                        licensed dentists. Our
                                                        process ensures your
                                                        treatment aligns within
                                                        the “Standards of Care.”
                                                        For more details, you
                                                        can visit our blog posts{' '}
                                                        <a href="/blog">here</a>
                                                        .
                                                    </p>
                                                </QADropdown>
                                                <QADropdown title="Who are the treating dental professionals?">
                                                    <p>
                                                        YourTeeth partners with
                                                        verified, state-licensed
                                                        dental hygienists to
                                                        help provide dental
                                                        services to our
                                                        customers.
                                                    </p>
                                                </QADropdown>
                                            </div>
                                        </div>
                                    </Route>
                                    <Route path="/faq/covid" exact>
                                        <div className="pad-top">
                                            <h2 className="is-bold is-black is-text-centered">
                                                About COVID-19
                                            </h2>
                                            <div className="pad-top pad-bottom">
                                                <QADropdown
                                                    first
                                                    title="What is COVID-19?">
                                                    <p>
                                                        COVID-19 is a disease
                                                        caused by a novel
                                                        coronavirus called
                                                        SARS-CoV-2.{' '}
                                                        <a
                                                            target="_blank"
                                                            href="https://www.cdc.gov/coronavirus/2019-ncov/index.html"
                                                            className="hu-link">
                                                            Visit the CDC
                                                            website to learn
                                                            more.
                                                        </a>
                                                    </p>
                                                </QADropdown>
                                                <QADropdown
                                                    last
                                                    title="What is YourTeeth doing about the Coronavirus pandemic?">
                                                    <p>
                                                        YourTeeth is
                                                        continuously monitoring
                                                        updates on the pandemic
                                                        given by local
                                                        officials, the Centers
                                                        for Disease Control and
                                                        Prevention (CDC), and
                                                        the World Health
                                                        Organization (WHO).
                                                    </p>
                                                    <br />
                                                    <p>
                                                        As an at-home service,
                                                        we are able to ensure
                                                        maximum safety by
                                                        allowing patients to
                                                        remain in the safety of
                                                        their own home.
                                                    </p>
                                                </QADropdown>
                                            </div>
                                            <h2 className="is-bold is-black is-text-centered pad-top">
                                                Patients
                                            </h2>
                                            <div className="pad-top pad-bottom">
                                                <QADropdown
                                                    first
                                                    title="How does YourTeeth ensure patient safety?">
                                                    <p>
                                                        Our hygienists are
                                                        equipped with a
                                                        non-contact forehead
                                                        thermometer to monitor
                                                        their own health daily.
                                                        They use all the
                                                        appropriate PPE
                                                        including face
                                                        protection and nitrile
                                                        gloves to make sure that
                                                        any risks are mitigated
                                                        for the patient. All
                                                        dental equipment is
                                                        thoroughly sanitized
                                                        before and after every
                                                        appointment using
                                                        disinfectant wipes
                                                        followed by submersion
                                                        into a high temperature
                                                        dental sterilization
                                                        unit. We also advise
                                                        YourTeeth professionals
                                                        to cancel appointments
                                                        if they feel any COVID
                                                        symptoms.
                                                    </p>
                                                </QADropdown>
                                                <QADropdown
                                                    last
                                                    title="What if I have to cancel an appointment because of COVID-19?">
                                                    <p>
                                                        If you must cancel an
                                                        appointment due to
                                                        COVID-19, please cancel
                                                        your booking on your
                                                        account, and if within
                                                        24 hours of the
                                                        appointment, message our
                                                        staff to have the cost
                                                        100% refunded.
                                                    </p>
                                                </QADropdown>
                                            </div>
                                            <h2 className="is-bold is-black is-text-centered pad-top">
                                                Hygienists
                                            </h2>
                                            <div className="pad-top pad-bottom">
                                                <QADropdown
                                                    first
                                                    title="How does COVID-19 change the way hygienists care for patients?">
                                                    <p>
                                                        YourTeeth professionals
                                                        will be equipped with
                                                        face protection, nitrile
                                                        gloves, disinfectants
                                                        for tools, and anti
                                                        bacterial wipes to
                                                        ensure all equipment and
                                                        persons are thoroughly
                                                        cleaned and disinfected.
                                                        YourTeeth professionals
                                                        are advised to cancel
                                                        appointments if they
                                                        feel uncomfortable
                                                        performing for safety
                                                        reasons and to stay home
                                                        in the event of mild
                                                        illness, respiratory
                                                        symptoms, or fever.
                                                    </p>
                                                </QADropdown>
                                                <QADropdown
                                                    last
                                                    title="How does YourTeeth Health help keep hygienists safe?">
                                                    <p>
                                                        Currently, the company
                                                        has a 14-day “no
                                                        appointment policy” for
                                                        any patients who have
                                                        tested positive for
                                                        COVID-19, been in
                                                        contact with someone who
                                                        tested positive for
                                                        COVID-19, or has shown
                                                        symptoms of COVID-19.
                                                    </p>
                                                    <br />
                                                    <p>
                                                        Our hygienists are
                                                        equipped with
                                                        non-contact forehead
                                                        thermometers to measure
                                                        patient’s temperatures
                                                        before entering their
                                                        home.
                                                    </p>
                                                </QADropdown>
                                            </div>
                                        </div>
                                    </Route>
                                    <Route path="/faq">
                                        <Redirect to="/faq" />
                                    </Route>
                                </Switch>
                            </BarButtonSelector>
                            <h5 className="is-black is-text-centered pad-top-double">
                                Can’t find an answer? Contact us at
                                <strong> support@yourteeth.club</strong> for
                                further support.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="body grey">
                <div className="container pad-top-double pad-bottom-double">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-black is-text-centered">
                                Be the first to hear about the launch
                            </h1>
                            <h5 className="pad-top-half is-text-centered">
                                Join our wait list and receive priority access
                                to book your
                            </h5>
                            <h5 className="pad-top-half is-text-centered">
                                appointment through Hu!
                            </h5>
                            <MailchimpField />
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
};
