import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Home,
    User,
    Users,
    Activity,
    Award,
    DollarSign,
    Briefcase,
    MessageCircle,
    BookOpen,
    Folder,
} from 'react-feather';

import '../../styles/profile.scss';
import { axiosInstance } from '../../axios';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import AuthContext from '../../context/auth';
import { isAuthenticated, isProvider } from '../../auth';

export const ProfileSidebar = (props) => {
    return (
        <div className="profile-sidebar column is-2 is-narrow-mobile is-hidden-mobile">
            {isAuthenticated() &&
                (!isProvider() ? (
                    <div>
                        <ul className="menu-list">
                            <li>
                                <Link to="/profile">
                                    <div
                                        className={`profile-sidebar-item ${
                                            props.profile && 'selected'
                                        }`}>
                                        <Home />
                                        <span>
                                            <p>Profile</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <User />
                                        <span>
                                            <p>Personal Info</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <Activity />
                                        <span>
                                            <p>Dashboard</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <Award />
                                        <span>
                                            <p>Offers</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <DollarSign />
                                        <span>
                                            <p>Benefits</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <Briefcase />
                                        <span>
                                            <p>Training Library</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <MessageCircle />
                                        <span>
                                            <p>Community Forum</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <BookOpen />
                                        <span>
                                            <p>Continued Education</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile/network">
                                    <div
                                        className={`profile-sidebar-item ${
                                            props.network && 'selected'
                                        }`}>
                                        <Users />
                                        <span>
                                            <p>Network</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div>
                        <ul className="menu-list">
                            <li>
                                <Link to="/profile">
                                    <div
                                        className={`profile-sidebar-item ${
                                            props.profile && 'selected'
                                        }`}>
                                        <Home />
                                        <span>
                                            <p>Profile</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <User />
                                        <span>
                                            <p>Office Info</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <Activity />
                                        <span>
                                            <p>Dashboard</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <Folder />
                                        <span>
                                            <p>Documents</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile">
                                    <div className="profile-sidebar-item">
                                        <Briefcase />
                                        <span>
                                            <p>Training Library</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile/network">
                                    <div
                                        className={`profile-sidebar-item ${
                                            props.network && 'selected'
                                        }`}>
                                        <Users />
                                        <span>
                                            <p>Network</p>
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                ))}
        </div>
    );
};

export const SettingsSidebar = (props) => {
    const links = [{ name: 'Public Profile', link: '/profile/settings' }];

    const selected = props.selected || 0;
    return (
        <div className="profile-sidebar column is-2 is-narrow-mobile is-hidden-mobile">
            <ul className="menu-list">
                {links.map((item, idx) => (
                    <li key={item.name}>
                        <Link to={item.link}>
                            <div
                                className={`profile-sidebar-item ${
                                    idx === selected ? 'selected' : undefined
                                }`}>
                                {/* <User /> */}
                                <div>
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const TodoSidebar = (props) => {
    const [tasks, setTasks] = useState([]);
    const history = useHistory();

    const fetchTasks = async () => {
        try {
            const res = await axiosInstance.get('/profile/todo');
            setTasks(res.data);
        } catch (e) {}
    };

    useEffect(() => {
        fetchTasks();
    }, []);

    return (
        <div className="column is-3">
            <div className="todo-list-container margin-right-double card rounded">
                <h4 className="is-bold">Build Your Profile</h4>
                <ul className="pad-top">
                    {tasks.map((item, idx) => (
                        <li>
                            <Link to={item.link}>
                                <div className="todo-item">
                                    <FormControlLabel
                                        className={`${
                                            item.done
                                                ? 'item-completed'
                                                : undefined
                                        }`}
                                        onClick={() => {}}
                                        control={
                                            <Checkbox
                                                checked={item.done}
                                                className="arch-checkbox"
                                            />
                                        }
                                        label={item.name}
                                    />
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
