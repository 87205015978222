import React, { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { axiosInstance } from '../../axios';
import {
    User,
    Clock,
    Settings,
    HelpCircle,
    LogOut,
    DollarSign,
} from 'react-feather';
import {
    isAuthenticated,
    logout,
    authPhoto,
    authFirst,
    authLast,
    isProvider,
} from '../../auth';

export const Navbar = (props) => {
    const signUp = props.signUp;
    const { filled, links } = props;
    const [menu, setMenu] = useState(false);
    const [onLogout, setOnLogout] = useState(false);
    const history = useHistory();

    const handleLogout = async () => {
        try {
            await axiosInstance.get('/auth/logout');
            logout();
            history.push('/');
        } catch (e) {}
    };

    const handlePaymentPress = async () => {
        try {
            const res = await axiosInstance.get('/payments/manage');
            console.log(res.data.url);
            window.location.assign(res.data.url);
        } catch (e) {
            alert('An error occurred, please try again later.');
        }
    };

    return (
        <nav
            className={`navbar ${!filled && 'is-transparent'} ${
                props.dark && `dark`
            }`}>
            {onLogout && <Redirect to="/" />}
            <div className="navbar-brand">
                <div className="navbar-item">
                    <Link to="/">
                        <h4 className="is-bold">YourTeeth</h4>
                    </Link>
                </div>
                <div
                    className={`navbar-burger ${menu ? 'is-active' : ''}`}
                    aria-label="menu"
                    aria-expanded="false"
                    onClick={() => setMenu(!menu)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>
            </div>
            <div className={`navbar-menu ${menu ? 'is-active' : ''}`}>
                {links && !isAuthenticated() && (
                    <div className="navbar-start">
                        <div
                            className={`navbar-item ${
                                props.services && 'is-bold'
                            }`}>
                            <Link to="/services">Services</Link>
                        </div>
                        <div
                            className={`navbar-item ${
                                props.browse && 'is-bold'
                            }`}>
                            <Link to="/browsehygienists">
                                Browse Hygienists
                            </Link>
                        </div>
                        <div
                            className={`navbar-item ${
                                props.whyhu && 'is-bold'
                            }`}>
                            <Link to="/whyhu">Why YourTeeth?</Link>
                        </div>
                        <div
                            className={`navbar-item ${props.faq && 'is-bold'}`}>
                            <Link to="/faq">FAQ</Link>
                        </div>
                        <div
                            className={`navbar-item ${
                                props.forhygienists && 'is-bold'
                            }`}>
                            <Link to="/forhygienists">For Hygienists</Link>
                        </div>
                        <div
                            className={`navbar-item ${
                                props.blog && 'is-bold'
                            }`}>
                            <Link to="/blog">Blog</Link>
                        </div>
                    </div>
                )}
                {isAuthenticated() &&
                    (isProvider() ? (
                        <div className="navbar-start">
                            <div
                                className={`navbar-item ${
                                    props.appts && 'is-bold'
                                }`}>
                                <Link to="/appointments">Appointments</Link>
                            </div>
                            <div
                                className={`navbar-item ${
                                    props.banking && 'is-bold'
                                }`}>
                                <Link to="/">Banking</Link>
                            </div>
                            <div
                                className={`navbar-item ${
                                    props.reviews && 'is-bold'
                                }`}>
                                <Link to="/">Reviews</Link>
                            </div>
                            <div
                                className={`navbar-item ${
                                    props.community && 'is-bold'
                                }`}>
                                <Link to="/">Community</Link>
                            </div>
                            <div
                                className={`navbar-item ${
                                    props.referrals && 'is-bold'
                                }`}>
                                <Link to="/">Referrals</Link>
                            </div>
                        </div>
                    ) : (
                        <div className="navbar-start">
                            <div
                                className={`navbar-item ${
                                    props.appts && 'is-bold'
                                }`}>
                                <Link to="/appointments">Appointments</Link>
                            </div>
                            <div
                                className={`navbar-item ${
                                    props.referrals && 'is-bold'
                                }`}>
                                <Link to="/">Referrals</Link>
                            </div>
                        </div>
                    ))}
                <div className="navbar-end">
                    {isAuthenticated() ? (
                        <div>
                            <div className="navbar-item has-dropdown margin-right-double margin-left is-hoverable is-hidden-mobile">
                                <div
                                    className="navbar-profile-img margin-top-small center-flex"
                                    style={{
                                        backgroundImage: `url(${authPhoto()})`,
                                        alignItems: 'center',
                                    }}>
                                    {!authPhoto() && (
                                        <div style={{ color: 'black' }}>
                                            {authFirst()
                                                .charAt(0)
                                                .toUpperCase()}
                                            {authLast().charAt(0).toUpperCase()}
                                        </div>
                                    )}
                                </div>

                                <div className="navbar-dropdown is-right is-boxed">
                                    <div className="navbar-item name-dropdown-item margin-bottom-double">
                                        <div
                                            className="center-flex navbar-profile-img"
                                            style={{
                                                backgroundImage: `url(${authPhoto()})`,
                                                alignItems: 'center',
                                            }}>
                                            {!authPhoto() && (
                                                <div style={{ color: 'black' }}>
                                                    {authFirst()
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                    {authLast()
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                </div>
                                            )}
                                        </div>
                                        <p className="is-medium pad-left">
                                            {authFirst()} {authLast()}
                                        </p>
                                    </div>
                                    <hr className="dropdown-divider" />
                                    <Link
                                        to={
                                            isProvider()
                                                ? '/profile'
                                                : '/appointments'
                                        }>
                                        <div className="navbar-item dropdown-item">
                                            <User
                                                className="margin-right-half"
                                                size={18}
                                            />
                                            <p>My Account</p>
                                        </div>
                                    </Link>
                                    {isProvider() && (
                                        <div>
                                            <Link to="/availability">
                                                <div className="navbar-item dropdown-item">
                                                    <Clock
                                                        className="margin-right-half"
                                                        size={18}
                                                    />
                                                    <p>Edit Availability</p>
                                                </div>
                                            </Link>
                                            <Link to="/profile/settings">
                                                <div className="navbar-item dropdown-item">
                                                    <Settings
                                                        className="margin-right-half"
                                                        size={18}
                                                    />
                                                    <p>Settings and Privacy</p>
                                                </div>
                                            </Link>
                                            <div
                                                className="navbar-item dropdown-item"
                                                onClick={handlePaymentPress}>
                                                <DollarSign
                                                    className="margin-right-half"
                                                    size={18}
                                                />
                                                <p>Payments</p>
                                            </div>
                                        </div>
                                    )}

                                    <Link to="/">
                                        <div className="navbar-item dropdown-item">
                                            <HelpCircle
                                                className="margin-right-half"
                                                size={18}
                                            />
                                            <p>Help and Support</p>
                                        </div>
                                    </Link>
                                    <div
                                        className="navbar-item dropdown-item"
                                        onClick={handleLogout}>
                                        <LogOut
                                            className="margin-right-half"
                                            size={18}
                                        />
                                        <p>Logout</p>
                                    </div>
                                </div>
                            </div>
                            <div className="is-hidden-tablet">
                                <hr className="dropdown-divider" />
                                <div className="navbar-item name-dropdown-item">
                                    <div
                                        className="pad-right navbar-profile-img"
                                        style={{
                                            backgroundImage: `url(${authPhoto()})`,
                                        }}>
                                        {' '}
                                        {!authPhoto() && (
                                            <div style={{ color: 'black' }}>
                                                {authFirst()
                                                    .charAt(0)
                                                    .toUpperCase()}
                                                {authLast()
                                                    .charAt(0)
                                                    .toUpperCase()}
                                            </div>
                                        )}
                                    </div>
                                    <p className="is-medium pad-left">
                                        {authFirst()} {authLast()}
                                    </p>
                                </div>
                                <hr className="dropdown-divider" />
                                <Link
                                    to={
                                        isProvider()
                                            ? '/profile'
                                            : '/appointments'
                                    }>
                                    <div className="navbar-item dropdown-item">
                                        <User
                                            className="margin-right-half"
                                            size={18}
                                        />
                                        <p>My Account</p>
                                    </div>
                                </Link>
                                {isProvider() && (
                                    <Link to="/availability">
                                        <div className="navbar-item dropdown-item">
                                            <Clock
                                                className="margin-right-half"
                                                size={18}
                                            />
                                            <p>Edit Availability</p>
                                        </div>
                                    </Link>
                                )}
                                <Link to="/">
                                    <div className="navbar-item dropdown-item">
                                        <Settings
                                            className="margin-right-half"
                                            size={18}
                                        />
                                        <p>Settings and Privacy</p>
                                    </div>
                                </Link>
                                <Link to="/">
                                    <div className="navbar-item dropdown-item">
                                        <HelpCircle
                                            className="margin-right-half"
                                            size={18}
                                        />
                                        <p>Help and Support</p>
                                    </div>
                                </Link>
                                <div
                                    className="navbar-item dropdown-item"
                                    onClick={handlePaymentPress}>
                                    <DollarSign
                                        className="margin-right-half"
                                        size={18}
                                    />
                                    <p>Payments</p>
                                </div>

                                <div
                                    className="navbar-item dropdown-item"
                                    onClick={handleLogout}>
                                    <LogOut
                                        className="margin-right-half"
                                        size={18}
                                    />
                                    <p>Logout</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="buttons margin-right">
                            <div className="navbar-item">
                                <Link to="/login">
                                    <div>Log in</div>
                                </Link>
                            </div>
                            <div className="navbar-item no-pad-right">
                                <Link to="/appointment/book">
                                    <div
                                        className={`button ${
                                            props.dark
                                                ? 'is-info'
                                                : 'is-primary'
                                        }`}>
                                        BOOK NOW
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};
