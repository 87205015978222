import React from 'react';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

export const Services = (props) => {
    const [patients, setPatients] = useState(props.patients);
    const [idx, setIdx] = useState(0);
    const [completed, setCompleted] = useState(false);
    const city = 'Philadelphia, PA';

    const history = useHistory();

    if (!props.patients || props.patients.length === 0) {
        history.push('/appointment/book/patients');
        return null;
    }

    const selectService = (service) => {
        const copy = [...patients];
        copy[idx].service = service;
        setPatients(copy);

        if (idx + 1 === patients.length) {
            props.onServiceSelect(patients);
            setCompleted(true);
            return;
        }

        setIdx(idx + 1);
    };

    return (
        <div>
            {completed && <Redirect to="/appointment/book/details" />}
            <div>
                <div className="hu-hero">
                    <p className="accent-underline">Step 2 of 4</p>
                    <h3 className="is-bold pad-top-half">
                        Which services would you like to book for{' '}
                        {patients[idx].first}?
                    </h3>
                </div>
                <div className="pad-top-double container pad-bottom-double">
                    <p className="is-bold center-align">CITY</p>
                    <div className="columns">
                        <div className="column is-3 is-full-mobile center">
                            <fieldset disabled>
                                <input
                                    className="input margin-top"
                                    value={city}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <h4 className="is-medium center-align pad-bottom">
                        Early Access Patient Special
                    </h4>
                    <div className="columns">
                        <div className="column">
                            <div
                                className="card rounded border hoverable padded"
                                onClick={() => selectService('cleaning')}>
                                <div
                                    className="center-flex"
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                    <img
                                        className="img-fluid"
                                        style={{ maxHeight: '200px' }}
                                        src="../../img/doctors.svg"
                                        alt="Arch Dental"
                                    />
                                    <h5 className="is-bold pad-top center-align">
                                        Professional dental cleaning
                                    </h5>
                                    <p>
                                        <div className="is-medium slash-wrapper">
                                            <div className="slash"></div>
                                            <p className="is-medium">$199 </p>
                                        </div>{' '}
                                        $99 | 60 min
                                    </p>
                                    <p className="center-align pad-top">
                                        Our most simple, yet rewarding treatment
                                        - a convenient teeth cleaning with a
                                        YT-verified hygienist. Say goodbye to
                                        yucky plaque and calculus, and hello to
                                        a rejuvinated, polished smile.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div
                                className="card rounded border hoverable padded"
                                onClick={() => selectService('whitening')}>
                                <div
                                    className="center-flex"
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                    <img
                                        className="img-fluid"
                                        style={{ maxHeight: '200px' }}
                                        src="../../img/smileyface.svg"
                                        alt="Arch Dental"
                                    />
                                    <h5 className="is-bold pad-top center-align">
                                        Professional teeth whitening
                                    </h5>
                                    <p>
                                        <div className="is-medium slash-wrapper">
                                            <div className="slash"></div>
                                            <p className="is-medium">$299 </p>
                                        </div>{' '}
                                        $199 | 60 min
                                    </p>
                                    <p className="center-align pad-top">
                                        An elevated whitening experience with
                                        Opalesence whitening gel so you can
                                        shine your brightest smile for life’s
                                        biggest moments.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div
                                className="card rounded border hoverable padded"
                                onClick={() => selectService('bundle')}>
                                <div
                                    className="center-flex"
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}>
                                    <img
                                        className="img-fluid"
                                        style={{ maxHeight: '200px' }}
                                        src="../../img/bundle.svg"
                                        alt="Arch Dental"
                                    />
                                    <h5 className="is-bold pad-top center-align">
                                        Mint Bundle
                                    </h5>
                                    <p>
                                        <div className="is-medium slash-wrapper">
                                            <div className="slash"></div>
                                            <p className="is-medium">$399 </p>
                                        </div>{' '}
                                        $249 | 120 min
                                    </p>
                                    <p className="center-align pad-top">
                                        Our two most popular services combined
                                        into one appointment. Get a professional
                                        cleaning and whitening all in 120
                                        minutes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
