import Image from 'react-image-enlarger';
import React from 'react';

export const imgStyle = {
    width: '200px',
    marginRight: '20px',
    marginBottom: '20px',
    paddingLeft: '0px',
    paddingRight: '0px',
    height: '200px',
    borderRadius: '10px',
    backgroundColor: 'lightgrey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    position: 'relative',
};

export const ArchImage = (props) => {
    const [zoomed, setZoomed] = React.useState(false);

    return (
        <Image
            style={props.style}
            zoomed={zoomed}
            src={props.src}
            className={props.className}
            alt={props.alt}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
        />
    );
};
