import React, { useState, useEffect } from 'react';
import { axiosInstance, baseURL } from '../../axios';

export const FileUpload = (props) => {
    const imageUploader = React.useRef(null);

    const [file, setFile] = useState();

    const handleImageUpload = (e) => {
        const [file] = e.target.files;
        if (file) {
            if (props.maxSize) {
                if (file.size > props.maxSize * 1048576) {
                    alert(
                        `Your file exceeds the maximum upload size of ${props.maxSize} MB`
                    );
                    return;
                }
            }
            setFile(file);
        }
    };

    const upload = async () => {
        const formData = new FormData();
        formData.append('file', file);

        if (props.onUploadStart) {
            props.onUploadStart(file.name);
        }

        try {
            const res = await axiosInstance.post('/upload', formData);
            const { filename } = res.data;

            if (props.url) {
                await axiosInstance.post(`${props.url}${filename}`);
            }

            props.onSuccess(
                `https://arch-dental.s3.us-east-2.amazonaws.com/${filename}`
            );
        } catch (e) {
            console.log(e.response);
            if (props.onError) {
                props.onError(e.response);
            }
            alert('An error occurred while uploading. Please try again later.');
        }
    };

    useEffect(() => {
        if (file) {
            upload();
        }
    }, [file]);

    return (
        <div>
            <input
                type="file"
                allow={props.allow || 'image/*'}
                multiple={props.multiple}
                ref={imageUploader}
                onChange={handleImageUpload}
                style={{ display: 'none' }}
            />
            <div onClick={() => imageUploader.current.click()}>
                {props.children}
            </div>
        </div>
    );
};
