import axios from 'axios';
export const baseURL = 'https://api.yourteeth.club';
export const testURL = 'http://localhost:8000';

export const currentUrl = baseURL;

export const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
});
