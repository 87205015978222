import React, { useState, useEffect, useRef } from 'react';
import { axiosInstance } from '../axios';

export const ExperienceForm = (props) => {
    const [clinicName, setClinicName] = useState('');
    const [startMonth, setStartMonth] = useState();
    const [startYear, setStartYear] = useState();
    const [endMonth, setEndMonth] = useState();
    const [endYear, setEndYear] = useState();
    const [type, setType] = useState('');

    const yearFields = () => {
        var years = [];
        const current = new Date().getFullYear();
        for (var i = 0; i < current - 1900; i++) {
            years.push(
                <option key={current - i} value={current - i}>
                    {current - i}
                </option>
            );
        }
        return years;
    };

    return (
        <div className="experience-form pad-top">
            <label className="label">Clinic Name</label>
            <div className="field">
                <div className="control is-expanded">
                    <input
                        className={`input`}
                        type="text"
                        placeholder="Clinic Name"
                        value={clinicName}
                        onChange={(e) => setClinicName(e.target.value)}
                    />
                    {/* {props.validate && !clinicName && <p className='help is-danger'>Please enter your clinic name.</p>} */}
                </div>
            </div>
            <label className="label pad-top">Years Worked</label>
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <div className="field is-grouped">
                            <div className="control select">
                                <select
                                    name="startMonth"
                                    value={startMonth ? startMonth : '0'}
                                    onChange={(e) =>
                                        setStartMonth(e.target.value)
                                    }>
                                    <option value="0" disabled>
                                        Month
                                    </option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </div>
                            <div className="control select">
                                <select
                                    name="startYear"
                                    value={startYear ? startYear : '0'}
                                    onChange={(e) =>
                                        setStartYear(e.target.value)
                                    }>
                                    <option value="0" disabled>
                                        Year
                                    </option>
                                    {yearFields()}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="level-item">
                        <div>to</div>
                    </div>
                    <div className="level-item">
                        <div className="field is-grouped">
                            <div className="control select">
                                <select
                                    name="endMonth"
                                    value={endMonth ? endMonth : '0'}
                                    onChange={(e) =>
                                        setEndMonth(e.target.value)
                                    }>
                                    <option value="0" disabled>
                                        Month
                                    </option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </div>
                            <div className="control select">
                                <select
                                    name="endYear"
                                    value={endYear ? endYear : '0'}
                                    onChange={(e) =>
                                        setEndYear(e.target.value)
                                    }>
                                    <option value="0" disabled>
                                        Year
                                    </option>
                                    {yearFields()}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <label className="label pad-top">Type of Experience</label>
            <div className="field control is-expanded">
                <input
                    className={`input`}
                    type="text"
                    placeholder="General, Orthodontics, etc."
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                />
                {/* {validated && !lastName && <p className='help is-danger'>Please enter your clinic name.</p>} */}
            </div>
            <div
                className="margin-top remove-experience"
                onClick={() => props.onRemove(props.idx)}>
                &mdash; Remove this experience
            </div>
            <div className="pad-top"></div>
        </div>
    );
};

export const DateOfBirthField = (props) => {
    var initmonth = '1';
    var initday = '1';
    var inityear = new Date().getFullYear();

    const [month, setMonth] = useState(
        new Date(props.date).getUTCMonth() || initmonth
    );
    const [day, setDay] = useState(new Date(props.date).getUTCDay() || initday);
    const [year, setYear] = useState(
        new Date(props.date).getUTCFullYear() || inityear
    );

    useEffect(() => {
        const date = new Date(year, month - 1, day);
        props.onDateUpdate(date);
    }, [month, day, year]);

    const dayFields = (month, year) => {
        var days = [];
        const count = new Date(year, month, 0).getDate();
        for (var i = 0; i < count; i++) {
            days.push(
                <option key={i} value={i + 1}>
                    {i + 1}
                </option>
            );
        }
        return days;
    };

    const yearFields = () => {
        var years = [];
        const current = new Date().getFullYear();
        for (var i = 0; i < current - 1900; i++) {
            years.push(
                <option key={current - i} value={current - i}>
                    {current - i}
                </option>
            );
        }
        return years;
    };

    return (
        <div>
            {!props.hideHeader && (
                <label className="label">Date of Birth</label>
            )}
            <div className="field is-grouped">
                <div className="control select">
                    <select
                        name="month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
                <div className="control select">
                    <select
                        name="date"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}>
                        {dayFields(month, year)}
                    </select>
                </div>
                <div className="control select">
                    <select
                        name="year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}>
                        {yearFields()}
                    </select>
                </div>
            </div>
        </div>
    );
};

const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
};
// Required Props
//onSelect: function called when the user makes a selection
//url: url to GET results from (must include everything up to the actual value (?name=))
//Optional Props:
//onUpdate: function called when input changes
//blockUserInput: boolean dictating if user has to select a result or can use their own input
export const AutocompleteField = (props) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const ref = useRef();

    useOutsideClick(ref, () => {
        setResults([]);
    });

    useEffect(() => {
        props.onSelect(query);
    }, [query]);

    const updateQuery = async (q) => {
        setQuery(q);
        setResults([]);
        if (q.length > 2) {
            const res = await axiosInstance.get(`${props.url}${q}`);
            setResults(res.data);
        }
    };

    return (
        <div className="clinic-item" ref={ref}>
            <div className="field">
                <div
                    className={`dropdown ${results.length > 0 && 'is-active'}`}>
                    <div className="dropdown-trigger">
                        <div className="field">
                            <div className="control is-expanded">
                                <input
                                    className="input"
                                    type="search"
                                    value={query}
                                    onChange={(e) => {
                                        updateQuery(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            !props.blockUserInput
                                        ) {
                                            setResults([]);
                                        }
                                    }}
                                    placeholder="Select Clinic"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dropdown-menu" role="menu">
                        <div
                            className="dropdown-content"
                            style={{
                                display: `${
                                    results.length === 0 ? 'none' : 'block'
                                }`,
                            }}>
                            {results.map((item, idx) => (
                                <div
                                    className="dropdown-item"
                                    key={item}
                                    onClick={() => {
                                        setQuery(item[1]);
                                        setResults([]);
                                    }}>
                                    {item[1]}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const AutocompleteFieldNoFetch = (props) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const ref = useRef();

    useOutsideClick(ref, () => {
        setResults([]);
    });

    const updateQuery = async (q) => {
        setQuery(q);
        setResults([]);
        setResults(
            props.options.filter((item) =>
                item.toLowerCase().includes(q.toLowerCase())
            )
        );
    };

    useEffect(() => {
        if (props.onSelect) {
            props.onSelect(query);
        }

        if (props.onUpdate) {
            props.onUpdate(query);
        }
    }, [query]);

    return (
        <div className="clinic-item" ref={ref}>
            <div className="field">
                <div
                    className={`dropdown ${results.length > 0 && 'is-active'}`}>
                    <div className="dropdown-trigger">
                        <div className="field">
                            <div className="control is-expanded">
                                <input
                                    className="input"
                                    type="search"
                                    value={query}
                                    onChange={(e) => {
                                        updateQuery(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setResults([]);
                                        }
                                    }}
                                    placeholder={props.placeholder}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dropdown-menu" role="menu">
                        <div
                            className="dropdown-content"
                            style={{
                                display: `${
                                    results.length === 0 ? 'none' : 'block'
                                }`,
                            }}>
                            {results.map((item, idx) => (
                                <div
                                    className="dropdown-item"
                                    key={idx}
                                    onClick={(e) => {
                                        setQuery(item);
                                        setResults([]);
                                    }}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const AutocompleteLocationField = (props) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [location, setLocation] = useState();

    const ref = useRef();

    useOutsideClick(ref, () => {
        setResults([]);
    });

    useEffect(() => {
        props.onSelect(location);
    }, [location]);

    const updateQuery = async (q) => {
        setQuery(q);
        setResults([]);
        if (q.length > 2) {
            const res = await axiosInstance.get(
                `/onboard/locations?location=${q}`
            );
            setResults(res.data);
        }
    };

    return (
        <div className="clinic-item" ref={ref}>
            <div className="field">
                <div
                    className={`dropdown ${results.length > 0 && 'is-active'}`}>
                    <div className="dropdown-trigger">
                        <div className="field">
                            <div className="control is-expanded">
                                <input
                                    className="input"
                                    type="search"
                                    value={query}
                                    onChange={(e) => {
                                        updateQuery(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            !props.blockUserInput
                                        ) {
                                            setResults([]);
                                        }
                                    }}
                                    placeholder="Address"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="dropdown-menu" role="menu">
                        <div
                            className="dropdown-content"
                            style={{
                                display: `${
                                    results.length === 0 ? 'none' : 'block'
                                }`,
                            }}>
                            {results.map((item, idx) => (
                                <div
                                    className="dropdown-item"
                                    key={item.address}
                                    onClick={() => {
                                        setQuery(item.address);
                                        setLocation(item);
                                        setResults([]);
                                    }}>
                                    {item.address}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
