import React from 'react';
import ReactPlayer from 'react-player';
import { useState } from 'react';
import { axiosInstance } from '../../axios';
import { useEffect } from 'react';
import { FileUpload } from '../profile/Uploaders';
import { authUsername } from '../../auth';
import { ArchImage, imgStyle } from '../shared/Image';

export const MediaGallery = (props) => {
    const username = props.username;
    const [videoGallery, setVideoGallery] = useState([]);
    const [photoGallery, setPhotoGallery] = useState([]);

    const fetchGallery = async () => {
        try {
            const res = await axiosInstance.get(
                `/profile/gallery?username=${props.username || authUsername()}`
            );
            const { photos } = res.data;
            setPhotoGallery(photos);
            const res2 = await axiosInstance.get(
                `/profile/video?username=${props.username || authUsername()}`
            );
            const { videos } = res2.data;
            setVideoGallery(videos);
        } catch (e) {
            console.log(e.response);
        }
    };

    useEffect(() => {
        fetchGallery();
    }, []);

    return (
        <div>
            <h5 className="is-bold is-black">{'Media'}</h5>
            <div className="photo-gallery pad-top">
                {photoGallery &&
                    photoGallery.length > 0 &&
                    photoGallery.map((item, idx) => (
                        <ArchImage style={imgStyle} src={item} />
                        // <div
                        //     className="photo-img-container"
                        //     style={{
                        //         backgroundImage: `url(${item})`,
                        //     }}
                        //     alt="photo-gallery"></div>
                    ))}
                {videoGallery &&
                    videoGallery.length > 0 &&
                    videoGallery.map((item, idx) => (
                        <div className="photo-img-container">
                            <ReactPlayer
                                url={item}
                                controls
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export const VideoGalleryCard = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const deletePhoto = async (idx) => {
        props.onDelete(idx);
        await axiosInstance.delete(`/profile/gallery?idx=${idx}`);
    };

    return (
        <div className="card rounded">
            <div className="card-content">
                <h5 className="is-bold">Videos</h5>
                <div className="photo-gallery">
                    {props.video &&
                        props.video.length > 0 &&
                        props.video.map((item, idx) => (
                            <div className="gallery-item pad-top profile-video-container">
                                <ReactPlayer url={item} controls width="100%" />
                                {
                                    <span
                                        className="icon icon-hover"
                                        onClick={() => deletePhoto(idx)}>
                                        <i className="material-icons">delete</i>
                                    </span>
                                }
                            </div>
                        ))}
                    <div className="arch-dropzone-video">
                        <span className="icon icon-large">
                            <i className="material-icons">cloud_upload</i>
                        </span>
                        <p className="pad-top">Browse for Files</p>
                        <FileUpload
                            url="/profile/video?name="
                            onSuccess={(item) => {
                                setIsLoading(false);
                                props.onSuccess(item);
                            }}
                            onError={() => {
                                setIsLoading(false);
                            }}
                            maxSize={300}
                            onUploadStart={() => setIsLoading(true)}>
                            <div
                                className={`button is-primary margin-top ${
                                    isLoading && 'is-loading'
                                }`}>
                                Upload Video
                            </div>
                        </FileUpload>
                    </div>
                </div>
            </div>
        </div>
    );
};
