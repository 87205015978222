import React, { useState, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import moment from 'moment';
import { axiosInstance } from '../../axios';
import { useHistory } from 'react-router-dom';

export const AppointmentBookingCard = (props) => {
    const [date, setDate] = useState(moment().startOf('day').toDate());
    const [appointments, setAppts] = useState([]);
    const history = useHistory();

    const onDateSelect = (d) => {
        setDate(d);
    };

    const fetchAvailability = async () => {
        try {
            const res = await axiosInstance.get(
                `profile/availability/appointments?date=${moment(
                    date
                ).format()}&username=${props.username}`
            );
            setAppts(res.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAvailability();
    }, [date]);

    const parseMinFromMid = (min) => {
        let hours = Math.floor(min / 60);
        const mins = min - hours * 60;
        const am = hours < 12;
        hours = hours === 0 ? 12 : hours;

        return `${am ? hours : hours - 12 == 0 ? 12 : hours - 12}:${
            mins < 10 ? '0' + mins : mins
        } ${am ? 'AM' : 'PM'}`;
    };

    return (
        <div className="card rounded border">
            <div className="card-content">
                <h4 className="is-bold">Availability</h4>
                <Calendar value={date} onChange={onDateSelect} />
                {appointments.map((item) => (
                    <div className="pad-top-half">
                        <h6 className="is-medium">
                            {moment(item.date).format('dddd, MMMM Do')}
                        </h6>
                        <div className="buttons pad-top-half">
                            {item.start_times.map((start) => (
                                <div
                                    className="button is-info is-outlined is-small"
                                    onClick={() =>
                                        history.push('/appointment/book')
                                    }>
                                    {parseMinFromMid(start)}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
