import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import { Footer } from '../shared/Footer';
import { MailchimpField } from '../shared/MailchimpField';

export const Services = () => {
    return (
        <div id="landing-page">
            <Helmet>
                <title>YourTeeth</title>
            </Helmet>
            <Navbar links services={true} />
            <section className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="columns is-centered is-center-horizontal">
                            <div className="column is-6 is-full-mobile">
                                <h1 className="is-bold is-white is-text-centered">
                                    Our Services
                                </h1>
                                <h5 className="pad-top-half is-white is-text-centered">
                                    We offer three essential services and two
                                    premium plans.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body main">
                <div className="container">
                    <h4 className="is-bold center-align">
                        Early Access Patient Special
                    </h4>
                    <div className="columns pad-top-double">
                        <div className="column is-center-vertical">
                            <div>
                                <div className="rounded-rectangle is-main">
                                    <p className="is-bold">MOST POPULAR</p>
                                </div>
                                <div className="center column-img pad-top-double">
                                    <img
                                        className="img-fluid"
                                        src="img/doctors.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Professional Dental Cleaning
                                </h4>
                                <h5 className="is-bold">
                                    <div className="is-medium slash-wrapper">
                                        <div
                                            className="slash"
                                            style={{ top: '16px' }}></div>
                                        <h5>$199 </h5>
                                    </div>{' '}
                                    $99 | 60 min
                                </h5>
                                <p className="pad-top-half pad-bottom left-align is-black">
                                    Our most simple, yet rewarding treatment - a
                                    convenient teeth cleaning with a YT-verified
                                    hygienist. Say goodbye to yucky plaque and
                                    calculus, and hello to a rejuvinated,
                                    polished smile.
                                </p>
                                <Link to="/appointment/book">
                                    <div className="button is-primary">
                                        BOOK NOW
                                    </div>
                                </Link>
                                <p className="pad-top left-align">
                                    <i>
                                        * The American Dental Association
                                        recommends dental visits and exams every
                                        six months.
                                    </i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-tablet">
                        <div className="column is-center-vertical">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/smileyface.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Professional Teeth Whitening
                                </h4>
                                <h5 className="is-bold">
                                    <div className="is-medium slash-wrapper">
                                        <div
                                            className="slash"
                                            style={{ top: '16px' }}></div>
                                        <h5>$299 </h5>
                                    </div>{' '}
                                    $199 | 60 min
                                </h5>
                                <p className="pad-top-half pad-bottom left-align is-black">
                                    An elevated whitening experience with
                                    Opalesence whitening gel so you can shine
                                    your brightest smile for life’s biggest
                                    moments.
                                </p>
                                <Link to="/appointment/book">
                                    <div className="button is-primary">
                                        BOOK NOW
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-mobile">
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Professional Teeth Whitening
                                </h4>
                                <h5 className="is-bold">
                                    <div className="is-medium slash-wrapper">
                                        <div
                                            className="slash"
                                            style={{ top: '16px' }}></div>
                                        <h5>$299 </h5>
                                    </div>{' '}
                                    $199 | 60 min
                                </h5>
                                <p className="pad-top-half pad-bottom left-align is-black">
                                    An elevated whitening experience with
                                    Opalesence whitening gel so you can shine
                                    your brightest smile for life’s biggest
                                    moments.
                                </p>
                                <Link to="/appointment/book">
                                    <div className="button is-primary">
                                        BOOK NOW
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/smileyface.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double">
                        <div className="column is-center-vertical">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/bundle.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Mint Bundle
                                </h4>
                                <h5 className="is-bold">
                                    <div className="is-medium slash-wrapper">
                                        <div
                                            className="slash"
                                            style={{ top: '16px' }}></div>
                                        <h5>$399 </h5>
                                    </div>{' '}
                                    $249 | 120 min
                                </h5>
                                <p className="pad-top-half pad-bottom left-align is-black">
                                    Our two most popular services combined into
                                    one appointment. Get a professional cleaning
                                    and whitening all in 2 hours.
                                </p>
                                <Link to="/appointment/book">
                                    <div className="button is-primary">
                                        BOOK NOW
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="body grey">
                <div className="container pad-top-double pad-bottom-double">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-black is-text-centered">
                                Be the first to hear about the launch
                            </h1>
                            <h5 className="pad-top-half is-text-centered">
                                Join our wait list and receive priority access
                                to book your
                            </h5>
                            <h5 className="pad-top-half is-text-centered">
                                appointment through YourTeeth!
                            </h5>
                            <MailchimpField />
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
};
