import React from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { authFirst, authLast, authEmail } from '../../../auth';

export const Patients = (props) => {
    const blankPatient = {
        first: authFirst(),
        last: authLast(),
        email: authEmail(),
    };
    // const counts = [1, 2, 3, 4, 5];
    const counts = [1];
    const [patientCount, setPatientCount] = useState(1);
    const [patients, setPatients] = useState(
        counts.map((item) => {
            return { ...blankPatient };
        })
    );
    const [complete, setComplete] = useState(false);

    const updateFirst = (i, value) => {
        const copy = [...patients];
        copy[i].first = value;
        setPatients(copy);
    };

    const updateLast = (i, value) => {
        const copy = [...patients];
        copy[i].last = value;
        setPatients(copy);
    };

    const updateEmail = (i, value) => {
        const copy = [...patients];
        copy[i].email = value;
        setPatients(copy);
    };

    const isFormSubmitable = () => {
        for (let i = 0; i < patientCount; i++) {
            let item = patients[i];

            if (!item.first || !item.last || !item.email) return false;
        }

        return true;
    };

    const submitForm = () => {
        console.log(patients.slice(0, patientCount));
        props.onPatientComplete(patients.slice(0, patientCount));
        setComplete(true);
    };

    return (
        <div>
            {complete && <Redirect to="/appointment/book/service" />}
            <div className="hu-hero">
                <p className="accent-underline">Step 1 of 4</p>
                <h3 className="is-bold pad-top-half">
                    Who are you booking for?
                </h3>
            </div>
            <div className="pad-top-double container">
                <p className="is-bold center-align">HOW MANY PATIENTS?</p>
                <div className="center-flex">
                    <div className="buttons pad-top">
                        {counts.map((item) => (
                            <div
                                className={`button is-info ${
                                    item !== patientCount && 'is-outlined'
                                }`}
                                onClick={() => setPatientCount(item)}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pad-top-double">
                    {patients.map(
                        (item, idx) =>
                            idx < patientCount && (
                                <div className="pad-bottom-double">
                                    <p className="is-bold center-align pad-bottom">
                                        PATIENT {idx + 1}
                                    </p>
                                    <div className="columns">
                                        <div className="column is-6 is-full-mobile center">
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label">
                                                            First Name
                                                        </label>
                                                        <input
                                                            className="input"
                                                            placeholder="First Name"
                                                            type="text"
                                                            value={item.first}
                                                            onChange={(e) =>
                                                                updateFirst(
                                                                    idx,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label">
                                                            Last Name
                                                        </label>
                                                        <input
                                                            className="input"
                                                            placeholder="Last Name"
                                                            type="text"
                                                            value={item.last}
                                                            onChange={(e) =>
                                                                updateLast(
                                                                    idx,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label">
                                                            Email
                                                        </label>
                                                        <input
                                                            className="input"
                                                            placeholder="Email"
                                                            type="email"
                                                            value={item.email}
                                                            onChange={(e) =>
                                                                updateEmail(
                                                                    idx,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                    <div className="columns">
                        <div className="column is-6 is-full-mobile center">
                            <div className="pad-bottom-double center-flex">
                                <div
                                    className={`button is-primary
                        `}
                                    onClick={submitForm}
                                    disabled={!isFormSubmitable()}>
                                    Continue
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
