import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import { isProvider } from '../../auth';
import { Footer } from '../shared/Footer';
import { Loader } from '../profile/Loader';
import { axiosInstance } from '../../axios';
import { parseMinFromMid } from '../../utils';

export const AppointmentDashboard = (props) => {
    const [fetching, setFetching] = useState(true);
    const [appts, setAppts] = useState();

    const history = useHistory();

    const fetchAppointments = async () => {
        setFetching(true);
        try {
            const res = await axiosInstance.get('/appointments/all');
            setAppts(res.data);
            setFetching(false);
        } catch (e) {
            setAppts([]);
            setFetching(false);
            console.log(e);
            history.push('/login');
        }
    };

    const deleteAppointment = async (apptid) => {
        try {
            const res = await axiosInstance.delete(
                `/appointments/?apptid=${apptid}`
            );
            fetchAppointments();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);

    return (
        <div>
            <Navbar filled provider={isProvider()} dark />
            <div className="container">
                <h3 className="is-bold pad-top-double">All Appointments</h3>
                <Loader isLoading={fetching} style={{ height: '90vh' }}>
                    <div className="pad-top pad-bottom">
                        <div className="card padded rounded border">
                            {appts &&
                                appts.map((item, idx) => (
                                    <div className="pad-left pad-right">
                                        <h5 className="is-medium pad-bottom">
                                            {moment(item.date).format('MMMM D')}
                                        </h5>
                                        {item.patients.map((patient, idx) => (
                                            <div>
                                                <p className="is-info is-small pad-bottom-half">
                                                    {parseMinFromMid(item.time)}{' '}
                                                    -{' '}
                                                    {parseMinFromMid(
                                                        item.time +
                                                            item.duration
                                                    )}
                                                </p>
                                                <h6 className="is-bold">
                                                    {patient.first_name}{' '}
                                                    {patient.last_name}
                                                </h6>
                                                <p className="is-small">
                                                    {patient.email}
                                                </p>
                                                <h6 className="is-light">
                                                    {patient.service
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                    {patient.service.slice(1)} +
                                                    Digital Smile Scan
                                                </h6>
                                                <Link
                                                    to={`/${item.provider_username}`}
                                                    target="_blank">
                                                    <div className="provider-card-top">
                                                        <div
                                                            className="provider-img small margin-top-small"
                                                            style={{
                                                                backgroundImage: `url(${item.provider_photo})`,
                                                                alignItems:
                                                                    'center',
                                                            }}></div>
                                                        <div className="provider-card-name pad-left">
                                                            <p className="is-medium pad-top-half">
                                                                {
                                                                    item.provider_name
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <p className="pad-top">
                                                    {item.address1}
                                                </p>
                                                {item.address2 && (
                                                    <p className="pad">
                                                        {item.address2}
                                                    </p>
                                                )}
                                                {item.special && (
                                                    <p className="pad-top">
                                                        <span className="is-bold">
                                                            Special
                                                            Instructions:
                                                        </span>{' '}
                                                        {item.special}
                                                    </p>
                                                )}
                                                {item.payment_completed ? (
                                                    <p className="pad-top is-bold">
                                                        Paid!
                                                    </p>
                                                ) : (
                                                    <p className="pad-top is-bold">
                                                        Payment Pending
                                                    </p>
                                                )}
                                                <div className="buttons margin-top">
                                                    <Link
                                                        to={`/appointments/${item.appointment_id}`}>
                                                        <div className="button is-info">
                                                            Appointment Detail
                                                        </div>
                                                    </Link>
                                                    <div
                                                        className="button is-danger margin-left"
                                                        onClick={() =>
                                                            deleteAppointment(
                                                                item.appointment_id
                                                            )
                                                        }>
                                                        Delete Appointment
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {idx !== appts.length - 1 && (
                                            <hr className="dropdown-divider margin-top margin-bottom" />
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                </Loader>
            </div>
            <Footer />
        </div>
    );
};
