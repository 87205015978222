import { axiosInstance } from './axios';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

var authed = false;
var account;

export const login = (acct) => {
    authed = !(acct == undefined);
    account = acct;
    localStorage.setItem('hu-health-account', JSON.stringify(acct));
};

export const logout = () => {
    authed = false;
    account = undefined;
    localStorage.removeItem('hu-health-account');
};

export const isAuthenticated = () => authed;

export const initAuth = async () => {
    var acct = localStorage.getItem('hu-health-account');

    if (acct) {
        acct = JSON.parse(acct);
        authed = true;
        account = acct;

        try {
            const res = await axiosInstance.get('/auth/me');
            login(res.data);
        } catch (e) {
            console.log('user logged out passivly');
            logout();
        }
    }
};

export const authUsername = () => {
    if (!account) {
        logout();
        return;
    }
    return account.username || account.email;
};

export const authPhoto = () => {
    if (!account) {
        logout();
        return;
    }
    return account.photo;
};

export const authFirst = () => {
    if (!account) {
        logout();
        return;
    }
    return account.first_name;
};

export const authLast = () => {
    if (!account) {
        logout();
        return;
    }
    return account.last_name;
};

export const authEmail = () => {
    if (!account) {
        logout();
        return;
    }
    return account.email;
};

export const isProvider = () => {
    if (!account) {
        logout();
        return;
    }
    return account.is_provider;
};

export const updatePhoto = (photo) => {
    var acct = localStorage.getItem('hu-health-account');
    if (acct) {
        acct = JSON.parse(acct);
        acct.photo = photo;
        account = acct;
        localStorage.setItem('hu-health-account', JSON.stringify(acct));
    }
};

export const PrivateRoute = (props) => {
    return (
        <Route {...props}>
            {isAuthenticated() && (props.providers ? isProvider() : true) ? (
                props.children
            ) : (
                <Redirect to={isAuthenticated() ? '/' : '/login'} />
            )}
        </Route>
    );
};
