import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import { Footer } from '../shared/Footer';
import { MailchimpField } from '../shared/MailchimpField';

export const ForHygienists = () => {
    const [email, setEmail] = useState('');

    return (
        <div id="landing-page">
            <Helmet>
                <title>YourTeeth</title>
            </Helmet>
            <Navbar links forhygienists={true} />
            <section className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="columns is-centered is-center-horizontal">
                            <div className="column is-6 is-full-mobile">
                                <h1 className="is-bold is-white is-text-centered">
                                    Own your career with YourTeeth
                                </h1>
                                <h5 className="pad-top-half is-white is-text-centered">
                                    We are seeking talented dental hygienists to
                                    join our YourTeeth provider network and help
                                    us in our mission of making healthcare more
                                    human.
                                </h5>
                                <div className="buttons center-flex">
                                    <Link to="/register/provider">
                                        <div className="button is-medium is-white margin-top-double">
                                            <h5 className="is-bold is-black">
                                                Sign Up
                                            </h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body grey">
                <div className="container">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-text-centered pad-bottom">
                                Who we are
                            </h1>
                            <h5 className="pad-top-half">
                                YourTeeth is a community of dental health
                                enthusiasts on a mission to re-humanize the
                                patient-provider relationship from insurance
                                companies and large corporates.
                            </h5>
                            <h5 className="pad-top">
                                Through YourTeeth, both patients and providers
                                alike can experience a more curated and
                                personalized experience, leading to higher
                                quality experiences and health.
                            </h5>
                            <h5 className="pad-top">
                                We deliver on-demand dental services to clients,
                                empowering them to receive quality care on their
                                own terms. Guided by collaboration, we provide
                                customers with truly personalized experiences,
                                in the comfort of their own homes.
                            </h5>
                            <h5 className="pad-top">
                                That’s where you come in. As the dental health
                                authority, you have the power to connect with
                                your clients, to guide them to their healthiest
                                smiles—at YourTeeth, humans treat and empower
                                other humans.
                            </h5>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body main">
                <div className="container">
                    <h1 className="is-bold is-text-centered pad-bottom">
                        Why YourTeeth?
                    </h1>
                    <div className="columns pad-top-double is-hidden-mobile">
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Compensation
                                </h4>
                                <p className="pad-top-half left-align">
                                    Earn double the national hygiene
                                    compensation averages for your time and
                                    receive a guaranteed tip on every service -
                                    and watch that money flow into your bank
                                    account within 48 hours through our instant
                                    pay feature.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/piggybank.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="columns pad-top-double is-hidden-tablet">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/piggybank.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Compensation
                                </h4>
                                <p className="pad-top-half left-align">
                                    Earn double the national hygiene
                                    compensation averages for your time and
                                    receive a guaranteed tip on every service -
                                    and watch that money flow into your bank
                                    account within 48 hours through our instant
                                    pay feature.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/calendar.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Freedom
                                </h4>
                                <p className="pad-top-half left-align">
                                    Your schedule is in YOUR hands, with our
                                    easy-to-use YourTeeth Professional app—be
                                    your own boss!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="columns pad-top-double is-hidden-mobile">
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Boost your business
                                </h4>
                                <p className="pad-top-half left-align">
                                    Grow your roster of YourTeeth patients who
                                    can continue to book directly with you and
                                    gain personal reviews and recommendations on
                                    your YourTeeth profile.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/feedback.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-tablet">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/feedback.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Boost your business
                                </h4>
                                <p className="pad-top-half left-align">
                                    Grow your roster of YourTeeth patients who
                                    can continue to book directly with you and
                                    gain personal reviews and recommendations on
                                    your YourTeeth profile.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/network.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Community
                                </h4>
                                <p className="pad-top-half left-align">
                                    Attend exclusive master classes and Pro
                                    Sessions to hone your techniques, and join
                                    in on our Pro Seminar Series for advice on
                                    taxes, social media, and more! Become a part
                                    of a community of talented, passionate
                                    fellow dental professionals.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="body grey">
                <div className="container pad-top-double pad-bottom-double">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-black is-text-centered">
                                Be the first to hear about the launch
                            </h1>
                            <h5 className="pad-top-half is-text-centered">
                                Join our wait list and receive priority access
                                to book your
                            </h5>
                            <h5 className="pad-top-half is-text-centered">
                                appointment through YourTeeth!
                            </h5>
                            <MailchimpField />
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
};
