import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import '../../styles/urgentdental.scss';
import { Footer } from '../shared/Footer';
import { MailchimpField } from '../shared/MailchimpField';

export const UrgentDental = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [descr, setDescr] = useState('');

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div id="landing-page">
            <Helmet>
                <title>24/7 Urgent Dental</title>
            </Helmet>
            <div className="top is-lightblue-bkg pad-top is-hidden-mobile">
                <div className="level">
                    <div className="level-left">
                        <a href="/urgentdental">
                            <img
                                className="logo"
                                alt="Arch Dental"
                                src="/247_logo.svg"
                            />
                        </a>
                    </div>
                    <div className="level-right">
                        <div className="level-item pad-right-double">
                            <h4 className="is-darkblue">Call &nbsp;</h4>
                            <h4 className="is-red is-bold"> 678-722-5212</h4>
                            <h4 className="is-darkblue">
                                &nbsp;to see us today{' '}
                            </h4>
                        </div>
                        <button className="button is-medium is-urgentdental margin-right-double">
                            <h5 className="is-bold is-white">Call Now</h5>
                        </button>
                    </div>
                </div>
            </div>
            <div className="mobile-top is-lightblue-bkg pad-top is-hidden-tablet">
                <div className="is-center-horizontal">
                    <a href="/urgentdental">
                        <img
                            className="logo pad-logo"
                            alt="Arch Dental"
                            src="/247_logo.svg"
                        />
                    </a>
                </div>
                <div className="is-center-horizontal">
                    <h5 className="is-darkblue">Call &nbsp;</h5>
                    <h5 className="is-red is-bold"> 123-456-7890</h5>
                    <h5 className="is-darkblue">&nbsp;to see us today </h5>
                </div>
                <div className="is-center-horizontal pad-top">
                    <button className="button is-medium is-urgentdental">
                        <h5 className="is-bold is-white">Call Now</h5>
                    </button>
                </div>
            </div>
            <section className="body lightblue">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <h2 className="has-text-centered is-bold is-darkblue">
                                Same Day Emergency
                            </h2>
                            <h2 className="has-text-centered is-bold is-darkblue">
                                Dental Care in Norcross
                            </h2>
                            <div className="pad-top center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/phone.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                            <h4 className="pad-top center-align is-darkblue">
                                Servicing all of Greater Atlanta
                            </h4>
                        </div>
                        <div className="column">
                            <div className="form">
                                <div className="row2">
                                    <h6 className="is-bold">Name</h6>
                                </div>
                                <div className="row2">
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="First"
                                                    value={firstName}
                                                    onChange={(e) =>
                                                        setFirstName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </p>
                                        </div>
                                        <div className="field">
                                            <p className="control is-expanded">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="Last"
                                                    value={lastName}
                                                    onChange={(e) =>
                                                        setLastName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row3 pad-top">
                                    <h6 className="is-bold">Email</h6>
                                </div>
                                <div className="row3">
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="email@domain.com"
                                                    value={email}
                                                    onChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row4 pad-top">
                                    <h6 className="is-bold">Phone Number</h6>
                                </div>
                                <div className="row4">
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="000-000-0000"
                                                    value={number}
                                                    onChange={(e) =>
                                                        setNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row5 pad-top">
                                    <h6 className="is-bold">
                                        Additional Comments
                                    </h6>
                                </div>
                                <div className="row5">
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded">
                                                <textarea
                                                    className="textarea"
                                                    type="text"
                                                    placeholder="Comments"
                                                    value={descr}
                                                    onChange={(e) =>
                                                        setDescr(e.target.value)
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="is-center-horizontal column">
                                <button className="button is-medium is-urgentdental">
                                    <h5 className="is-bold is-white">Submit</h5>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="body main">
                <div className="container">
                    <div className="is-center-horizontal pad-bottom-double is-hidden-mobile">
                        <div className="counter">
                            <div className="numbers">
                                <div className="columns is-centered is-gapless">
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>5</b>
                                        </div>
                                    </div>
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>7</b>
                                        </div>
                                    </div>
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>3</b>
                                        </div>
                                    </div>
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>0</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 className="has-text-centered is-white is-bold pad-top-half">
                                Pain-Free Patients
                            </h5>
                            <h5 className="has-text-centered is-white pad-bottom">
                                ...and counting!
                            </h5>
                        </div>
                    </div>
                    <div className="is-center-horizontal pad-bottom-double is-hidden-tablet">
                        <div className="counter-mobile">
                            <div className="numbers">
                                <div className="columns is-centered is-mobile">
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>5</b>
                                        </div>
                                    </div>
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>7</b>
                                        </div>
                                    </div>
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>3</b>
                                        </div>
                                    </div>
                                    <div className="column is-center-horizontal">
                                        <div className="white-number-block">
                                            <b>0</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 className="has-text-centered is-white is-bold pad-top-half">
                                Pain-Free Patients
                            </h5>
                            <h5 className="has-text-centered is-white pad-bottom">
                                ...and counting!
                            </h5>
                        </div>
                    </div>
                    <div className="testimony-card is-hidden-mobile">
                        <div className="columns">
                            <div className="column">
                                <div className="center testimony-img">
                                    <img
                                        className="img-rigid"
                                        src="img/tarabecker.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h5 className="has-text-centered is-red is-bold pad-top-half">
                                    Tara Becker
                                </h5>
                            </div>
                            <div className="column is-narrow">
                                <p className="is-red is-bold apostrophe">"</p>
                            </div>
                            <div className="column is-two-thirds">
                                <h6 className="is-darkblue margin-right-double">
                                    When going to someone new, you usually get
                                    nervous about how you may be treated. I felt
                                    the complete opposite here -{' '}
                                    <b>the team made me feel so comfortable.</b>{' '}
                                    I cried quite a few times due to the
                                    excessive amount of pain I was in, but Dr.
                                    Sanchez made sure that I never felt
                                    belittled.{' '}
                                    <b>
                                        {' '}
                                        I was finally able to sleep that night!
                                    </b>{' '}
                                    Thank you sooooo much! I would recommend
                                    “24/7 Urgent Dental” to anyone.”
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="testimony-card is-hidden-tablet">
                        <div className="columns">
                            <div className="column">
                                <div className="center testimony-img">
                                    <img
                                        className="img-rigid"
                                        src="img/tarabecker.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h5 className="has-text-centered is-red is-bold pad-top-half">
                                    Tara Becker
                                </h5>
                            </div>
                            <div className="column is-narrow">
                                <p className="is-red is-bold apostrophe-mobile">
                                    "
                                </p>
                            </div>
                            <div className="column is-two-thirds">
                                <h6 className="is-darkblue small-margins">
                                    When going to someone new, you usually get
                                    nervous about how you may be treated. I felt
                                    the complete opposite here -{' '}
                                    <b>the team made me feel so comfortable.</b>{' '}
                                    I cried quite a few times due to the
                                    excessive amount of pain I was in, but Dr.
                                    Sanchez made sure that I never felt
                                    belittled.{' '}
                                    <b>
                                        {' '}
                                        I was finally able to sleep that night!
                                    </b>{' '}
                                    Thank you sooooo much! I would recommend
                                    “24/7 Urgent Dental” to anyone.”
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="testimony-card is-hidden-mobile">
                        <div className="columns">
                            <div className="column is-narrow">
                                <p className="is-red is-bold apostrophe margin-left">
                                    "
                                </p>
                            </div>
                            <div className="column is-two-thirds">
                                <h6 className="is-darkblue">
                                    During a holiday weekend, when no other
                                    dentists would see me for an emergency root
                                    canal, “24/7 Urgent Dental” scheduled me a
                                    same-day root canal with Dr. Bash. My
                                    regular dentist refused to see me because of
                                    the holiday weekend, but Dr. Bash did the
                                    same-day procedure even though I wasn’t a
                                    patient of his. I was in extreme discomfort
                                    prior to his work.
                                    <b>
                                        This evening, I am pain-free and so
                                        grateful!
                                    </b>
                                    And I loved Winkles the therapy dog, she’s
                                    the best!!
                                </h6>
                            </div>
                            <div className="column">
                                <div className="center testimony-img">
                                    <img
                                        className="img-rigid"
                                        src="img/danielperelman.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h5 className="has-text-centered is-red is-bold pad-top-half">
                                    Daniel Perelman
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="testimony-card is-hidden-tablet">
                        <div className="columns">
                            <div className="column">
                                <div className="center testimony-img">
                                    <img
                                        className="img-rigid"
                                        src="img/danielperelman.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h5 className="has-text-centered is-red is-bold pad-top-half">
                                    Daniel Perelman
                                </h5>
                            </div>
                            <div className="column is-narrow">
                                <p className="is-red is-bold apostrophe-mobile">
                                    "
                                </p>
                            </div>
                            <div className="column is-two-thirds">
                                <h6 className="is-darkblue small-margins">
                                    During a holiday weekend, when no other
                                    dentists would see me for an emergency root
                                    canal, “24/7 Urgent Dental” scheduled me a
                                    same-day root canal with Dr. Bash. My
                                    regular dentist refused to see me because of
                                    the holiday weekend, but Dr. Bash did the
                                    same-day procedure even though I wasn’t a
                                    patient of his. I was in extreme discomfort
                                    prior to his work.
                                    <b>
                                        This evening, I am pain-free and so
                                        grateful!
                                    </b>
                                    And I loved Winkles the therapy dog, she’s
                                    the best!!
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="testimony-card is-hidden-mobile">
                        <div className="columns">
                            <div className="column">
                                <div className="center testimony-img">
                                    <img
                                        className="img-rigid"
                                        src="img/jeannequinn.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h5 className="has-text-centered is-red is-bold pad-top-half">
                                    Jeanne Quinn
                                </h5>
                            </div>
                            <div className="column is-narrow">
                                <p className="is-red is-bold apostrophe">"</p>
                            </div>
                            <div className="column is-two-thirds">
                                <h6 className="is-darkblue margin-right-double">
                                    I woke up this morning with an unbearable
                                    toothache. I found “24/7 Urgent Dental” on
                                    the internet. They accommodated me
                                    immediately. When I arrived,{' '}
                                    <b>they were so kind and compassionate</b>.
                                    Dr. Bash explained everything that was about
                                    to happen, and{' '}
                                    <b>
                                        I left there feeling great and will
                                        return to get on track with better
                                        dental care
                                    </b>
                                    . Dr. Bash personally called me that evening
                                    to follow up on my care - so amazing!
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="testimony-card is-hidden-tablet">
                        <div className="columns">
                            <div className="column">
                                <div className="center testimony-img">
                                    <img
                                        className="img-rigid"
                                        src="img/jeannequinn.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h5 className="has-text-centered is-red is-bold pad-top-half">
                                    Jeanne Quinn
                                </h5>
                            </div>
                            <div className="column is-narrow">
                                <p className="is-red is-bold apostrophe-mobile">
                                    "
                                </p>
                            </div>
                            <div className="column is-two-thirds">
                                <h6 className="is-darkblue small-margins">
                                    I woke up this morning with an unbearable
                                    toothache. I found “24/7 Urgent Dental” on
                                    the internet. They accommodated me
                                    immediately. When I arrived,{' '}
                                    <b>they were so kind and compassionate</b>.
                                    Dr. Bash explained everything that was about
                                    to happen, and{' '}
                                    <b>
                                        I left there feeling great and will
                                        return to get on track with better
                                        dental care
                                    </b>
                                    . Dr. Bash personally called me that evening
                                    to follow up on my care - so amazing!
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="is-center-vertical pad-top-double">
                        <div>
                            <button className="button is-large is-urgentdental">
                                <h5 className="is-bold is-white">Call Now</h5>
                            </button>
                            <button
                                className="button is-large is-urgentdental-alt margin-left"
                                onClick={scrollTop}>
                                <h5 className="is-bold is-red">Book Now</h5>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body lightblue">
                <div className="container">
                    <h2 className="is-bold has-text-centered is-darkblue">
                        Meet Our Dentist Partners
                    </h2>
                    <div className="columns is-centered pad-top-double">
                        <div className="column is-4">
                            <div className="dentist-card">
                                <div className="center dentist-img pad-top-double">
                                    <img
                                        className="img-rigid"
                                        src="img/zachmurphy.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h4 className="is-bold is-red has-text-centered pad-top">
                                    Dr. Zach Murphy
                                </h4>
                                <p className="small-margins pad-top-half is-darkblue center-align pad-bottom-double">
                                    Dr. Zach is a Penn Dental alum with 14 years
                                    of experience and has been practicing in the
                                    Greater Atlanta area for almost all of it.s
                                    His number one priority is getting his
                                    patients out of pain, and patients love him
                                    for his fun-loving, knowledgeable approach
                                    to dentistry. When he isn’t working, he is
                                    spending time with his dog Max or cheering
                                    on Philly ssports teams!
                                </p>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="dentist-card">
                                <div className="center dentist-img pad-top-double">
                                    <img
                                        className="img-rigid"
                                        src="img/tinabolton.svg"
                                        alt="Arch Dental"
                                    />
                                </div>
                                <h4 className="is-bold is-red has-text-centered pad-top">
                                    Dr. Tina Bolton
                                </h4>
                                <p className="small-margins pad-top-half is-darkblue center-align pad-bottom-double">
                                    Dr. Tina is a Columbia Dental alum with 9
                                    years of experience. She is a New York
                                    native but has lived in Georgia for almost 5
                                    years now. Patients find that Dr. Tina has a
                                    pleasant demeanor and demonstrates
                                    considerable knowledge and skill in her work
                                    across the multiple disciplines within
                                    dentistry. Her favorite thing to do outside
                                    of treating patients is watching a good show
                                    on Netflix - her new favorite is Breaking
                                    Bad!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="is-center-vertical pad-top-double">
                        <div>
                            <button className="button is-large is-urgentdental">
                                <h5 className="is-bold is-white">Call Now</h5>
                            </button>
                            <button
                                className="button is-large is-urgentdental-alt margin-left"
                                onClick={scrollTop}>
                                <h5 className="is-bold is-red">Book Now</h5>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body main">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4">
                            <h4 className="is-bold is-darkblue pad-top">
                                Financing Plans Available!
                            </h4>
                            <h6 className="pad-top is-darkblue pad-bottom-double">
                                24/7 Urgent Dental offers flexible financing
                                options for major treatments. Our administrative
                                staff can work alongside with you to put
                                together a payment plan that fits your time and
                                budget.
                            </h6>
                            <div className="right-just">
                                <div>
                                    <button className="button is-large is-urgentdental">
                                        <h5 className="is-bold is-white">
                                            Call Now
                                        </h5>
                                    </button>
                                    <button className="button is-large is-urgentdental-alt margin-left">
                                        <h5 className="is-bold is-red">
                                            Book Now
                                        </h5>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="column is-4 is-center-horizontal">
                            <img
                                className="img-rigid"
                                src="img/coins.svg"
                                alt="Arch Dental"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="body lightblue">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <h2 className="is-bold is-darkblue is-text-centered">
                                Dental Care Driven By Needs Not Insurance Limits
                            </h2>
                            <h6 className="pad-top-double is-darkblue">
                                We at 24/7 Urgent Dental are a non-participating
                                or out-of-network provider. We can submit claims
                                for most dental insurance/plans that allow you
                                to choose your own dentist (PPOs). However, we
                                are unable to submit claims for state-funded
                                insurance and HMOs.
                            </h6>
                            <h6 className="pad-top is-darkblue">
                                Why? When Patients Opt To Pay For Dental Care
                                Outright, Dental Practices Are Not Influenced By
                                The Treatment Fee Schedule Incentives Set By
                                Insurance Companies. This Freedom Tends To Bring
                                A Better Level Of Care— With More Time Spent On
                                Your Case And A Higher Level Of Patient
                                Satisfaction. Our fee for service structure
                                allows us to provide premium care 24 hours a
                                day, 7 days a week.
                            </h6>
                        </div>
                    </div>
                    <div className="is-center-vertical pad-top-double">
                        <div>
                            <button className="button is-large is-urgentdental">
                                <h5 className="is-bold is-white">Call Now</h5>
                            </button>
                            <button
                                className="button is-large is-urgentdental-alt margin-left"
                                onClick={scrollTop}>
                                <h5 className="is-bold is-red">Book Now</h5>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
