import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Navbar } from '../../shared/Navbar';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { PlusCircle, MinusCircle } from 'react-feather';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useEffect } from 'react';
import { axiosInstance } from '../../../axios';
import Modal from '../../shared/Modal';
import { Footer } from '../../shared/Footer';

export const AvailabilitySlotField = (props) => {
    const format = 'h:mm a';

    const now = moment().hour(0).minute(props.start);
    const later = moment().hour(0).minute(props.end);

    const onStartChange = (value) => {
        const mmtMidnight = value.clone().startOf('day');
        const diffMinutes = value.diff(mmtMidnight, 'minutes');
        if (props.onStartChange) props.onStartChange(diffMinutes);
    };

    const onEndChange = (value) => {
        const mmtMidnight = value.clone().startOf('day');
        const diffMinutes = value.diff(mmtMidnight, 'minutes');
        if (props.onEndChange) props.onEndChange(diffMinutes);
    };

    return (
        <div className="time-picker-slot">
            <TimePicker
                showSecond={false}
                value={now}
                minuteStep={15}
                onChange={onStartChange}
                format={format}
                allowEmpty={false}
                use12Hours
                inputReadOnly
                className="pad-right"
            />
            to
            <TimePicker
                showSecond={false}
                value={later}
                minuteStep={15}
                onChange={onEndChange}
                format={format}
                allowEmpty={false}
                use12Hours
                inputReadOnly
                className="pad-left"
            />
            <div
                className="pad-left pad-top-half"
                onClick={props.onButtonClick}>
                {props.add ? (
                    <PlusCircle size={18} color="#6c63ff" />
                ) : (
                    <MinusCircle size={18} color="#6c63ff" />
                )}
            </div>
        </div>
    );
};

const AvailabilitySelectCard = (props) => {
    const [unavailable, setUnavailable] = useState(props.events.length === 0);
    const [times, setTimes] = useState(
        props.events.length > 0 ? props.events : [[9 * 60, 17 * 60]]
    );

    const date = moment(props.date);

    const addTime = () => {
        const copy = [...times];
        copy.push([9 * 60, 17 * 60]);
        setTimes(copy);
    };

    const deleteTime = (idx) => {
        const copy = [...times];
        copy.splice(idx, 1);
        setTimes(copy);
    };

    const startChange = (idx, min) => {
        const copy = [...times];
        times[idx][0] = min;
        setTimes(copy);
    };

    const endChange = (idx, min) => {
        const copy = [...times];
        times[idx][1] = min;
        setTimes(copy);
    };

    const submitAvailability = async () => {
        try {
            const payload = {
                date: date.toISOString(),
                times: unavailable
                    ? []
                    : times.map((item) => {
                          return { start: item[0], end: item[1] };
                      }),
            };
            await axiosInstance.post('/profile/availability', payload);
            props.onComplete();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="">
            <h5 className="is-bold center-align pad-bottom">
                Availability for {date.format('MMMM Do')}
            </h5>
            {!unavailable && times.length > 0 && (
                <div className="avail-slots pad-bottom-half">
                    {times.map((item, idx) => (
                        <AvailabilitySlotField
                            key={item}
                            start={item[0]}
                            end={item[1]}
                            onStartChange={(min) => startChange(idx, min)}
                            onEndChange={(min) => endChange(idx, min)}
                            add={idx === times.length - 1}
                            onButtonClick={() =>
                                idx === times.length - 1
                                    ? addTime()
                                    : deleteTime(idx)
                            }
                        />
                    ))}
                </div>
            )}
            <FormGroup className="pad-bottom">
                <FormControlLabel
                    label="I'm unavailable"
                    control={
                        <Checkbox
                            className="arch-checkbox"
                            checked={unavailable}
                            onChange={() => setUnavailable(!unavailable)}
                        />
                    }></FormControlLabel>
            </FormGroup>
            <div
                className="button is-info is-outlined is-fullwidth"
                onClick={submitAvailability}>
                Apply to {date.format('MMMM Do')} only
            </div>
        </div>
    );
};

const AvailabilityCal = (props) => {
    const [sDate, setSDate] = useState(undefined);
    const [sEvents, setSEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const localizer = momentLocalizer(moment);
    const [modalOpen, toggleModal] = useState(false);

    const parseAvail = (avail) => {
        const e = [];
        avail.map((item) => {
            let date = moment(item.date).hour(0);

            item.times.map((item) => {
                e.push({
                    start: moment(date).minute(item.start).toDate(),
                    end: moment(date).minute(item.end).toDate(),
                    title: `${moment(date)
                        .minute(item.start)
                        .format('h:mmA')} - ${moment(date)
                        .minute(item.end)
                        .format('h:mmA')}`,
                });
            });
        });
        return e;
    };

    const fetchAvail = async () => {
        try {
            const res = await axiosInstance.get('/profile/availability');
            const avail = res.data;
            const parsed = parseAvail(avail);
            setEvents(parsed);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAvail();
    }, []);

    const handleClick = (slotInfo) => {
        if (slotInfo.action !== 'click' && slotInfo.action !== 'select') {
            return;
        }
        setSDate(undefined);

        const eventsForDate = events.filter((item) =>
            moment(item.start)
                .startOf('day')
                .isSame(moment(slotInfo.start).startOf('day'))
        );

        setSEvents(eventsForDate);
        setSDate(slotInfo.start);
        toggleModal(true);
    };

    const minSinceMidnight = (date) => {
        date = moment(date);
        const midnight = date.clone().startOf('day');
        return date.diff(midnight, 'minutes');
    };
    return (
        <div>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                views={['month', 'week']}
                onSelectSlot={handleClick}
                longPressThreshold={1}
                selectable
            />

            <Modal
                open={sDate && modalOpen}
                toggle={() => toggleModal(!modalOpen)}
                background>
                <AvailabilitySelectCard
                    date={sDate}
                    events={sEvents.map((item) => [
                        minSinceMidnight(item.start),
                        minSinceMidnight(item.end),
                    ])}
                    onComplete={() => {
                        fetchAvail();
                        toggleModal(false);
                    }}
                />
            </Modal>
        </div>
    );
};

export const AvailabilityPreferences = () => {
    const distances = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
    const [breakLength, setBreak] = useState(0);
    const [maxDistance, setMaxDistance] = useState(0);

    const breakChange = (l) => {
        setBreak(l);
        axiosInstance.post(`profile/availability/break?length=${l}`);
    };

    const distanceChange = (d) => {
        setMaxDistance(d);
        axiosInstance.post(`profile/availability/distance?distance=${d}`);
    };

    const fetchBreak = async () => {
        try {
            const res = await axiosInstance.get('profile/availability/break');
            const b = res.data.break;

            if (b) {
                setBreak(b);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchDistance = async () => {
        try {
            const res = await axiosInstance.get(
                'profile/availability/distance'
            );
            const { max_distance } = res.data;

            if (max_distance) {
                setMaxDistance(max_distance);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchBreak();
        fetchDistance();
    }, []);

    return (
        <div className="card rounded border">
            <div className="card-content">
                <div className="columns">
                    <div className="column is-4">
                        <div className="field is-expanded">
                            <label className="label">
                                Maximum travel distance
                            </label>
                            <div className="select is-expanded">
                                <select
                                    onChange={(e) =>
                                        distanceChange(parseInt(e.target.value))
                                    }>
                                    {distances.map((item) => (
                                        <option
                                            value={item}
                                            selected={item === maxDistance}>
                                            {item} miles
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                Minimum time between appointments
                            </label>
                            <div className="buttons pad-top-half">
                                <div
                                    className={`button is-info ${
                                        breakLength !== 15 && 'is-outlined'
                                    }`}
                                    onClick={() => breakChange(15)}>
                                    15 min
                                </div>
                                <div
                                    className={`button is-info ${
                                        breakLength !== 30 && 'is-outlined'
                                    }`}
                                    onClick={() => breakChange(30)}>
                                    30 min
                                </div>
                                <div
                                    className={`button is-info ${
                                        breakLength !== 45 && 'is-outlined'
                                    }`}
                                    onClick={() => breakChange(45)}>
                                    45 min
                                </div>
                                <div
                                    className={`button is-info ${
                                        breakLength !== 60 && 'is-outlined'
                                    }`}
                                    onClick={() => breakChange(60)}>
                                    60 min
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const AvailabilityPage = () => {
    return (
        <div>
            <Navbar filled provider dark />
            <div className="container pad-top pad-bottom-double">
                <h3 className="is-bold pad-bottom-double">Availability</h3>
                <div className="pad-bottom-double">
                    <AvailabilityPreferences />
                </div>
                <AvailabilityCal />
            </div>
            <Footer />
        </div>
    );
};
