import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { SettingsCard } from './Card';
import Skeleton from 'react-loading-skeleton';
import { DateOfBirthField } from '../FormFields';
import { Map } from '../onboard/Map';
import { axiosInstance } from '../../axios';
import { authUsername, updatePhoto, isProvider } from '../../auth';
import { FileUpload } from '../profile/Uploaders';
import { useHistory } from 'react-router-dom';
import { Gallery } from '../profile/Gallery';
import { VideoGalleryCard } from './VideoGallery';

export const ProfileLocationForm = (props) => {
    const [, setPlace] = useState({});

    return (
        <div>
            <h4 className="is-bold pad-top-double">Location</h4>
            <Map onPlaceChange={setPlace} />
            <div className="bottom-buttons pad-top-double">
                <div></div>
                <button className="button is-primary">Update Location</button>
            </div>
        </div>
    );
};

export const ProfileEmergencyContactForm = (props) => {
    const [name, setName] = useState('');
    const [relation, setRelation] = useState('');
    const [phone, setPhone] = useState('');

    return (
        <div>
            <h4 className="is-bold pad-top-double">Emergency Contact</h4>
            <div className="column is-6">
                <div className="field pad-top">
                    <label className="label">Primary Contact</label>
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="control pad-top">
                        <input
                            className="input"
                            type="text"
                            placeholder="Relationship"
                            value={relation}
                            onChange={(e) => setRelation(e.target.value)}
                        />
                    </div>
                    <div className="control pad-top">
                        <input
                            className="input"
                            type="text"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="bottom-buttons pad-top-double">
                <div></div>
                <button className="button is-primary">
                    Update Emergency Contact
                </button>
            </div>
        </div>
    );
};
export const ProfileContactInfoForm = (props) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    return (
        <div>
            <div className="column is-6">
                <h4 className="is-bold pad-top-double">Contact</h4>
                <div className="field pad-top">
                    <label className="label">Email</label>
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="field pad-top">
                    <label className="label">Phone Number</label>
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>
                <div className="field pad-top">
                    <label className="label">Address</label>
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Address Line 1"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder="Address Line 2"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </div>
                </div>
                <div className="field is-grouped">
                    <div className="control" style={{ width: '60%' }}>
                        <input
                            className="input"
                            type="text"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className="control" style={{ width: '15%' }}>
                        <input
                            className="input"
                            type="text"
                            placeholder="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </div>
                    <div className="control" style={{ width: '20%' }}>
                        <input
                            className="input"
                            type="text"
                            placeholder="ZIP"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="bottom-buttons pad-top-double">
                <div></div>
                <button className="button is-primary">
                    Update Contact Info
                </button>
            </div>
        </div>
    );
};

export const ProfileBasicInfoForm = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [profileType] = useState(
        isProvider() ? 'Dental Hygienist' : 'Hu Health User'
    );
    const [isFetching, setIsFetching] = useState(false);
    const [bio, setBio] = useState('');
    const [photo, setPhoto] = useState();
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = authUsername();
    const history = useHistory();

    const fetchBasics = async () => {
        setIsFetching(true);
        try {
            const res = await axiosInstance.get(
                `/settings/basic?username=${user}`
            );
            setIsFetching(false);
            console.log(res.data);
            setFirstName(res.data.first_name);
            setLastName(res.data.last_name);
            setUsername(res.data.username);
            setPhoto(res.data.photo);
            setBio(res.data.bio);
            setVideos(res.data.videos);
        } catch (e) {
            console.log(e);
            setIsFetching(false);
        }
    };

    const payload = () => {
        return {
            first_name: firstName,
            last_name: lastName,
            username: username,
            bio: bio,
            photo: photo,
        };
    };

    const submitForm = async () => {
        setIsFetching(true);
        try {
            await axiosInstance.post('/settings/basic', payload());
            setIsFetching(false);
            history.push('/profile');
        } catch (e) {
            setIsFetching(false);
            console.log(e);
        }
    };

    const onPhotoSuccess = (photo) => {
        setPhoto(photo);
        updatePhoto(photo);
        fetchBasics();
    };

    const onVideoSuccess = (item) => {
        setVideos([...videos, item]);
        fetchBasics();
    };

    const deleteVideo = (idx) => {
        const old = [...videos];
        old.splice(idx, 1);
        console.log(old);
        setVideos(old);
    };

    useEffect(() => {
        fetchBasics();
    }, []);

    return (
        <div>
            <div className="profile-basic-info">
                <FileUpload
                    url="/profile/photo?name="
                    onSuccess={onPhotoSuccess}>
                                        
                    <div
                        className="pad-left profile-img-container"
                        style={{
                            backgroundImage: `url(${photo})`,
                            cursor: 'pointer',
                        }}>
                                                
                        {!photo && (
                            <img
                                style={{ paddingLeft: '7px' }}
                                src={`${process.env.PUBLIC_URL}/img/add_photo.svg`}
                                alt="Profile"
                            />
                        )}
                                            
                    </div>
                                    
                </FileUpload>
                <div className="profile-name pad-left">
                    <h5 className="is-bold">
                        {firstName + ' ' + lastName ||
                            (isFetching && <Skeleton />)}
                    </h5>
                    <h5>{profileType || (isFetching && <Skeleton />)}</h5>
                </div>
            </div>
            <div className="bar margin-top-double"></div>
            <div>
                <div className="field pad-top">
                    <label className="label">Name</label>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                            </p>
                        </div>
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="field pad-top">
                    <label className="label">Username</label>
                    <div className="field">
                        <div className="control is-expanded">
                            <fieldset disabled>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder=""
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                            </fieldset>
                        </div>
                    </div>
                </div>

                <div className="field pad-top">
                    <label className="label">Bio</label>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <textarea
                                    className="textarea"
                                    placeholder="Enter a bio here"
                                    value={bio}
                                    onChange={(e) =>
                                        setBio(e.target.value)
                                    }></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-buttons pad-top-double">
                    <div></div>
                    <button
                        onClick={submitForm}
                        className={`button is-primary ${
                            isFetching ? 'is-loading' : undefined
                        }`}>
                        Update Basic Info
                    </button>
                </div>
                <VideoGalleryCard
                    video={videos}
                    onSuccess={onVideoSuccess}
                    onDelete={deleteVideo}
                />
            </div>
        </div>
    );
};

export const PersonalInfoCard = (props) => {
    return (
        <SettingsCard heading="Personal Info">
            <ProfileBasicInfoForm />
            <div className="bar margin-top-double"></div>
            <Gallery />
        </SettingsCard>
    );
};
