import React from 'react';

export const SettingsCard = (props) => {
    return (
        <div>
            <h3 className="is-bold">{props.heading}</h3>
            <div className="card margin-top-double rounded">
                <div className="card-content">{props.children}</div>
            </div>
        </div>
    );
};
