import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import { Footer } from '../shared/Footer';
import { MailchimpField } from '../shared/MailchimpField';
import DatePicker from 'react-date-picker';
import { axiosInstance } from '../../axios';
import moment from 'moment';
import { useEffect } from 'react';
import StarRatings from 'react-star-ratings';

export const EmployeeCard = (props) => {
    const {
        first_name,
        last_name,
        photo,
        username,
        avg_rating,
        review_count,
        booking_count,
    } = props.employee;

    return (
        <Link to={`/${username}`}>
            <div className="employee-card card rounded">
                <div
                    className="pad-right profile-img-container"
                    style={{ backgroundImage: `url(${photo})` }}></div>
                <h6 className="is-bold pad-top">
                    {first_name} {last_name}
                </h6>
                <p>{booking_count} bookings completed</p>
                <div>
                    <StarRatings
                        rating={avg_rating}
                        starRatedColor="#F5D04D"
                        starEmptyColor="#E0E0E0"
                        starDimension="20px"
                        starSpacing="2px"
                    />
                    <span>({review_count})</span>
                </div>
            </div>
        </Link>
    );
};

export const BrowseHygienists = () => {
    const [date, setDate] = useState(moment.utc().startOf('day').toDate());
    const [providers, setProviders] = useState([]);

    const fetchHygienists = async () => {
        const res = await axiosInstance.get(
            `/appointments/browse?date=${moment(date).format()}`
        );
        setProviders(res.data);
    };

    useEffect(() => {
        fetchHygienists();
    }, [date]);

    return (
        <div id="landing-page">
            <Helmet>
                <title>Browse Hygienists - YourTeeth</title>
            </Helmet>
            <Navbar links browse />
            <section className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="columns is-centered is-center-horizontal">
                            <div className="column is-6 is-full-mobile">
                                <h1 className="is-bold is-white is-text-centered">
                                    Find your Hygienist
                                </h1>
                                <h5 className="pad-top-half is-white is-text-centered">
                                    Choose from a selection of highly vetted,
                                    top-rated dental professionals.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body main">
                <div className="container">
                    <div className="columns">
                        <div className="column is-4 is-full-mobile">
                            <p className="is-small">Location</p>
                            <fieldset disabled>
                                <input
                                    className="input"
                                    value="Philadelphia, PA"
                                />
                            </fieldset>
                        </div>
                        <div className="column is-full-mobile">
                            <p className="is-small">Date</p>
                            <DatePicker onChange={setDate} value={date} />
                        </div>
                    </div>
                    <div className="columms">
                        {providers.map((item, idx) => (
                            <div className="column is-4 is-full-mobile">
                                <EmployeeCard employee={item} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* <section className="body grey">
                <div className="container pad-top-double pad-bottom-double">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-black is-text-centered">
                                Be the first to hear about the launch
                            </h1>
                            <h5 className="pad-top-half is-text-centered">
                                Join our wait list and receive priority access
                                to book your
                            </h5>
                            <h5 className="pad-top-half is-text-centered">
                                appointment through YourTeeth!
                            </h5>
                            <MailchimpField />
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
};
