import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Navbar } from '../../shared/Navbar';

import { axiosInstance } from '../../../axios';
import { ProviderBasicInfo } from './BasicInfo';
import { ProviderQualifications } from './Qualifications';
import { Footer } from '../../shared/Footer';
import { ProviderNetwork } from './Network';
import { PrivateRoute, login } from '../../../auth';

export const ProviderRegisterPage = (props) => {
    const fetchAccount = async () => {
        try {
            const res = await axiosInstance.get('/auth/me');
            const account = res.data;
            login(account);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <div className="register-page">
                <Helmet>
                    <title>Provider Registration - YourTeeth</title>
                </Helmet>
                <Navbar />
                <Switch>
                    <Route path="/register/provider" exact>
                        <Redirect to="/register/provider/basic" />
                    </Route>
                    <Route path="/register/provider/basic" exact>
                        <ProviderBasicInfo onBasicComplete={fetchAccount} />
                    </Route>
                    <PrivateRoute
                        path="/register/provider/qualifications"
                        exact>
                        <ProviderQualifications />
                    </PrivateRoute>
                    <Route path="/register/provider/network" exact>
                        <ProviderNetwork />
                    </Route>
                    <Route path="*">
                        <Redirect to="/404" />
                    </Route>
                </Switch>
            </div>
            <Footer />
        </div>
    );
};
