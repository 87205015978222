export const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
};

export const validateUsername = (u) => {
    const regex = /^[a-z0-9.]*$/;
    return regex.test(u);
};

export const validatePassword = (p) => {
    const regex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
    return regex.test(p);
};

export const validatePhone = (p) => {
    const regex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
    return regex.test(p);
};

export const parseMinFromMid = (min) => {
    let hours = Math.floor(min / 60);
    const mins = min - hours * 60;
    const am = hours < 12;
    hours = hours === 0 ? 12 : hours;

    return `${am ? hours : hours - 12 == 0 ? 12 : hours - 12}:${
        mins < 10 ? '0' + mins : mins
    }${am ? 'am' : 'pm'}`;
};

export const informedConsent = `TELEHEALTH – I hereby consent to use Hu Health’s teledentistry platform so a state-licensed dentist and I can engage in telehealth as part of my preventative dental treatment. I understand that "telehealth" includes the practice of health or dental care delivery, diagnosis, consultation, treatment, and transfer of medical/dental information, both orally and visually, between me and a state licensed dental professional who has engaged Hu Health to provide certain non-clinical dental support organization services.

By signing this Informed Consent, I understand that I am certifying that: My dentist checked my last x-rays and has verified that I have no cavities, loose or defective fillings, crowns or bridges, shortened or resorbed roots, or impacted teeth. My dentist has previously probed or measured my gum pockets and confirmed that I do not have periodontal or gum disease, or have been treatment planned for scaling and root planing. My dentist performed a full oral-cancer screening in the last 6 months and confirmed that I do not have oral cancer.

I confirm that I do not have pain in any of my teeth or jaws. I further confirm that none of my teeth are loose, that I do not have any “baby teeth” and that all of my permanent teeth are present. I further consent to Hu Health sharing my personal and medical information with third parties, business associates, or affiliates for the purposes of dental treatment planning and/or services.

I certify that I can read and understand English. I acknowledge that neither the dentist prescribing my dental cleaning treatment nor Hu Health has made any guarantee or assurance to me. I have read this form and fully understand the benefits and risks listed in this form related to receiving a dental cleaning and/or whitening through Hu Health. I understand that Hu Health contracts with professional corporations which have engaged licensed dentists and orthodontists in the state in which I reside. I hereby provide my consent for one or more of the dentists or orthodontists affiliated with that professional corporation to review my records for potential evaluation, diagnosis, and treatment. I understand that neither the dentist who prescribed my dental cleaning treatment nor Hu Health can guarantee any specific result or outcome. I further understand that my dental cleaning treatment will only address preventative dental care measures, such as plaque and calculus removal, and will not specifically treat caries or more serious underlying dental diseases. In order to treat cavities or receive restorative dental services directly, I will need to seek more comprehensive treatment via my local dental professional. I understand that the dentist who prescribes my dental cleaning will determine the best course of treatment for me and that I may be prescribed the in-patient services of a local professional for more involved treatments, such as scaling and root planing, even if I prefer a dental cleaning. Because I am choosing not to engage the in-patient services of a local dental professional, I understand and accept that my teeth will be more plaque-free than they currently are but may still be compromised.

I hereby grant Hu Health the right to use photographs taken of me and my first name for educational and/or marketing purposes. I acknowledge that because my participation is voluntary, I will receive no financial compensation. I also agree that my participation confers upon me no right of ownership. I release Hu Health from liability for any copyright, trademark, or other Intellectual Property-related claims by me or any third party in connection with my participation or use of the clear aligner therapy treatment. I also understand that my treatment is not conditioned on my agreement to the use of my photographs or name, and that I can revoke this grant at any time by sending a written revocation to Hu Health, who will then inform my treating dentist.`;

export const covidLiability = `I acknowledge the contagious nature of the Coronavirus/COVID-19 and that the CDC and many other public health authorities still recommend practicing social distancing.

I further acknowledge that Hu Health Inc has put in place preventative measures to reduce the spread of the Coronavirus/COVID-19.

I further acknowledge that Hu Health Inc can not guarantee that I will not become infected with the Coronavirus/Covid-19. I understand that the risk of becoming exposed to and/or infected by the Coronavirus/COVID-19 may result from the actions, omissions, or negligence of myself and others, including, but not limited to, Hu staff, and other Hu clients and their families.

I voluntarily seek services provided by Hu Health and acknowledge that I am increasing my risk to exposure to the Coronavirus/COVID-19. I acknowledge that I must comply with all set procedures to reduce the spread while attending my appointment.

I attest that:

- I am not experiencing any symptom of illness such as cough, shortness of breath or difficulty breathing, fever, chills, repeated shaking with chills, muscle pain, headache, sore throat, or new loss of taste or smell.

-  I have not traveled internationally within the last 14 days.

- I have not traveled to a highly impacted area within the United States of America in the last 14 days.

- I do not believe I have been exposed to someone with a suspected and/or confirmed case of the Coronavirus/COVID-19.

- I have not been diagnosed with Coronavirus/Covid-19 and not yet cleared as non contagious by state or local public health authorities.

- I am following all CDC recommended guidelines as much as possible and limiting my exposure to the Coronavirus/COVID-19.

I hereby release and agree to hold Hu Health Inc harmless from, and waive on behalf of myself, my heirs, and any personal representatives any and all causes of action, claims, demands, damages, costs, expenses and compensation for damage or loss to myself and/or property that may be caused by any act, or failure to act of the company, or that may otherwise arise in any way in connection with any services received from Hu Health LLC. I understand that this release discharges Hu Health Inc from any liability or claim that I, my heirs, or any personal representatives may have against the company with respect to any bodily injury, illness, death, medical treatment, or property damage that may arise from, or in connection to, any services received from Hu Health Inc. This liability waiver and release extends to the company together with all owners, partners, and employees.`;
