import React from 'react';
import { BeatLoader } from 'react-spinners';

export const Loader = (props) => {
    return (
        <div>
            {props.isLoading ? (
                <div
                    style={Object.assign(
                        { display: 'flex', alignItems: 'center' },
                        props.style
                    )}
                    className={props.className}>
                    <div className="center">
                        <BeatLoader color="#6c63ff" />
                    </div>
                </div>
            ) : (
                <div>{props.children}</div>
            )}
        </div>
    );
};
