import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Navbar } from '../../shared/Navbar';
import { Footer } from '../../shared/Footer';
import { isProvider } from '../../../auth';
import { BarButtonSelector } from '../../profile/BarButtonSelector';
import { Switch, Route, Link, Redirect, useHistory } from 'react-router-dom';
import { axiosInstance } from '../../../axios';
import { parseMinFromMid } from '../../../utils';
import { PostBooking } from './PostBooking';
import { loadStripe } from '@stripe/stripe-js';

const UpcomingAppointments = (props) => {
    const [appts, setAppts] = useState([]);
    const [loadingStripe, setLoadingStripe] = useState(false);
    const history = useHistory();
    const fetchAppointments = async () => {
        try {
            const res = await axiosInstance.get(`/appointments/upcoming`);
            setAppts(res.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);

    const completePayment = async () => {
        // console.log(stripe_id);
        // setLoadingStripe(true);
        // const stripe = await loadStripe(
        //     'pk_live_1NBNGEeqHEJAB8AEENRxfGCG00tCAyJz0W'
        // );
        // stripe.redirectToCheckout({ sessionId: stripe_id });
        try {
            axiosInstance.post();
        } catch (e) {
            console.log(e);
        }
    };

    const completeAppointment = async (appt_id) => {
        const res = await axiosInstance.post(
            `/appointments/complete?apptid=${appt_id}`
        );
        await fetchAppointments();
    };

    return (
        <div className="column">
            <h3 className="is-bold pad-bottom-double">Appointments</h3>
            <BarButtonSelector
                cardMenu
                info
                headings={['Upcoming', 'Completed']}
                urls={['/appointments/upcoming', '/appointments/completed']}>
                <Switch>
                    <Route path="/appointments">
                        <div className="card padded rounded border">
                            {appts.map((item, idx) => (
                                <div className="pad-left pad-right">
                                    <h5 className="is-medium pad-bottom">
                                        {moment(item.date).format('MMMM D')}
                                    </h5>
                                    {item.patients.map((patient, idx) => (
                                        <div>
                                            <p className="is-info is-small pad-bottom-half">
                                                {parseMinFromMid(item.time)} -{' '}
                                                {parseMinFromMid(
                                                    item.time + item.duration
                                                )}
                                            </p>
                                            <h6 className="is-bold">
                                                {patient.first_name}{' '}
                                                {patient.last_name}
                                            </h6>
                                            <h6 className="is-light">
                                                {patient.service
                                                    .charAt(0)
                                                    .toUpperCase()}
                                                {patient.service.slice(1)} +
                                                Digital Smile Scan
                                            </h6>
                                            <p className="pad-top">
                                                {item.address1}
                                            </p>
                                            {item.address2 && (
                                                <p className="pad">
                                                    {item.address2}
                                                </p>
                                            )}
                                            {item.special && (
                                                <p className="pad-top">
                                                    <span className="is-bold">
                                                        Special Instructions:
                                                    </span>{' '}
                                                    {item.special}
                                                </p>
                                            )}
                                            {item.payment_completed ? (
                                                <p className="pad-top is-bold">
                                                    Paid!
                                                </p>
                                            ) : (
                                                <div>
                                                    <p className="pad-top is-bold">
                                                        Payment Pending
                                                    </p>
                                                    {!isProvider() && (
                                                        <div
                                                            className={`button margin-top ${
                                                                loadingStripe &&
                                                                'is-loading'
                                                            }`}
                                                            onClick={() =>
                                                                completePayment(
                                                                    item.stripe_id
                                                                )
                                                            }>
                                                            Complete Payment
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <Link
                                                to={`/appointments/${item.appointment_id}`}>
                                                <div className="button margin-top is-info">
                                                    Appointment Detail
                                                </div>
                                            </Link>
                                            {isProvider() && (
                                                <div
                                                    className="button margin-top is-primary margin-left"
                                                    onClick={() =>
                                                        completeAppointment(
                                                            item.appointment_id
                                                        )
                                                    }>
                                                    Mark Appointment Completed
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {idx !== appts.length - 1 && (
                                        <hr className="dropdown-divider margin-top margin-bottom" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </Route>
                </Switch>
            </BarButtonSelector>
        </div>
    );
};

const CompletedAppointments = (props) => {
    const [appts, setAppts] = useState([]);
    const [loadingStripe, setLoadingStripe] = useState(false);
    const history = useHistory();
    const fetchAppointments = async () => {
        try {
            const res = await axiosInstance.get(`/appointments/completed`);
            setAppts(res.data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);

    const completePayment = async (stripe_id) => {
        console.log(stripe_id);
        setLoadingStripe(true);
        const stripe = await loadStripe(
            'pk_live_1NBNGEeqHEJAB8AEENRxfGCG00tCAyJz0W'
        );
        stripe.redirectToCheckout({ sessionId: stripe_id });
    };

    const completeAppointment = async (appt_id) => {
        const res = await axiosInstance.post(
            `/appointments/complete?apptid=${appt_id}`
        );
    };

    return (
        <div className="column">
            <h3 className="is-bold pad-bottom-double">Appointments</h3>
            <BarButtonSelector
                cardMenu
                info
                headings={['Upcoming', 'Completed']}
                urls={['/appointments/upcoming', '/appointments/completed']}>
                <Switch>
                    <Route path="/appointments">
                        <div className="card padded rounded border">
                            {appts.map((item, idx) => (
                                <div className="pad-left pad-right">
                                    <h5 className="is-medium pad-bottom">
                                        {moment(item.date).format('MMMM D')}
                                    </h5>
                                    {item.patients.map((patient, idx) => (
                                        <div>
                                            <p className="is-info is-small pad-bottom-half">
                                                {parseMinFromMid(item.time)} -{' '}
                                                {parseMinFromMid(
                                                    item.time + item.duration
                                                )}
                                            </p>
                                            <h6 className="is-bold">
                                                {patient.first_name}{' '}
                                                {patient.last_name}
                                            </h6>
                                            <h6 className="is-light">
                                                {patient.service
                                                    .charAt(0)
                                                    .toUpperCase()}
                                                {patient.service.slice(1)} +
                                                Digital Smile Scan
                                            </h6>
                                            <p className="pad-top">
                                                {item.address1}
                                            </p>
                                            {item.address2 && (
                                                <p className="pad">
                                                    {item.address2}
                                                </p>
                                            )}
                                            {item.special && (
                                                <p className="pad-top">
                                                    <span className="is-bold">
                                                        Special Instructions:
                                                    </span>{' '}
                                                    {item.special}
                                                </p>
                                            )}
                                            {item.payment_completed ? (
                                                <p className="pad-top is-bold">
                                                    Paid!
                                                </p>
                                            ) : (
                                                <div>
                                                    <p className="pad-top is-bold">
                                                        Payment Pending
                                                    </p>
                                                    {!isProvider() && (
                                                        <div
                                                            className={`button margin-top ${
                                                                loadingStripe &&
                                                                'is-loading'
                                                            }`}
                                                            onClick={() =>
                                                                completePayment(
                                                                    item.stripe_id
                                                                )
                                                            }>
                                                            Complete Payment
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <Link
                                                to={`/appointments/${item.appointment_id}`}>
                                                <div className="button margin-top is-info">
                                                    Appointment Detail
                                                </div>
                                            </Link>
                                            {/* {isProvider() && (
                                                <Link
                                                    to={`/appointments/complete/${item.appointment_id}`}>
                                                    <div className="button margin-top is-primary margin-left">
                                                        Mark Appointment
                                                        Completed
                                                    </div>
                                                </Link>
                                            )} */}
                                        </div>
                                    ))}
                                    {idx !== appts.length - 1 && (
                                        <hr className="dropdown-divider margin-top margin-bottom" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </Route>
                </Switch>
            </BarButtonSelector>
        </div>
    );
};
export const AppointmentPage = (props) => {
    return (
        <div id="profile-page">
            <Navbar filled provider={isProvider()} dark appts />
            <div className="container">
                <div className="columns pad-top-double pad-bottom-double">
                    <Switch>
                        <Route path={'/appointments/upcoming'} exact>
                            <UpcomingAppointments />
                        </Route>
                        <Route path={'/appointments/completed'} exact>
                            <CompletedAppointments />
                        </Route>
                        <Route path={'/appointments'} exact>
                            <Redirect to="/appointments/upcoming" />
                        </Route>
                        <Route path="/appointments/:apptid">
                            <PostBooking />
                        </Route>
                    </Switch>
                </div>
            </div>
            <Footer light />
        </div>
    );
};
