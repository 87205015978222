import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import StarRatings from 'react-star-ratings';
import { useHistory } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import ImgModal from '../shared/Modal';
import { axiosInstance } from '../../axios';
import { ReviewCard } from './reviews/ReviewCard';
import { isAuthenticated, authUsername } from '../../auth';
import { MapPin, CheckCircle } from 'react-feather';
import { Gallery } from './Gallery';
import { ArchImage } from '../shared/Image';
import { MediaGallery } from '../settings/VideoGallery';

export const ProfileSection = (props) => {
    const employeeHeadings = ['Portfolio', 'Reviews', 'Background'];
    const clinicHeadings = ['Portfolio', 'Reviews', 'People', 'About'];

    return (
        <div className="profile-section">
            <div className="card rounded border">
                <div className="card-content pad-top-double pad-left-double pad-right-double">
                    <ProfileHeader
                        username={props.username || authUsername()}
                    />
                    {/* <Awards /> */}
                    {/* <div className="pad-top pad-bottom">
                        <Gallery username={props.username || authUsername()} />
                    </div> */}
                    <div className="pad-top">
                        <MediaGallery
                            username={props.username || authUsername()}
                        />
                    </div>
                    <ReviewCard username={props.username || authUsername()} />
                    {/* <BarButtonSelector
                        headings={employeeHeadings}
                        urls={[
                            '/profile/portfolio',
                            '/profile/reviews',
                            '/profile/background',
                        ]}>
                        <Switch>
                            <Route path="/profile" exact>
                                <PortfolioCard />
                            </Route>
                            <Route path="/profile/portfolio" exact>
                                <PortfolioCard />
                            </Route>
                            <Route path="/profile/reviews" exact>
                                <ReviewCard hideWrite />
                            </Route>
                            <Route path="/profile/background" exact>
                                <div>
                                    <div className="margin-bottom">
                                        <SkillsCard />
                                    </div>
                                    <div className="margin-bottom">
                                        <CertificationsCard />
                                    </div>
                                    <div className="margin-bottom">
                                        <ExperienceCard />
                                    </div>
                                </div>
                            </Route>
                            <Route path="/profile/*" exact>
                                <Redirect to="/profile" />
                            </Route>
                        </Switch>
                    </BarButtonSelector> */}
                </div>
            </div>
        </div>
    );
};

export const Awards = (props) => {
    const [awards, setAwards] = useState([]);
    return (
        <div className="Awards-Card pad-top-double">
            <h5 className="is-black is-bold">Awards</h5>
            <div className="pad-top awards-box">
                <div className="pad-left level no-margin-bottom">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="awards-image"></div>
                        </div>
                        <div className="level-item">
                            <div className="awards-image"></div>
                        </div>
                        <div className="level-item">
                            <div className="awards-image"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Media = (props) => {
    return (
        <div className="Media-Card pad-top">
            <h5 className="is-black is-bold">Media</h5>
            <div className="pad-top Media-box">
                <div className="pad-left level no-margin-bottom">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="media-image"></div>
                        </div>
                        <div className="level-item">
                            <div className="media-image"></div>
                        </div>
                        <div className="level-item">
                            <div className="media-image"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Reviews = (props) => {
    return (
        <div className="Review-Card pad-top">
            <h5 className="is-black is-bold">Patient Reviews</h5>
        </div>
    );
};

export const ProfileHeader = (props) => {
    const [isFetching, setIsFetching] = useState(true);
    const [headerData, setHeaderData] = useState({});
    const [reviews, setReviews] = useState([]);
    const username = props.username;
    const [openModal, setOpenModal] = useState(false);

    const [avgStars, setAvgStars] = useState(0);
    const [numReviews, setNumReviews] = useState(0);
    const history = useHistory();

    const {
        bio,
        first_name,
        last_name,
        location,
        photo,
        provider_type,
        can_message,
        bookings_completed,
    } = headerData;

    const fetchHeader = async () => {
        setIsFetching(true);
        try {
            const res = await axiosInstance.get(
                `/profile/header?username=${username}`
            );
            console.log(res.data);
            setHeaderData(res.data);
        } catch (e) {
            history.push('/404');
        }
        setIsFetching(false);
    };

    const fetchReviews = async () => {
        setIsFetching(true);
        try {
            const res = await axiosInstance.get(
                `/profile/reviews?username=${username}`
            );
            console.log(res.data);
            var arr = res.data.reviews.filter(function (item) {
                return item.review_id !== null;
            });
            setReviews(arr);
            setNumReviews(res.data.num_reviews);
            setAvgStars(res.data.rating);
        } catch (e) {}
        setIsFetching(false);
    };

    const showModal = () => {
        setOpenModal(true);
    };

    useEffect(() => {
        fetchHeader();
        fetchReviews();
    }, []);

    return (
        <div>
            <div className="profile-header">
                <div className="profile-corner-btns">
                    {/* {can_message && (
                        <div className="button is-primary is-outlined is-small">
                            Message
                        </div>
                    )} */}
                </div>
                <div className="profile-basic-info">
                    <ArchImage className="rounded-img" src={photo} />
                    <div className="profile-name pad-left">
                        <div className="level no-margin-bottom">
                            <div className="level-left">
                                <div className="level-item">
                                    <h4 className="is-bold">
                                        {!isFetching &&
                                            `${first_name} ${last_name}`}
                                    </h4>
                                </div>
                                <div className="level-item">
                                    <span className="is-small">
                                        {!isFetching && `@${username}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p>{isFetching ? <Skeleton /> : provider_type}</p>

                        {!isFetching ? (
                            <div className="no-margin-bottom level-left">
                                <div className="level-left">
                                    <div className="level-item">
                                        <MapPin size={16} />
                                        <p className="is-small pad-left-half">
                                            {location
                                                ? location
                                                : 'This user has not yet added their location'}
                                        </p>
                                    </div>
                                    {avgStars ? (
                                        <div className="level-item pad-left">
                                            <StarRatings
                                                rating={avgStars}
                                                starRatedColor="#F5D04D"
                                                starEmptyColor="#E0E0E0"
                                                starDimension="20px"
                                                starSpacing="2px"
                                            />
                                        </div>
                                    ) : (
                                        <div className="level-item pad-left">
                                            <StarRatings
                                                rating={5}
                                                starRatedColor="#F5D04D"
                                                starEmptyColor="#E0E0E0"
                                                starDimension="20px"
                                                starSpacing="2px"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <h6>{!location && <Skeleton />}</h6>
                        )}
                    </div>
                </div>
                <div className="profile-bio pad-left pad-top">
                    <ShowMoreText
                        /* Default options */
                        lines={2}
                        more="Show more"
                        less="Show less"
                        anchorClass=""
                        expanded={false}
                        width={0}>
                        {bio}
                    </ShowMoreText>
                </div>
                <div className="profile-bookings-reviews pad-left pad-top">
                    <div className="level-left">
                        <div className="level-item">
                            {bookings_completed ? (
                                <p>
                                    <span className="is-purple is-bold">
                                        {bookings_completed}
                                    </span>
                                    <span className="has-text-grey">
                                        {' Bookings completed'}
                                    </span>
                                </p>
                            ) : (
                                <p>
                                    <span className="is-purple is-bold is-size-5">
                                        26
                                    </span>
                                    <span className="has-text-grey">
                                        {' bookings completed'}
                                    </span>
                                </p>
                            )}
                        </div>
                        <div className="level-item">
                            {avgStars ? (
                                <p>
                                    <span className="is-purple is-bold is-size-5">
                                        {avgStars.toFixed(2)}/5
                                    </span>
                                    <span className="has-text-grey">
                                        {' stars (' + numReviews + ' reviews)'}
                                    </span>
                                </p>
                            ) : (
                                <p>
                                    <span className="is-purple is-bold is-size-5">
                                        5/5
                                    </span>
                                    <span className="has-text-grey">
                                        {' stars (' + numReviews + ' reviews)'}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
