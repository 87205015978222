import React from 'react';
import { Helmet } from 'react-helmet';
import { Navbar } from '../../shared/Navbar';
import { ProfileSidebar } from '../Sidebar';
import { BarButtonSelector } from '../BarButtonSelector';
import { RequestCard } from './RequestCard';
import { Route, Switch } from 'react-router-dom';
import { ConnectionsCard } from './ConnectionsCard';

export const NetworkCard = (props) => {
    const headings = ['Connection Requests', 'Connections'];
    const urls = ['/profile/network/requests', '/profile/network/connections'];
    return (
        <div className="card rounded">
            <div className="pad-bottom pad-left-double pad-right-double">
                <BarButtonSelector headings={headings} urls={urls} small>
                    <Switch>
                        <Route path="/profile/network/requests" exact>
                            <RequestCard />
                        </Route>
                        <Route path="/profile/network/connections" exact>
                            <ConnectionsCard />
                        </Route>
                        <Route path="/profile/network">
                            <RequestCard />
                        </Route>
                    </Switch>
                </BarButtonSelector>
            </div>
        </div>
    );
};

export const NetworkPage = (props) => {
    return (
        <div id="profile-page">
            <Helmet>
                <title>Network - Arch Dental</title>
            </Helmet>
            <Navbar filled protect />
            <div className="columns profile-container">
                <ProfileSidebar network />
                <div className="column is-8">
                    <NetworkCard />
                </div>
            </div>
        </div>
    );
};
