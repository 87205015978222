import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './App.scss';

import { LandingPage } from './components/landing/LandingPage';
import { WhyHu } from './components/landing/WhyHu';
import { ForHygienists } from './components/landing/ForHygienists';
import { FAQ } from './components/landing/FAQ';
import { Services } from './components/landing/Services';
import { BlogPage } from './components/landing/Blog';
import { Login } from './components/Login';
import { ProfilePage } from './components/profile/ProfilePage';
import { ReviewPage } from './components/profile/reviews/ReviewPage.jsx';
import { SettingsPage } from './components/settings/SettingsPage';
import { axiosInstance } from './axios';
import { ErrorPage } from './components/ErrorPage';
import { NetworkPage } from './components/profile/network/NetworkPage';
import { ProviderRegisterPage } from './components/onboard/provider/RegisterPage';
import { UrgentDental } from './components/landing/UrgentDental';
import {
    initAuth,
    isAuthenticated,
    logout,
    authUsername,
    authFirst,
    PrivateRoute,
    isProvider,
} from './auth';
import { AvailabilityPage } from './components/profile/availability/AvailabilityPage';
import { ApptBookingFlow } from './components/appointment/booking/BookingFlow';
import { AppointmentPage } from './components/appointment/manage/AppointmentPage';
import { BrowseHygienists } from './components/landing/BrowseHygienists';
import ScrollToTop from './components/shared/ScrollToTop';
import { AppointmentDashboard } from './components/admin/AppointmentDashboard';
import { CompleteAppt } from './components/appointment/manage/CompleteAppt';

const App = () => {
    const [redirectToLogin, setRedirectLogin] = useState(false);

    // global level catch for unauthed requests to logout
    const logoutOnUnauth = (err) => {
        if (err.response?.status === 401) {
            logout();
            setRedirectLogin(true);
        }

        return Promise.reject(err);
    };

    const returnResponse = (res) => res;

    axiosInstance.interceptors.response.use(returnResponse, logoutOnUnauth);
    initAuth();
    return (
        <Router>
            <ScrollToTop />
            {redirectToLogin && <Redirect to="/login" />}
            <Helmet>
                <title>YourTeeth</title>
            </Helmet>
            <Switch>
                <Route path="/" exact>
                    {isAuthenticated() && isProvider() ? (
                        <Redirect to="/profile" />
                    ) : (
                        <LandingPage />
                    )}
                </Route>
                <Route path="/whyhu" exact>
                    <WhyHu />
                </Route>
                <Route path="/urgentdental" exact>
                    <UrgentDental />
                </Route>
                <Route path="/forhygienists" exact>
                    <ForHygienists />
                </Route>
                <Route path="/faq">
                    <FAQ />
                </Route>
                <Route path="/services" exact>
                    <Services />
                </Route>
                <Route path="/browsehygienists" exact>
                    <BrowseHygienists />
                </Route>
                <Route path="/blog" exact>
                    <BlogPage />
                </Route>
                <Route path="/appointment/book">
                    <ApptBookingFlow />
                </Route>
                <PrivateRoute
                    path="/appointments/complete/:apptid"
                    component={CompleteAppt}
                />
                <PrivateRoute path="/appointments">
                    <AppointmentPage />
                </PrivateRoute>
                <Route
                    path="/register/provider"
                    component={ProviderRegisterPage}
                />
                <Route path="/login" component={Login} exact />
                <PrivateRoute
                    providers
                    path="/profile/settings"
                    component={SettingsPage}
                />
                <PrivateRoute path="/profile/network">
                    <NetworkPage />
                </PrivateRoute>
                <PrivateRoute path="/profile/" providers>
                    <ProfilePage />
                </PrivateRoute>
                <PrivateRoute path="/availability">
                    <AvailabilityPage />
                </PrivateRoute>
                <PrivateRoute path="/admin">
                    <AppointmentDashboard />
                </PrivateRoute>
                <Route path="/404">
                    <ErrorPage />
                </Route>
                <Route path="/:username/review" component={ReviewPage} />
                <Route
                    path="/:username"
                    render={(props) =>
                        isAuthenticated() &&
                        isProvider() &&
                        authUsername() === props.match.params.username ? (
                            <Redirect to="/profile" />
                        ) : (
                            <ProfilePage
                                username={props.match.params.username}
                            />
                        )
                    }
                />
                <Route path="*">
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
