import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../axios';
import { authUsername } from '../../auth';
import { FileUpload } from './Uploaders';
import { ArchImage } from '../shared/Image';

export const Gallery = (props) => {
    const username = props.username;
    const [gallery, setGallery] = useState([]);

    const fetchGallery = async () => {
        try {
            const res = await axiosInstance.get(
                `/profile/gallery?username=${props.username || authUsername()}`
            );
            const { photos } = res.data;
            setGallery(photos);
        } catch (e) {
            console.log(e.response);
        }
    };

    const deleteGalleryPhoto = (idx) => {
        const old = [...gallery];
        old.splice(idx, 1);
        console.log(old);
        setGallery(old);
    };

    useEffect(() => {
        fetchGallery();
    }, []);

    return (
        <div>
            <PhotoGalleryCard
                onSuccess={fetchGallery}
                onDelete={deleteGalleryPhoto}
                gallery={gallery}
                username={username}
            />
        </div>
    );
};

export const PhotoGalleryCard = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const deletePhoto = async (idx) => {
        props.onDelete(idx);
        await axiosInstance.delete(`/profile/gallery?idx=${idx}`);
    };

    return (
        <div>
            <div>
                <h5 className="is-bold is-black">Photos</h5>
                {/* {!props.username && (
                    <p>
                        Upload photos showing your most successful cases, before
                        and after photos, and service demos.
                    </p>
                )} */}
                <div className="photo-gallery pad-top">
                    {props.gallery &&
                        props.gallery.length > 0 &&
                        props.gallery.map((item, idx) => (
                            <div
                                className="photo-img-container"
                                style={{
                                    backgroundImage: `url(${item})`,
                                }}
                                alt="photo-gallery">
                                {!props.username && (
                                    <span
                                        className="icon icon-hover"
                                        onClick={() => deletePhoto(idx)}>
                                        <i className="material-icons">close</i>
                                    </span>
                                )}
                            </div>
                        ))}
                    {!props.username && (
                        <div className="arch-dropzone">
                            <span className="icon icon-large">
                                <i className="material-icons">cloud_upload</i>
                            </span>
                            <p className="pad-top">Browse for Files</p>
                            <FileUpload
                                url="/profile/gallery?name="
                                maxSize={5}
                                onSuccess={() => {
                                    setIsLoading(false);
                                    props.onSuccess();
                                }}
                                onUploadStart={() => setIsLoading(true)}>
                                <div
                                    className={`button is-primary margin-top ${
                                        isLoading && 'is-loading'
                                    }`}>
                                    Upload Photo
                                </div>
                            </FileUpload>
                        </div>
                    )}
                </div>
                <div className="columns pad-top">
                    {props.username &&
                        (!props.gallery || props.gallery.length === 0) && (
                            <h5 className="center">
                                {props.username} has not uploaded any photos to
                                their gallery.
                            </h5>
                        )}
                </div>
            </div>
        </div>
    );
};
