import React, { useState, useEffect, useContext } from 'react';
import ShowMoreText from 'react-show-more-text';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../../axios';
import StarRatings from 'react-star-ratings';
import AuthContext from '../../../context/auth';
import { ArchImage, imgStyle } from '../../shared/Image';

// import '../../styles/_colors.scss';

export const ReviewCard = (props) => {
    const { auth } = useContext(AuthContext);
    const [avgStars, setAvgStars] = useState(0);
    const [search, setSearch] = useState('');

    var username = props.username;

    if (username === 'profile') {
        username = auth.account?.username;
    }

    const [reviews, setReviews] = useState([]);
    const [numReviews, setNumReviews] = useState(0);
    const [isFetching, setIsFetching] = useState(true);

    const fetchReviews = async () => {
        setIsFetching(true);
        try {
            const res = await axiosInstance.get(
                `/profile/reviews?username=${username}`
            );
            var arr = res.data.reviews.filter(function (item) {
                return item.review_id !== null;
            });
            console.log(res.data);
            setReviews(arr);
            setNumReviews(res.data.num_reviews);
            setAvgStars(res.data.rating);
        } catch (e) {}
        setIsFetching(false);
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    return (
        <div className="Review-Card pad-top">
            <div className="level">
                <div className="level-left">
                    <h5 className="is-bold is-black">{'Patient Reviews'}</h5>
                </div>
                <div className="level-right">
                    {!props.hideWrite && (
                        <Link to={`/${username}/review`}>
                            <button className="button is-primary card-button">
                                Write a review
                            </button>
                        </Link>
                    )}
                </div>
            </div>
            <div className="level-left is-hidden-mobile">
                <div className="level-item">
                    <h6 className="is-bold">{numReviews + ' reviews'}</h6>
                </div>
                <div className="level-item">
                    <StarRatings
                        rating={avgStars}
                        starRatedColor="#F5D04D"
                        starEmptyColor="#E0E0E0"
                        starDimension="25px"
                        starSpacing="4px"
                    />
                </div>
                <div className="level-item">
                    <p>({avgStars.toString().slice(0, 3)}) </p>
                </div>
            </div>
            <div
                className="pad-top-half pad-bottom-half is-hidden-tablet"
                style={{
                    display: 'inline-flex',
                    flexdirection: 'row',
                    alignItems: 'center',
                }}>
                <h6 className="is-bold">{numReviews + ' reviews'}</h6>
                <div className="pad-left-half">
                    <StarRatings
                        rating={avgStars}
                        starRatedColor="#F5D04D"
                        starEmptyColor="#E0E0E0"
                        starDimension="25px"
                        starSpacing="4px"
                    />
                </div>
                <p className="pad-left-half">
                    ({avgStars.toString().slice(0, 3)}){' '}
                </p>
            </div>
            <div className="level-left pad-top">
                <div className="level-item">
                    <input
                        className="input"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="search"
                    />
                </div>
                <div className="level-item">
                    <p>Sort by: Most Recent</p>
                </div>
            </div>
            {reviews.map((item, idx) =>
                item.review_id !== null ? (
                    <div className="pad-top">
                        <div>
                            <div className="level-left is-hidden-mobile">
                                <div className="level-item">
                                    {item.first_name || item.last_name ? (
                                        <h6 className="is-bold">
                                            {item.first_name +
                                                ' ' +
                                                item.last_name}
                                        </h6>
                                    ) : (
                                        <h6 className="is-bold">
                                            {'Anonymous'}
                                        </h6>
                                    )}{' '}
                                </div>
                                <div className="level-item">
                                    <p>
                                        {item.time &&
                                            item.time.slice(
                                                0,
                                                item.time.indexOf(',')
                                            )}
                                    </p>
                                </div>
                            </div>
                            <div className="pad-top-half pad-bottom-half is-hidden-tablet">
                                <div
                                    className="pad-top-half pad-bottom-half"
                                    style={{
                                        display: 'inline-flex',
                                        flexdirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    {item.first_name || item.last_name ? (
                                        <h6 className="is-bold">
                                            {item.first_name +
                                                ' ' +
                                                item.last_name}
                                        </h6>
                                    ) : (
                                        <h6 className="is-bold">
                                            {'Anonymous'}
                                        </h6>
                                    )}{' '}
                                    <p className="pad-left-half">
                                        {item.time &&
                                            item.time.slice(
                                                0,
                                                item.time.indexOf(',')
                                            )}
                                    </p>
                                </div>
                                <div
                                    className="pad-bottom-half"
                                    style={{
                                        display: 'inline-flex',
                                        flexdirection: 'row',
                                    }}>
                                    <StarRatings
                                        rating={item.stars}
                                        starRatedColor="#F5D04D"
                                        starEmptyColor="#E0E0E0"
                                        starDimension="22px"
                                        starSpacing="2px"
                                    />
                                    {
                                        <p className="pad-left-half">
                                            {item.city + ', ' + item.state}
                                        </p>
                                    }
                                </div>

                                {item.title ? (
                                    <div>
                                        <h5 className="subtitle is-6 is-bold">
                                            {item.title}
                                        </h5>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div
                                className="pad-top-half pad-bottom-half is-hidden-mobile"
                                style={{
                                    display: 'inline-flex',
                                    flexdirection: 'row',
                                }}>
                                <StarRatings
                                    rating={item.stars}
                                    starRatedColor="#F5D04D"
                                    starEmptyColor="#E0E0E0"
                                    starDimension="22px"
                                    starSpacing="2px"
                                />
                                {
                                    <p className="pad-left-half">
                                        {item.city + ', ' + item.state}
                                    </p>
                                }
                                {item.title ? (
                                    <div className="pad-left">
                                        <h5 className="subtitle is-6 is-bold">
                                            {item.title}
                                        </h5>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div
                                className="description"
                                style={{ whiteSpace: 'pre-line' }}>
                                {item.description}
                            </div>
                            <div className="photo-gallery pad-top">
                                {item.attachments ? (
                                    item.attachments.map((item, idx) => (
                                        <ArchImage
                                            style={imgStyle}
                                            src={item}
                                        />
                                    ))
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                        <div className="bar margin-top"> </div>
                    </div>
                ) : (
                    <div></div>
                )
            )}
        </div>
    );
};
