import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import { PersonalInfoCard } from './PersonalInfo';
import { DocumentSettingsCard } from './Documents';
import { EmploymentSettingsCard } from './Employment';
import { PaySettingsCard } from './Pay';
import { TaxSettingsCard } from './Tax';
import { SettingsSidebar } from '../profile/Sidebar';
import { Footer } from '../shared/Footer';

import '../../styles/settings.scss';

export const SettingsPage = (props) => {
    return (
        <div id="profile-page">
            <Navbar filled dark />
            <div className="container pad-top-double pad-bottom-double">
                {/* <SettingsSidebar /> */}
                <div className="columns">
                    <div className="column is-full center">
                        <Switch>
                            <Route
                                path="/profile/settings"
                                exact
                                component={PersonalInfoCard}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
            <Footer light />
        </div>
    );
};
