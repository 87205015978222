import React, { useState } from 'react';
import { axiosInstance } from '../../../axios';
import { FileUpload } from '../Uploaders';

export const PhotoGalleryCard = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const deletePhoto = async (idx) => {
        props.onDelete(idx);
        await axiosInstance.delete(`/profile/gallery?idx=${idx}`);
    };

    return (
        <div>
            <div>
                <h5 className="is-bold">Photos</h5>
                {/* {!props.username && (
                    <p>
                        Upload photos showing your most successful cases, before
                        and after photos, and service demos.
                    </p>
                )} */}
                <div className="photo-gallery pad-top">
                    {props.gallery &&
                        props.gallery.length > 0 &&
                        props.gallery.map((item, idx) => (
                            <div
                                className="photo-img-container"
                                style={{
                                    backgroundImage: `url(${item})`,
                                }}
                                alt="photo-gallery">
                                {!props.username && (
                                    <span
                                        className="icon icon-hover"
                                        onClick={() => deletePhoto(idx)}>
                                        <i className="material-icons">close</i>
                                    </span>
                                )}
                            </div>
                        ))}
                    {!props.username && (
                        <div className="arch-dropzone">
                            <span className="icon icon-large">
                                <i className="material-icons">cloud_upload</i>
                            </span>
                            <p className="pad-top">Browse for Files</p>
                            <FileUpload
                                maxSize={5}
                                onSuccess={(item) => {
                                    setIsLoading(false);
                                    props.onSuccess(item);
                                }}
                                onUploadStart={() => setIsLoading(true)}>
                                <div
                                    className={`button is-primary margin-top ${
                                        isLoading && 'is-loading'
                                    }`}>
                                    Upload Photo
                                </div>
                            </FileUpload>
                        </div>
                    )}
                </div>
                <div className="columns pad-top">
                    {props.username &&
                        (!props.gallery || props.gallery.length === 0) && (
                            <h5 className="center">
                                {props.username} has not uploaded any photos to
                                their gallery.
                            </h5>
                        )}
                </div>
            </div>
        </div>
    );
};
