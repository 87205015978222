import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../../../axios';
import { informedConsent, covidLiability } from '../../../utils';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';

const InputEnterField = (props) => {
    const [val, setValue] = useState('');

    return (
        <input
            className="input"
            value={val}
            onChange={(e) => setValue(e.target.value)}
            {...props}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && val) {
                    props.onEnter(val);
                    setValue('');
                }
            }}
        />
    );
};
export const Consent = (props) => {
    const [appt, setAppt] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    const [covid, updateCovid] = useState({
        symptoms: [],
        exposure: false,
        test: false,
    });

    const [medical, updateMedical] = useState({
        age: 1,
        allergies: [],
        medication: [],
        surgeries: [],
    });

    const [allergies, setAllergies] = useState(false);
    const [med, setMed] = useState(false);
    const [surg, setSurg] = useState(false);

    const setCovid = (covid) => {
        updateCovid(covid);
    };

    const setMedical = (med) => {
        updateMedical(med);
    };

    const setAge = (age) => {
        const copy = { ...medical };
        copy.age = parseInt(age);
        setMedical(copy);
    };

    const addAllergy = (alg) => {
        const copy = { ...medical };
        copy.allergies.push(alg);
        setMedical(copy);
    };

    const addMedication = (name) => {
        const copy = { ...medical };
        copy.medication.push(name);
        setMedical(copy);
    };

    const addSurgery = (name) => {
        const copy = { ...medical };
        copy.surgeries.push(name);
        setMedical(copy);
    };

    const setExposure = (exp) => {
        const copy = { ...covid };
        copy.exposure = exp;
        setCovid(copy);
    };

    const toggleTest = () => {
        const copy = { ...covid };
        copy.test = !copy.test;
        setCovid(copy);
    };

    const toggleFever = () => {
        const copy = { ...covid };
        if (copy.symptoms.includes('Fever')) {
            copy.symptoms = copy.symptoms.filter((item) => item !== 'Fever');
        } else {
            copy.symptoms.push('Fever');
        }

        setCovid(copy);
    };

    const toggleShortness = () => {
        const copy = { ...covid };
        if (copy.symptoms.includes('Shortness of Breath')) {
            copy.symptoms = copy.symptoms.filter(
                (item) => item !== 'Shortness of Breath'
            );
        } else {
            copy.symptoms.push('Shortness of Breath');
        }

        setCovid(copy);
    };

    const toggleCough = () => {
        const copy = { ...covid };
        if (copy.symptoms.includes('Cough')) {
            copy.symptoms = copy.symptoms.filter((item) => item !== 'Cough');
        } else {
            copy.symptoms.push('Cough');
        }

        setCovid(copy);
    };

    const [huConsent, setHuConsent] = useState(false);
    const [covidLiabSigned, setCovidLiab] = useState(false);

    const isFormSubmitable = () => {
        return huConsent && covidLiabSigned;
    };

    if (!props.appointment || props.appointment.length === 0) {
        history.push('/appointment/book');
        return null;
    }

    const payload = () => {
        const copy = { ...props.appointment };
        const covid_saftey = {
            symptoms: covid.symptoms,
            test: covid.test,
            exposure: covid.exposure,
        };
        const medical_checklist = {
            age: medical.age,
            allergies: medical.allergies,
            medication: medical.medication,
            surgeries: medical.surgeries,
        };

        copy.covid_saftey = covid_saftey;
        copy.medical_checklist = medical_checklist;
        copy.signed_consent = huConsent;
        copy.covid_waiver = covidLiabSigned;

        return copy;
    };

    const submitForm = async () => {
        console.log('yo');
        if (!isFormSubmitable()) return;
        setIsLoading(true);
        try {
            const res = await axiosInstance.post(
                '/appointments/book',
                payload()
            );
            const sessionId = res.data.session_id;
            // const stripe = await loadStripe(
            //     'pk_test_51GukstEQO3htPIIFb6H7A42k5h2GA61Cj7QVYpDMWxPYxejGhsfNysTNYtL1L0ipLDBQ209U9MzNLzBPWQil3Na300Vpfe8SA9'
            // );
            const stripe = await loadStripe(
                'pk_live_1NBNGEeqHEJAB8AEENRxfGCG00tCAyJz0W'
            );

            stripe.redirectToCheckout({ sessionId: sessionId });
        } catch (e) {
            console.log(e.message);
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className="hu-hero">
                <p className="accent-underline">Step 4 of 4</p>
                <h3 className="is-bold pad-top-half">
                    Medical History and Consent
                </h3>
            </div>
            <div className="container pad-top-double">
                <div className="card rounded padded border margin-bottom">
                    <div>
                        <h5 className="is-bold">
                            <span className="card-number margin-right-half">
                                1
                            </span>
                            COVID-19 Safety Precautions
                        </h5>
                        <div className="pad-left-double">
                            <p>
                                To best protect our hygienists and communtiies,
                                please complete all the following questions
                                before your appointment.
                            </p>
                            <div className="pad-top">
                                <p className="is-medium">
                                    In the past 7 days, have you experienced any
                                    of these symptoms?
                                </p>
                                <div className="columns">
                                    <div className="column is-3">
                                        <FormControlLabel
                                            label={'Fever'}
                                            control={
                                                <Checkbox
                                                    className="arch-checkbox"
                                                    checked={covid.symptoms.includes(
                                                        'Fever'
                                                    )}
                                                    onChange={toggleFever}
                                                />
                                            }></FormControlLabel>
                                    </div>
                                    <div className="column is-3">
                                        <FormControlLabel
                                            label={'Cough'}
                                            control={
                                                <Checkbox
                                                    className="arch-checkbox"
                                                    checked={covid.symptoms.includes(
                                                        'Cough'
                                                    )}
                                                    onChange={toggleCough}
                                                />
                                            }></FormControlLabel>
                                    </div>
                                    <div className="column">
                                        <FormControlLabel
                                            label={'Shortness of Breath'}
                                            control={
                                                <Checkbox
                                                    className="arch-checkbox"
                                                    checked={covid.symptoms.includes(
                                                        'Shortness of Breath'
                                                    )}
                                                    onClick={toggleShortness}
                                                />
                                            }></FormControlLabel>
                                    </div>
                                </div>
                            </div>
                            <div className="pad-top">
                                <p className="is-medium">
                                    In the past 14 days, have you tested for
                                    COVID-19 with a positive or unknown result?
                                </p>
                                <div className="columns">
                                    <div className="column is-3">
                                        <FormControlLabel
                                            label={'Yes'}
                                            control={
                                                <Checkbox
                                                    className="arch-checkbox"
                                                    checked={
                                                        covid.exposure === true
                                                    }
                                                    onChange={() =>
                                                        setExposure(
                                                            !covid.exposure
                                                        )
                                                    }
                                                />
                                            }></FormControlLabel>
                                    </div>
                                    <div className="column is-3">
                                        <FormControlLabel
                                            label={'No'}
                                            control={
                                                <Checkbox
                                                    className="arch-checkbox"
                                                    checked={
                                                        covid.exposure === false
                                                    }
                                                    onChange={() =>
                                                        setExposure(
                                                            !covid.exposure
                                                        )
                                                    }
                                                />
                                            }></FormControlLabel>
                                    </div>
                                </div>
                            </div>
                            <div className="pad-top">
                                <p className="is-medium">
                                    In the past 14 days, have you had known
                                    exposure to a COVID-19 patient?
                                </p>
                                <div className="columns">
                                    <div className="column is-3">
                                        <FormControlLabel
                                            label={'Yes'}
                                            control={
                                                <Checkbox
                                                    className="arch-checkbox"
                                                    checked={covid.test}
                                                    onChange={toggleTest}
                                                />
                                            }></FormControlLabel>
                                    </div>
                                    <div className="column is-3">
                                        <FormControlLabel
                                            label={'No'}
                                            control={
                                                <Checkbox
                                                    className="arch-checkbox"
                                                    checked={!covid.test}
                                                    onChange={toggleTest}
                                                />
                                            }></FormControlLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card rounded padded border margin-bottom">
                    <div>
                        <h5 className="is-bold">
                            <span className="card-number margin-right-half">
                                2
                            </span>
                            Medical Checklist
                        </h5>
                    </div>
                    <div className="pad-left-double margin-top">
                        <p className="is-medium">Age</p>

                        <div className="select">
                            <select
                                value={medical.age}
                                onChange={(e) => setAge(e.target.value)}>
                                {[...Array(99).keys()].map((item) => (
                                    <option value={item + 1}>{item + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className="pad-top">
                            <p className="is-medium">
                                Do you have any allergies?
                            </p>
                            <div
                                className="columns"
                                style={{ marginBottom: '0px' }}>
                                <div className="column is-3">
                                    <FormControlLabel
                                        label={'Yes'}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={allergies}
                                                onChange={() => {
                                                    setAllergies(!allergies);
                                                }}
                                            />
                                        }></FormControlLabel>
                                </div>
                                <div className="column is-3">
                                    <FormControlLabel
                                        label={'No'}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={!allergies}
                                                onChange={() => {
                                                    setAllergies(!allergies);
                                                }}
                                            />
                                        }></FormControlLabel>
                                </div>
                            </div>
                            {allergies && (
                                <div>
                                    <div>
                                        {medical.allergies.map((item, idx) => (
                                            <div className="tag margin-right-half margin-bottom">
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                    <InputEnterField
                                        onEnter={addAllergy}
                                        placeholder="List all Allergies"
                                    />
                                </div>
                            )}
                        </div>

                        <div className="pad-top">
                            <p className="is-medium">
                                Are you currently on any medications?
                            </p>
                            <div
                                className="columns"
                                style={{ marginBottom: '0px' }}>
                                <div className="column is-3">
                                    <FormControlLabel
                                        label={'Yes'}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={med}
                                                onChange={() => {
                                                    setMed(!med);
                                                }}
                                            />
                                        }></FormControlLabel>
                                </div>
                                <div className="column is-3">
                                    <FormControlLabel
                                        label={'No'}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={!med}
                                                onChange={() => {
                                                    setMed(!med);
                                                }}
                                            />
                                        }></FormControlLabel>
                                </div>
                            </div>
                            {med && (
                                <div>
                                    <div>
                                        {medical.medication.map((item, idx) => (
                                            <div className="tag margin-right-half margin-bottom">
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                    <InputEnterField
                                        onEnter={addMedication}
                                        placeholder="List all Medication"
                                    />
                                </div>
                            )}
                        </div>

                        <div className="pad-top">
                            <p className="is-medium">
                                Have your recently had any major surgeries or
                                medical treatments?{' '}
                            </p>
                            <div
                                className="columns"
                                style={{ marginBottom: '0px' }}>
                                <div className="column is-3">
                                    <FormControlLabel
                                        label={'Yes'}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={surg}
                                                onChange={() => {
                                                    setSurg(!surg);
                                                }}
                                            />
                                        }></FormControlLabel>
                                </div>
                                <div className="column is-3">
                                    <FormControlLabel
                                        label={'No'}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={!surg}
                                                onChange={() => {
                                                    setSurg(!surg);
                                                }}
                                            />
                                        }></FormControlLabel>
                                </div>
                            </div>
                            {surg && (
                                <div>
                                    <div>
                                        {medical.surgeries.map((item, idx) => (
                                            <div className="tag margin-right-half margin-bottom">
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                    <InputEnterField
                                        onEnter={addSurgery}
                                        placeholder="List all Surgeries or Treatments"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card rounded padded border margin-bottom">
                    <div>
                        <h5 className="is-bold">
                            <span className="card-number margin-right-half">
                                3
                            </span>
                            COVID-19 Liability Waiver
                        </h5>
                        <textarea
                            className="textarea margin-top-half"
                            rows={10}
                            value={covidLiability}
                            readOnly
                        />
                        <div className="pad-top center-flex">
                            <FormControlLabel
                                label={`I agree to the terms of YourTeeth's COVID-19 Liability Waiver`}
                                control={
                                    <Checkbox
                                        className="arch-checkbox"
                                        checked={covidLiabSigned}
                                        onChange={() =>
                                            setCovidLiab(!covidLiabSigned)
                                        }
                                    />
                                }></FormControlLabel>
                        </div>
                    </div>
                </div>
                <div className="card rounded padded border margin-bottom">
                    <div>
                        <h5 className="is-bold">
                            <span className="card-number margin-right-half">
                                4
                            </span>
                            Sign the Informed Consent
                        </h5>
                        <textarea
                            className="textarea margin-top-half"
                            rows={10}
                            value={informedConsent}
                            readOnly
                        />
                        <div className="pad-top center-flex">
                            <FormControlLabel
                                label={`I agree to the terms of YourTeeth's Informed Consent`}
                                control={
                                    <Checkbox
                                        className="arch-checkbox"
                                        checked={huConsent}
                                        onChange={() =>
                                            setHuConsent(!huConsent)
                                        }
                                    />
                                }></FormControlLabel>
                        </div>
                    </div>
                </div>
                <div className="center-flex pad-bottom">
                    <div
                        className={`button is-primary ${
                            isLoading && `is-loading`
                        }`}
                        onClick={submitForm}
                        disabled={!isFormSubmitable()}>
                        Continue to Payment
                    </div>
                </div>
            </div>
        </div>
    );
};
