import React from 'react';

import { Navbar } from '../shared/Navbar';
import { ProfileSidebar, TodoSidebar } from './Sidebar';
import { ProfileSection } from './Section';
import { Footer } from '../shared/Footer';
import '../../styles/profile.scss';
import { AppointmentBookingCard } from './AppointmentBookingCard';
import { authUsername } from '../../auth';

export const ProfilePage = (props) => {
    return (
        <div id="profile-page">
            <Navbar filled provider dark />
            <div className="container">
                <div className="columns pad-top-double pad-bottom-double">
                    <div className="column is-9">
                        <ProfileSection username={props.username} />
                    </div>
                    <div className="column is-3">
                        <AppointmentBookingCard
                            username={props.username || authUsername()}
                        />
                    </div>
                    {/* {!props.username && <TodoSidebar />} */}
                </div>
            </div>
            <Footer light />
        </div>
    );
};
