import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import '../../styles/barselect.scss';
import { useEffect } from 'react';

export const BarButtonSelector = (props) => {
    const [selected, setSelected] = useState(props.selected || 0);
    const history = useHistory();
    const location = useLocation();

    const checkUrl = () => {
        if (props.urls) {
            const path = location.pathname.split('/').slice(-1)[0];

            const urls = props.urls.map((item) => item.split('/').slice(-1)[0]);

            console.log(path, urls);

            const idx = urls.indexOf(path);
            idx === -1 ? setSelected(0) : setSelected(idx);
        } else {
            setSelected(0);
        }
    };

    useEffect(() => {
        checkUrl();
    }, [location.pathname]);

    return (
        <div className={`bar-button-selector pad-top ${props.className}`}>
            <div
                className={
                    props.cardMenu && 'card rounded border pad-left-half'
                }>
                <div className="buttons no-margin-bottom">
                    {props.headings.map((item, idx) => (
                        <div
                            key={item}
                            onClick={() => {
                                if (props.urls) {
                                    history.push(props.urls[idx]);
                                }
                                setSelected(idx);
                            }}
                            className={`bar-button ${props.small && 'small'} ${
                                props.info && 'is-info'
                            } no-margin-bottom ${
                                idx === selected && 'selected'
                            }`}>
                            {item}
                        </div>
                    ))}
                </div>
                {!props.cardMenu && <div className="bar"></div>}
            </div>
            <div className="pad-top">{props.children}</div>
        </div>
    );
};
