import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import { Footer } from '../shared/Footer';
import { MailchimpField } from '../shared/MailchimpField';

export const BlogPage = () => {
    return (
        <div id="landing-page">
            <Helmet>
                <title>Blog - YourTeeth</title>
            </Helmet>
            <Navbar links blog />
            <section className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="columns is-centered is-center-horizontal">
                            <div className="column is-6 is-full-mobile">
                                <h1 className="is-bold is-white is-text-centered">
                                    The YourTeeth Blog
                                </h1>
                                <h5 className="pad-top-half is-white is-text-centered">
                                    The place to stay up to date with what we at
                                    YourTeeth are doing to provide a safe and
                                    tailor-made dental service
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body grey">
                <div className="container">
                    <div className="columns">
                        <div className="column is-2"></div>
                        <div classname="column is-8">
                            <div className="card">
                                <div className="card-content">
                                    <p className="title pad-bottom">
                                        Meet the Dentists behind YourTeeth
                                    </p>
                                    <p className="subtitle is-black">
                                        With the help of our duly licensed
                                        dentists and orthodontists, we’re here
                                        to explain the YourTeeth process and
                                        some of the benefits of teledentistry.
                                        So you can gain access to convenient
                                        dental care, and smile, easy.
                                    </p>
                                    <div className="blog-text pad-bottom-double">
                                        <h4 className="is-bold pad-bottom">
                                            How a doctor monitors your treatment
                                        </h4>
                                        <p>
                                            YourTeeth’s services are offered
                                            through our network of
                                            hand-selected, state-licensed dental
                                            hygienists, who meet our criteria of
                                            experience, patient care standards,
                                            and continuing education
                                            involvement. Our dental hygienists
                                            are supervised by our network of
                                            highly-vetted, state licensed
                                            dentists. Our partner dentists have
                                            many years of clinical experience
                                            and quality patient care. Each
                                            YourTeeth customer is assigned to an
                                            overseeing dentist who is licensed
                                            where they live.
                                        </p>
                                        <br></br>
                                        <p>
                                            Your dentist will assess your
                                            individual case, including your
                                            medical and dental history, and
                                            determine if YourTeeth’s
                                            personalized approach is the right
                                            fit for you. After receiving
                                            treatment, your dentist will track
                                            your treatment notes and you will be
                                            able to communicate directly with
                                            him or her by phone or online
                                            account. Approval of your treatment
                                            includes a review of your medical
                                            and health history, oral pictures,
                                            and any dental-related concerns or
                                            pain points. Your supervising
                                            dentist may make treatment
                                            recommendations based on their
                                            findings and your needs.
                                        </p>
                                        <h4 className="is-bold pad-top pad-bottom">
                                            Who are the dentists?
                                        </h4>
                                        <p>
                                            A Clinical Advisory Board guides
                                            YourTeeth’s network of dentists and
                                            orthodontists. In fact, the dentists
                                            and orthodontists in our network
                                            don’t work for YourTeeth at all.
                                            They can prescribe treatment to
                                            their customers, but they’re under
                                            no obligation to recommend
                                            YourTeeth’s services specifically.
                                        </p>
                                        <br></br>
                                        <p>
                                            The Clinical Advisory Board is
                                            responsible for the establishment of
                                            recommended clinical guidelines and
                                            best practices for at-home dental
                                            care. The board makes
                                            recommendations to YourTeeth on its
                                            practices and services.
                                        </p>
                                        <h4 className="is-bold pad-top pad-bottom">
                                            Why Teledentistry?
                                        </h4>
                                        <p>
                                            Teledentistry is a more convenient
                                            and accessible option for most
                                            people. It also offers doctors the
                                            ability to provide more affordable
                                            options for dental treatment. That’s
                                            why YourTeeth can provide more
                                            affordable options at such
                                            convenience as compared to visiting
                                            a dentist’s office. YourTeeth is a
                                            leader in promoting and developing
                                            advanced technology. We’ve also made
                                            it available to local orthodontists
                                            and general dentists while improving
                                            everyone’s access to care.
                                        </p>
                                        <br></br>
                                        <p>
                                            YourTeeth truly believes everyone
                                            deserves convenient and affordable
                                            dental treatment that they’ll love.
                                            We are proud to be pioneers in the
                                            teledentistry space. We, along with
                                            dentists and orthodontists, are
                                            helping increase access to dental
                                            care.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-2"></div>
                    </div>
                </div>
            </section>

            {/* <section className="body white">
                <div className="container pad-top-double pad-bottom-double">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-black is-text-centered">
                                Be the first to hear about the launch
                            </h1>
                            <h5 className="pad-top-half is-text-centered">
                                Join our wait list and receive priority access
                                to book your
                            </h5>
                            <h5 className="pad-top-half is-text-centered">
                                appointment through YourTeeth!
                            </h5>
                            <MailchimpField />
                        </div>
                    </div>
                </div>
            </section> */}
            <div>
                <Footer />
            </div>
        </div>
    );
};
