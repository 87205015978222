import React, { useState } from 'react';
import { useEffect } from 'react';
import { Navbar } from '../../shared/Navbar';
import { Link, Redirect } from 'react-router-dom';
import { validateEmail, validatePhone, validatePassword } from '../../../utils';
import { axiosInstance } from '../../../axios';

export const ProviderBasicInfo = (props) => {
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [emailAvail, setEmailAvail] = useState(false);
    const [username, setUsername] = useState('');
    const [userValid, setUserValid] = useState(false);
    const [phone, setPhone] = useState('');
    const [pass, setPass] = useState('');
    const [serviceCity] = useState('Philadelphia, PA');
    const [viewPass, toggleViewPass] = useState(false);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [state, setState] = useState('AL');

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [complete, setComplete] = useState(false);

    const checkUsername = async () => {
        setIsLoading(true);
        const res = await axiosInstance.get(
            `/onboard/username?username=${username}`
        );
        setUserValid(res.data.is_avail);
        setIsLoading(false);
    };

    const checkEmail = async () => {
        if (validateEmail(email)) {
            setIsLoading(true);
            const res = await axiosInstance.get(
                `/onboard/email?email=${email}`
            );
            setEmailValid(true);
            setEmailAvail(res.data.is_avail);
            setIsLoading(false);
            return;
        }

        setEmailValid(false);
    };

    useEffect(() => {
        checkUsername();
    }, [username]);

    useEffect(() => {
        checkEmail();
    }, [email]);

    const isFormSubmitable = () => {
        return (
            first &&
            last &&
            email &&
            emailValid &&
            emailAvail &&
            phone &&
            validatePhone(phone) &&
            username &&
            userValid &&
            pass &&
            validatePassword(pass) &&
            address1 &&
            city &&
            zip &&
            state
        );
    };

    const payload = () => {
        return {
            first_name: first,
            last_name: last,
            email: email,
            phone: phone,
            username: username,
            address: {
                address1: address1,
                address2,
                city,
                state,
                zip_code: zip,
            },
            password: pass,
        };
    };

    const submitForm = async () => {
        if (!isFormSubmitable()) {
            return;
        }
        setIsLoading(true);
        try {
            await axiosInstance.post('/onboard/provider/basic', payload());
            setIsLoading(false);
            await props.onBasicComplete();
            setComplete(true);
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };

    return (
        <div>
            {complete && <Redirect to="/register/provider/qualifications" />}
            <div className="hu-hero">
                <p className="accent-underline">Step 1 of 3</p>
                <h3 className="is-bold pad-top-half">Basic Information</h3>
            </div>
            <div className="pad-top-double container">
                <p className="label">Name</p>
                <div className="columns">
                    <div className="column">
                        <input
                            className={`input`}
                            placeholder="First Name"
                            type="text"
                            value={first}
                            onChange={(e) => setFirst(e.target.value)}
                        />
                    </div>
                    <div className="column">
                        <input
                            className={`input`}
                            placeholder="Last Name"
                            type="text"
                            value={last}
                            onChange={(e) => setLast(e.target.value)}
                        />
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        <p className="label">Email</p>
                        <input
                            className={`input ${
                                (!emailAvail || !emailValid) &&
                                email &&
                                'is-danger'
                            }`}
                            placeholder="email@domain.com"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {email &&
                            (!emailValid ? (
                                <p className="help is-danger">
                                    Please enter a valid email
                                </p>
                            ) : (
                                !emailAvail && (
                                    <p className="help is-danger">
                                        This email is already in use
                                    </p>
                                )
                            ))}
                    </div>
                    <div className="column">
                        <p className="label">Phone Number</p>
                        <input
                            className={`input ${
                                !validatePhone(phone) && phone && 'is-danger'
                            }`}
                            placeholder="000-000-0000"
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        {phone && !validatePhone(phone) && (
                            <p className="help is-danger">
                                Please enter a valid phone number.
                            </p>
                        )}
                    </div>
                </div>

                <div className="columns">
                    <div className="column">
                        <p className="label">Username</p>
                        <input
                            className={`input ${
                                !userValid && username && 'is-danger'
                            }`}
                            placeholder="Username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        {username && !userValid && (
                            <p className="help is-danger">
                                This username is already in use.
                            </p>
                        )}
                    </div>
                    <div className="column">
                        <div className="field has-right-icons">
                            <label className="label">Password</label>
                            <p className="control has-icons-right">
                                <input
                                    className={`input`}
                                    type={viewPass ? 'text' : 'password'}
                                    placeholder=""
                                    value={pass}
                                    onChange={(e) => {
                                        setPass(e.target.value);
                                    }}
                                />

                                <p
                                    className={`help ${
                                        pass &&
                                        !validatePassword(pass) &&
                                        'is-danger'
                                    }`}>
                                    Minimum of 8 characters
                                    <br />
                                    Must contain a lowercase and uppercase
                                    letter,
                                    <br />
                                    number, and at least one symbol.
                                </p>

                                <span
                                    className="icon is-small is-right"
                                    onClick={() => toggleViewPass(!viewPass)}>
                                    <i className="material-icons">
                                        {viewPass
                                            ? 'visibility'
                                            : 'visibility_off'}
                                    </i>
                                </span>
                                {/* {validated && !password && (
                                    <p className="help is-danger">
                                        Please enter a password.
                                    </p>
                                )} */}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div class="field">
                            <label class="label">Location</label>
                            <div className="control">
                                <fieldset disabled>
                                    <input
                                        className="input"
                                        type="text"
                                        value={serviceCity}
                                    />
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field pad-top">
                    <label className="label">Address Line 1</label>
                    <div className="control">
                        <input
                            className={`input`}
                            type="text"
                            placeholder="Street Address or PO Box"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                        />
                    </div>
                </div>
                <div className="field pad-top">
                    <label className="label">Address Line 2</label>
                    <div className="control">
                        <input
                            className={`input`}
                            type="text"
                            placeholder="Apartment, suite, unit, building, floor, etc."
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className="field">
                            <label className="label">City</label>
                            <div className="control">
                                <input
                                    className="input"
                                    placeholder="City / Town"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label">Zip Code</label>
                            <div className="control">
                                <input
                                    className="input"
                                    placeholder="00000"
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label">State</label>
                            <div className="control select is-expanded">
                                <select
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">
                                        District Of Columbia
                                    </option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-buttons pad-bottom-double">
                    <div></div>
                    <div
                        className={`button is-primary ${
                            isLoading ? 'is-loading' : ''
                        }`}
                        onClick={submitForm}
                        disabled={isLoading || !isFormSubmitable()}>
                        Continue
                    </div>
                </div>
            </div>
        </div>
    );
};
