import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Navbar } from '../../shared/Navbar';

import { axiosInstance } from '../../../axios';
// import { ProviderBasicInfo } from './BasicInfo';
// import { ProviderQualifications } from './Qualifications';
import { Footer } from '../../shared/Footer';
// import { ProviderNetwork } from './Network';
import { PrivateRoute, login } from '../../../auth';
import { Patients } from './Patients';
import { useState } from 'react';
import { Services } from './Services';
import { Details } from './Details';

import '../../../styles/appointments.scss';
import { Consent } from './Consent';

export const ApptBookingFlow = (props) => {
    // const fetchAccount = async () => {
    //     try {
    //         const res = await axiosInstance.get('/auth/me');
    //         const account = res.data;
    //         login(account);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const [patients, setPatients] = useState([]);

    return (
        <div>
            <div className="register-page">
                <Helmet>
                    <title>Book an Appointment - YourTeeth</title>
                </Helmet>
                <Navbar />
                <Switch>
                    <Route path="/appointment/book" exact>
                        <Redirect to="/appointment/book/patients" />
                    </Route>
                    <Route path="/appointment/book/patients" exact>
                        {/* <ProviderBasicInfo onBasicComplete={fetchAccount} /> */}
                        <Patients onPatientComplete={setPatients} />
                    </Route>
                    <Route path="/appointment/book/service" exact>
                        {/* <ProviderBasicInfo onBasicComplete={fetchAccount} /> */}
                        <Services
                            patients={patients}
                            onServiceSelect={setPatients}
                        />
                    </Route>
                    <Route path="/appointment/book/details" exact>
                        {/* <ProviderBasicInfo onBasicComplete={fetchAccount} /> */}
                        <Details patients={patients} onComplete={setPatients} />
                    </Route>
                    <Route path="/appointment/book/health" exact>
                        <Consent appointment={patients} />
                    </Route>
                    {/* <PrivateRoute
                        path="/register/provider/qualifications"
                        exact>
                        <ProviderQualifications />
                    </PrivateRoute>
                    <Route path="/register/provider/network" exact>
                        <ProviderNetwork />
                    </Route> */}
                    <Route path="*">
                        <Redirect to="/404" />
                    </Route>
                </Switch>
            </div>
            <Footer />
        </div>
    );
};
