import React, { useState, useContext, useEffect } from 'react';
import ReactMapGL from 'react-map-gl';
import axios from 'axios';
import ScatterplotOverlay from './CircleOverlay';
import AuthContext from '../../context/auth';

export const Map = (props) => {
    const { auth } = useContext(AuthContext);
    const [viewport, setViewport] = useState({
        width: 400,
        height: 400,
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 8,
        mapboxApiAccessToken:
            'pk.eyJ1Ijoic2FsaWJkIiwiYSI6ImNrYTIyZHhyeTAxa3kzbW1zYzF2MzF5NnYifQ.kM52dWb8_ZN0c35w5tRegw',
    });

    const [zip, setZip] = useState([]);
    const [radius, setRadius] = useState(0);
    const [placeName, setPlaceName] = useState({
        city: 'San Francisco',
        state: 'California',
        latitude: 37.7577,
        longitude: -122.4376,
        radius: 0,
    });

    useEffect(() => {
        if (auth.account && auth.account.personal_info) {
            setZip(`${auth.account?.personal_info.zip_code}`);
        }
    }, [auth]);

    useEffect(() => {
        if (zip.length === 5) {
            requestZipCoords();
        }
    }, [zip]);

    const apiURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${zip}.json?access_token=${viewport.mapboxApiAccessToken}&autocomplete=true&country=us&types=postcode`;

    const handleZipChange = (v) => {
        setZip(v);
    };

    const requestZipCoords = async () => {
        try {
            const res = await axios.get(apiURL);
            const coords = res.data.features[0].center;
            const city = res.data.features[0].context[0].text;
            const state = res.data.features[0].context[1].text;
            setViewport({
                ...viewport,
                latitude: coords[1],
                longitude: coords[0],
            });

            setPlaceName({
                ...placeName,
                city,
                state,
                latitude: coords[1],
                longitude: coords[0],
            });
            props.onPlaceChange({
                ...placeName,
                city,
                state,
                latitude: coords[1],
                longitude: coords[0],
            });
        } catch (e) {}
    };

    const handleRadiusChange = (r) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (r === '' || re.test(r) || parseFloat(r) > 0) {
            r = parseFloat(r);
            setRadius(r);
            setPlaceName({ ...placeName, radius: r });
            props.onPlaceChange({ ...placeName, radius: r });
        }
    };

    return (
        <div className="columns pad-top">
            <div className="column is-7">
                <ReactMapGL
                    width={viewport.width}
                    height={viewport.height}
                    latitude={viewport.latitude}
                    longitude={viewport.longitude}
                    zoom={viewport.zoom}
                    mapboxApiAccessToken={viewport.mapboxApiAccessToken}>
                    {
                        <ScatterplotOverlay
                            locations={[
                                [viewport.longitude, viewport.latitude],
                            ]}
                            dotRadius={10 * radius}
                            globalOpacity={0.2}
                            compositeOperation="lighter"
                            dotFill="blue"
                            renderWhileDragging={true}
                        />
                    }
                </ReactMapGL>
            </div>
            {!props.hideForm && (
                <div className="column is-5">
                    <label className="label">Zip Code</label>
                    <input
                        className="input"
                        value={zip}
                        placeholder="94016"
                        onChange={(e) => handleZipChange(e.target.value)}
                    />
                    <label className="label pad-top-double">Max Radius</label>
                    <input
                        className="input"
                        placeholder="0"
                        onChange={(e) => handleRadiusChange(e.target.value)}
                    />
                </div>
            )}
        </div>
    );
};
