import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Navbar } from '../../shared/Navbar';
import { Footer } from '../../shared/Footer';
import { useState } from 'react';
import { axiosInstance } from '../../../axios';
import { useEffect } from 'react';

export const CompleteAppt = (props) => {
    const { apptid } = useParams();
    const history = useHistory();
    const [customEnabled, setCustomEnabled] = useState(false);
    const [customAmount, setCustomAmount] = useState(0);
    const [invalidAmt, setInvalidAmt] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [selected, setSelected] = useState(0);

    const handleAmtChange = (amt) => {
        setCustomAmount(amt);
        const tip = parseFloat(amt);
        if (isNaN(tip)) {
            setInvalidAmt(true);
        } else {
            setInvalidAmt(false);
        }
    };

    const fetchTippingData = async () => {
        try {
            const res = await axiosInstance.get(
                `/appointments/complete?apptid=${apptid}`
            );
            setSubtotal(res.data.tip);
        } catch (e) {
            console.log(e);
            history.push('/appointments');
        }
    };

    const submitForm = async () => {
        let tip = 0;

        if (selected === 0) tip = 10;
        if (selected === 1) tip = 15;
        if (selected === 2) tip = 20;
        if (selected === 3) tip = 25;
        if (selected === 4) tip = customAmount;

        const res = await axiosInstance.post(
            `/appointments/complete?apptid=${apptid}&tip=${Math.trunc(
                subtotal * tip
            )}`
        );
    };

    useEffect(() => {
        fetchTippingData();
    }, []);

    return (
        <div>
            <Navbar filled provider dark />

            <div className="container pad-top-double">
                <h4 className="is-bold pad-bottom">After Your Appointment</h4>
                <div className="card rounded padded border margin-bottom">
                    <div>
                        <h5 className="is-bold">
                            <span className="card-number margin-right-half">
                                1
                            </span>
                            Leave a Tip
                        </h5>
                    </div>
                    <div className="tip-buttons pad-top pad-left-double">
                        <div
                            className={`tip-button margin-right ${
                                selected === 0 && 'selected'
                            }`}
                            onClick={() => setSelected(0)}>
                            <h5 className="is-medium">10%</h5>
                            <p className="is-small">
                                ${(subtotal * 0.1).toFixed(2)}
                            </p>
                        </div>
                        <div
                            className={`tip-button margin-right ${
                                selected === 1 && 'selected'
                            }`}
                            onClick={() => setSelected(1)}>
                            <h5 className="is-medium">15%</h5>
                            <p className="is-small">
                                ${(subtotal * 0.15).toFixed(2)}
                            </p>
                        </div>
                        <div
                            className={`tip-button margin-right ${
                                selected === 2 && 'selected'
                            }`}
                            onClick={() => setSelected(2)}>
                            <h5 className="is-medium">20%</h5>
                            <p className="is-small">
                                ${(subtotal * 0.2).toFixed(2)}
                            </p>
                        </div>
                        <div
                            className={`tip-button margin-right ${
                                selected === 3 && 'selected'
                            }`}
                            onClick={() => setSelected(3)}>
                            <h5 className="is-medium">25%</h5>
                            <p className="is-small">
                                ${(subtotal * 0.25).toFixed(2)}
                            </p>
                        </div>
                        {customEnabled ? (
                            <div
                                className={`tip-button margin-right ${
                                    selected === 4 && 'selected'
                                }`}
                                onClick={() => setSelected(4)}>
                                <h5 className="is-medium">{customAmount}%</h5>
                                <p className="is-small">
                                    $
                                    {((subtotal * customAmount) / 100).toFixed(
                                        2
                                    )}
                                </p>
                            </div>
                        ) : (
                            <div
                                className="tip-button margin-right"
                                onClick={() => {
                                    setCustomEnabled(true);
                                    setSelected(4);
                                }}>
                                <h5 className="is-medium">Custom</h5>
                            </div>
                        )}
                    </div>
                    {customEnabled && selected === 4 && (
                        <div className="pad-left-double pad-top">
                            <p className="is-small">Tip Percentage</p>
                            <div
                                className={`control has-icons-right ${
                                    invalidAmt && 'is-danger'
                                }`}>
                                <input
                                    className="input"
                                    type="number"
                                    value={customAmount}
                                    onChange={(e) =>
                                        handleAmtChange(e.target.value)
                                    }
                                />
                                <span class="icon is-right">%</span>
                            </div>
                            {invalidAmt && (
                                <p className="is-danger help">
                                    Enter a valid number.
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <div className="center-flex pad-top pad-bottom">
                    <div className="button is-primary" onClick={submitForm}>
                        Complete Appointment
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
