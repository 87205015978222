import React, { useState } from 'react';

export const QADropdown = (props) => {
    const [toggled, setToggled] = useState(false);
    return (
        <div>
            <div className="faq-item">
                <div
                    className="faq-header"
                    onClick={() => setToggled(!toggled)}
                    style={!props.first ? { borderTop: '0px' } : {}}>
                    <h6 className="is-bold">{props.title}</h6>
                    <span
                        className={`margin-left chevron ${toggled ? 'up' : ''}`}
                    />
                </div>
                <div
                    className={toggled ? '' : 'is-hidden'}
                    style={
                        props.last ? {} : { borderBottom: '2px solid #dbdbdb' }
                    }>
                    <div className="pad-left pad-right pad-top pad-bottom">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};
