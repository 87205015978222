import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import { Footer } from '../shared/Footer';
import { MailchimpField } from '../shared/MailchimpField';

export const WhyHu = () => {
    return (
        <div id="landing-page">
            <Helmet>
                <title>YourTeeth</title>
            </Helmet>
            <Navbar links whyhu={true} />
            <section className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="columns center-flex">
                            <div className="column is-6 is-full-mobile">
                                <h1 className="is-bold is-white is-text-centered">
                                    Why YourTeeth?
                                </h1>
                                <h5 className="pad-top-half is-white is-text-centered">
                                    Quality dental care. Anytime, anywhere.
                                </h5>
                                <div className="buttons center-flex">
                                    <Link to="/appointment/book">
                                        <button className="button is-medium is-white margin-top-double">
                                            <h5 className="is-bold is-black">
                                                Book Now
                                            </h5>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body main">
                <div className="container">
                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/balloonpatient.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Patient protection
                                </h4>
                                <p className="pad-top-half left-align">
                                    We promise you a 100% satisfaction guarantee
                                    or your money back, as well as free second
                                    opinions with YT-verified dentists.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-mobile">
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Best available care
                                </h4>
                                <p className="pad-top-half left-align">
                                    YourTeeth patients gain access to highly
                                    vetted, top-rated dental professionals who
                                    are now just a click away.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/doctors.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-tablet">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/doctors.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Best available care
                                </h4>
                                <p className="pad-top-half left-align">
                                    YourTeeth patients gain access to highly
                                    vetted, top-rated dental professionals who
                                    are now just a click away.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/safety.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Safety
                                </h4>
                                <p className="pad-top-half left-align">
                                    In the age of COVID-19, you don’t have to
                                    put yourself at risk by stepping into
                                    another medical clinic. Stay at home and get
                                    treated by our team, who are fully equipped
                                    with all the necessary PPE required by the
                                    ADA and state guidelines.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-mobile">
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Eliminate travel time
                                </h4>
                                <p className="pad-top-half left-align">
                                    Never calculate travel time from work again!
                                    YourTeeth treats you in-home on YOUR
                                    schedule, so you never have to worry about
                                    missing your next meeting or call.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/timesaved.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-tablet">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/timesaved.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Eliminate travel time
                                </h4>
                                <p className="pad-top-half left-align">
                                    Never calculate travel time from work again!
                                    YourTeeth treats you in-home on YOUR
                                    schedule, so you never have to worry about
                                    missing your next meeting or call.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/reading.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Back to the future
                                </h4>
                                <p className="pad-top-half left-align">
                                    No more having to read magazines from 2010.
                                    You’re in your home, so continue listening
                                    to whatever music you want to play or
                                    Netflix show you want to watch.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-mobile">
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Discounted rates
                                </h4>
                                <p className="pad-top-half left-align">
                                    Our YourTeeth network provides you with the
                                    best fees available, discounted specifically
                                    for YourTeeth patients. We’ve pre-vet the
                                    top dental clinics in our cities if further
                                    treatment is requested or recommended.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/piggybank.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double is-hidden-tablet">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/piggybank.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    Discounted rates
                                </h4>
                                <p className="pad-top-half left-align">
                                    Our YourTeeth network provides you with the
                                    best fees available, discounted specifically
                                    for YourTeeth patients. We’ve pre-vet the
                                    top dental clinics in our cities if further
                                    treatment is requested or recommended.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/houses.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                        </div>
                        <div className="column is-center-vertical">
                            <div>
                                <h4 className="is-bold is-black pad-top">
                                    A familiar enviroment
                                </h4>
                                <p className="pad-top-half left-align">
                                    Stay where you feel most comfortable without
                                    the burden of entering a high-pressure
                                    dental office.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="body grey">
                <div className="container pad-top-double pad-bottom-double">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-black is-text-centered">
                                Be the first to hear about the launch
                            </h1>
                            <h5 className="pad-top-half is-text-centered">
                                Join our wait list and receive priority access
                                to book your
                            </h5>
                            <h5 className="pad-top-half is-text-centered">
                                appointment through YourTeeth!
                            </h5>
                            <MailchimpField />
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
};
