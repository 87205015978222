import React, { useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Loader } from '../../profile/Loader';
import { axiosInstance } from '../../../axios';
import { useEffect } from 'react';
import {
    parseMinFromMid,
    informedConsent,
    covidLiability,
} from '../../../utils';
import moment from 'moment';
import { FileUpload } from '../../profile/Uploaders';
import { Check } from 'react-feather';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { authEmail } from '../../../auth';
import { ArchImage } from '../../shared/Image';

const InputEnterField = (props) => {
    const [val, setValue] = useState('');

    return (
        <input
            className="input"
            value={val}
            onChange={(e) => setValue(e.target.value)}
            {...props}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && val) {
                    props.onEnter(val);
                    setValue('');
                }
            }}
        />
    );
};
export const PostBooking = (props) => {
    const { apptid } = useParams();
    const [appt, setAppt] = useState(undefined);
    const [fetching, setFetching] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [assessment, setAssessment] = useState({
        smile: '',
        edge: '',
        wide: '',
        tilt: '',
    });
    const [covid, updateCovid] = useState({
        symptoms: [],
        exposure: false,
        test: false,
    });

    const [medical, updateMedical] = useState({
        age: 1,
        allergies: [],
        medication: [],
        surgeries: [],
    });

    const [allergies, setAllergies] = useState(false);
    const [med, setMed] = useState(false);
    const [surg, setSurg] = useState(false);

    const setCovid = (covid) => {
        updateCovid(covid);

        try {
            axiosInstance.post(`/appointments/covid?apptid=${apptid}`, covid);
        } catch (e) {
            console.log(e);
        }
    };

    const setMedical = (med) => {
        updateMedical(med);

        try {
            axiosInstance.post(`/appointments/medical?apptid=${apptid}`, {
                age: med.age,
                allergies: allergies ? med.allergies : [],
                medication: med ? med.medication : [],
                surgeries: surg ? med.surgeries : [],
            });
        } catch (e) {
            console.log(e);
        }
    };

    const setAge = (age) => {
        if (!canEdit) return;
        const copy = { ...medical };
        copy.age = parseInt(age);
        setMedical(copy);
    };

    const addAllergy = (alg) => {
        if (!canEdit) return;
        const copy = { ...medical };
        copy.allergies.push(alg);
        setMedical(copy);
    };

    const addMedication = (name) => {
        if (!canEdit) return;
        const copy = { ...medical };
        copy.medication.push(name);
        setMedical(copy);
    };

    const addSurgery = (name) => {
        if (!canEdit) return;
        const copy = { ...medical };
        copy.surgeries.push(name);
        setMedical(copy);
    };

    const setExposure = (exp) => {
        if (!canEdit) return;
        const copy = { ...covid };
        copy.exposure = exp;
        setCovid(copy);
    };

    const toggleTest = () => {
        if (!canEdit) return;
        const copy = { ...covid };
        copy.test = !copy.test;
        setCovid(copy);
    };

    const toggleFever = () => {
        if (!canEdit) return;
        const copy = { ...covid };
        if (copy.symptoms.includes('Fever')) {
            copy.symptoms = copy.symptoms.filter((item) => item !== 'Fever');
        } else {
            copy.symptoms.push('Fever');
        }

        setCovid(copy);
    };

    const toggleShortness = () => {
        if (!canEdit) return;
        const copy = { ...covid };
        if (copy.symptoms.includes('Shortness of Breath')) {
            copy.symptoms = copy.symptoms.filter(
                (item) => item !== 'Shortness of Breath'
            );
        } else {
            copy.symptoms.push('Shortness of Breath');
        }

        setCovid(copy);
    };

    const toggleCough = () => {
        if (!canEdit) return;
        const copy = { ...covid };
        if (copy.symptoms.includes('Cough')) {
            copy.symptoms = copy.symptoms.filter((item) => item !== 'Cough');
        } else {
            copy.symptoms.push('Cough');
        }

        setCovid(copy);
    };

    const history = useHistory();

    const [openAssess, setOpenAssess] = useState(false);
    const [openConsent, setOpenConsent] = useState(false);

    const fetchApptDetail = async (apptid) => {
        try {
            const res = await axiosInstance.get(
                `/appointments/postbooking?apptid=${apptid}`
            );
            setAppt(res.data);

            if (res.data.assessment) {
                setAssessment(res.data.assessment);
            }

            if (res.data.covid_saftey) {
                updateCovid(res.data.covid_saftey);
            }

            if (res.data.medical_checklist) {
                updateMedical(res.data.medical_checklist);
                setAllergies(res.data.medical_checklist.allergies.length > 0);
                setMed(res.data.medical_checklist.medication.length > 0);
                setSurg(res.data.medical_checklist.surgeries.length > 0);
            }

            setCanEdit(res.data.customer_email === authEmail());

            setOpenConsent(res.data.signed_consent);
            setFetching(false);
        } catch (e) {
            history.push('/appointments');
        }
    };

    const acceptConsent = async () => {
        if (!canEdit) return;
        try {
            await axiosInstance.post(`/appointments/consent?apptid=${apptid}`);
            fetchApptDetail(apptid);
        } catch (e) {
            console.log(e);
        }
    };

    const setSmile = async (url) => {
        const copy = { ...assessment };
        copy.smile = url;
        setAssessment(copy);
        await updateAssessment(copy);
        setIsLoading(false);
    };

    const setEdge = async (url) => {
        const copy = { ...assessment };
        copy.edge = url;
        setAssessment(copy);
        await updateAssessment(copy);
        setIsLoading(false);
    };

    const setWide = async (url) => {
        const copy = { ...assessment };
        copy.wide = url;
        setAssessment(copy);
        await updateAssessment(copy);
        setIsLoading(false);
    };

    const setTilt = async (url) => {
        const copy = { ...assessment };
        copy.tilt = url;
        setAssessment(copy);
        await updateAssessment(copy);
        setIsLoading(false);
    };

    const updateAssessment = async (a) => {
        axiosInstance.post(`/appointments/assessment?apptid=${apptid}`, a);
    };

    useEffect(() => {
        fetchApptDetail(apptid);
    }, []);

    return (
        <div className="column">
            <Loader isLoading={fetching} style={{ height: '70vh' }}>
                {appt && (
                    <div>
                        <h4 className="is-bold pad-bottom">
                            Booking Confirmed
                        </h4>
                        <div className="card rounded padded border">
                            <div className="pad-top pad-left pad-right-double">
                                {appt.patients.map((patient, idx) => (
                                    <div className="pad-bottom">
                                        <p className="is-info is-small pad-bottom-half">
                                            {moment(appt.date).format('MMMM D')}{' '}
                                            • {parseMinFromMid(appt.time)} -{' '}
                                            {parseMinFromMid(
                                                appt.time + appt.duration
                                            )}
                                        </p>
                                        <h6 className="is-bold pad-bottom-half">
                                            {patient.service
                                                .charAt(0)
                                                .toUpperCase()}
                                            {patient.service.slice(1)} + Digital
                                            Smile Scan
                                        </h6>
                                        <Link
                                            to={`/${appt.provider_username}`}
                                            target="_blank">
                                            <div className="provider-card-top">
                                                <div
                                                    className="provider-img small margin-top-small"
                                                    style={{
                                                        backgroundImage: `url(${appt.provider_photo})`,
                                                        alignItems: 'center',
                                                    }}></div>
                                                <div className="provider-card-name pad-left">
                                                    <p className="is-medium pad-top-half">
                                                        {appt.provider_name}
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                        <p className="pad-top">
                                            {appt.address1}
                                        </p>
                                        {appt.address2 && (
                                            <p className="pad">
                                                {appt.address2}
                                            </p>
                                        )}
                                        {appt.special && (
                                            <p className="pad-top">
                                                <span className="is-bold">
                                                    Special Instructions:
                                                </span>{' '}
                                                {appt.special}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <h4 className="is-bold pad-bottom pad-top-double">
                            Before Your Appointment
                        </h4>
                        <div className="card rounded border pad-right-double padded margin-bottom">
                            {!(
                                assessment.edge &&
                                assessment.smile &&
                                assessment.tilt &&
                                assessment.wide
                            ) || openAssess ? (
                                <div>
                                    <h5 className="is-bold">
                                        <span className="card-number margin-right-half">
                                            1
                                        </span>
                                        Complete Your Smile Assessment
                                    </h5>
                                    <div className="pad-left-double">
                                        <p className="is-medium">
                                            Take some smile selfies for one of
                                            our state licensed dentists to
                                            review
                                        </p>
                                        <div className="info-box margin-right margin-top-half">
                                            Tips for best results:
                                            <ul>
                                                <li>
                                                    • Use your phone’s flash and
                                                    make pictures as clear as
                                                    possible
                                                </li>
                                                {/* <li>
                                            • Download the Hu app to quickly
                                            upload your photos
                                        </li> */}
                                            </ul>
                                        </div>
                                        <div className="pad-top">
                                            <ul className="steps is-vertical is-hollow is-thin">
                                                <li className="steps-segment">
                                                    <span
                                                        className="steps-marker"
                                                        style={{
                                                            display: 'none',
                                                        }}></span>
                                                    <div className="steps-content"></div>
                                                </li>
                                                <li className="steps-segment pad-bottom-double">
                                                    <span className="steps-marker"></span>
                                                    <div className="steps-content">
                                                        <h6 className="is-medium pad-bottom-half">
                                                            Smile
                                                        </h6>
                                                        <ArchImage
                                                            className="appointment-photo"
                                                            src={
                                                                assessment.smile
                                                            }
                                                        />
                                                        {canEdit && (
                                                            <FileUpload
                                                                maxSize={5}
                                                                onUploadStart={() =>
                                                                    setIsLoading(
                                                                        true
                                                                    )
                                                                }
                                                                onSuccess={
                                                                    setSmile
                                                                }>
                                                                <div
                                                                    className={`button is-info is-outlined margin-top-half ${
                                                                        isLoading &&
                                                                        'is-loading'
                                                                    }`}>
                                                                    {assessment.smile
                                                                        ? 'Change Photo'
                                                                        : 'Upload'}
                                                                </div>
                                                            </FileUpload>
                                                        )}
                                                    </div>
                                                </li>
                                                <li className="steps-segment pad-bottom-double">
                                                    <span className="steps-marker"></span>
                                                    <div className="steps-content">
                                                        <h6 className="is-medium">
                                                            Edge to edge
                                                        </h6>
                                                        <p className="pad-bottom-half">
                                                            move your front
                                                            teeth edge to edge
                                                        </p>
                                                        <ArchImage
                                                            className="appointment-photo"
                                                            src={
                                                                assessment.edge
                                                            }
                                                        />
                                                        {canEdit && (
                                                            <FileUpload
                                                                maxSize={5}
                                                                onUploadStart={() =>
                                                                    setIsLoading(
                                                                        true
                                                                    )
                                                                }
                                                                onSuccess={
                                                                    setEdge
                                                                }>
                                                                <div
                                                                    className={`button is-info is-outlined margin-top-half ${
                                                                        isLoading &&
                                                                        'is-loading'
                                                                    }`}>
                                                                    {assessment.edge
                                                                        ? 'Change Photo'
                                                                        : 'Upload'}
                                                                </div>
                                                            </FileUpload>
                                                        )}
                                                    </div>
                                                </li>
                                                <li className="steps-segment pad-bottom-double">
                                                    <span className="steps-marker"></span>
                                                    <div className="steps-content">
                                                        <h6 className="is-medium">
                                                            Open wide
                                                        </h6>
                                                        <p className="pad-bottom-half">
                                                            lift your tongue and
                                                            get a shot of your
                                                            bottom teeth
                                                        </p>

                                                        <ArchImage
                                                            className="appointment-photo"
                                                            src={
                                                                assessment.wide
                                                            }
                                                        />
                                                        {canEdit && (
                                                            <FileUpload
                                                                maxSize={5}
                                                                onUploadStart={() =>
                                                                    setIsLoading(
                                                                        true
                                                                    )
                                                                }
                                                                onSuccess={
                                                                    setWide
                                                                }>
                                                                <div
                                                                    className={`button is-info is-outlined margin-top-half ${
                                                                        isLoading &&
                                                                        'is-loading'
                                                                    }`}>
                                                                    {assessment.wide
                                                                        ? 'Change Photo'
                                                                        : 'Upload'}
                                                                </div>
                                                            </FileUpload>
                                                        )}
                                                    </div>
                                                </li>
                                                <li className="steps-segment pad-bottom">
                                                    <span className="steps-marker"></span>
                                                    <div className="steps-content">
                                                        <h6 className="is-medium">
                                                            Tilt your head back
                                                        </h6>
                                                        <p className="pad-bottom-half">
                                                            turn your phone
                                                            upside down, open
                                                            wide, and get a shot
                                                            of your top teeth
                                                        </p>
                                                        <ArchImage
                                                            className="appointment-photo"
                                                            src={
                                                                assessment.tilt
                                                            }
                                                        />
                                                        {canEdit && (
                                                            <FileUpload
                                                                maxSize={5}
                                                                onUploadStart={() =>
                                                                    setIsLoading(
                                                                        true
                                                                    )
                                                                }
                                                                onSuccess={
                                                                    setTilt
                                                                }>
                                                                <div
                                                                    className={`button is-info is-outlined margin-top-half ${
                                                                        isLoading &&
                                                                        'is-loading'
                                                                    }`}>
                                                                    {assessment.tilt
                                                                        ? 'Change Photo'
                                                                        : 'Upload'}
                                                                </div>
                                                            </FileUpload>
                                                        )}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="columns">
                                    <div className="column is-11">
                                        <h5 className="is-bold">
                                            <span className="card-number margin-right-half completed">
                                                <Check size={16} />
                                            </span>
                                            Complete Your Smile Assessment
                                        </h5>
                                    </div>
                                    <div className="column is-1 pad-top">
                                        <a onClick={() => setOpenAssess(true)}>
                                            {canEdit ? 'Edit' : 'View'}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="card rounded padded border margin-bottom">
                            <div>
                                <h5 className="is-bold">
                                    <span className="card-number margin-right-half">
                                        2
                                    </span>
                                    COVID-19 Safety Precautions
                                </h5>
                                <div className="pad-left-double">
                                    <p>
                                        To best protect our hygienists and
                                        communtiies, please complete all the
                                        following questions before your
                                        appointment.
                                    </p>
                                    <div className="pad-top">
                                        <p className="is-medium">
                                            In the past 7 days, have you
                                            experienced any of these symptoms?
                                        </p>
                                        <div className="columns">
                                            <div className="column is-3">
                                                <FormControlLabel
                                                    label={'Fever'}
                                                    control={
                                                        <Checkbox
                                                            className="arch-checkbox"
                                                            checked={covid.symptoms.includes(
                                                                'Fever'
                                                            )}
                                                            onChange={
                                                                toggleFever
                                                            }
                                                        />
                                                    }></FormControlLabel>
                                            </div>
                                            <div className="column is-3">
                                                <FormControlLabel
                                                    label={'Cough'}
                                                    control={
                                                        <Checkbox
                                                            className="arch-checkbox"
                                                            checked={covid.symptoms.includes(
                                                                'Cough'
                                                            )}
                                                            onChange={
                                                                toggleCough
                                                            }
                                                        />
                                                    }></FormControlLabel>
                                            </div>
                                            <div className="column">
                                                <FormControlLabel
                                                    label={
                                                        'Shortness of Breath'
                                                    }
                                                    control={
                                                        <Checkbox
                                                            className="arch-checkbox"
                                                            checked={covid.symptoms.includes(
                                                                'Shortness of Breath'
                                                            )}
                                                            onClick={
                                                                toggleShortness
                                                            }
                                                        />
                                                    }></FormControlLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pad-top">
                                        <p className="is-medium">
                                            In the past 14 days, have you tested
                                            for COVID-19 with a positive or
                                            unknown result?
                                        </p>
                                        <div className="columns">
                                            <div className="column is-3">
                                                <FormControlLabel
                                                    label={'Yes'}
                                                    control={
                                                        <Checkbox
                                                            className="arch-checkbox"
                                                            checked={
                                                                covid.exposure ===
                                                                true
                                                            }
                                                            onChange={() =>
                                                                setExposure(
                                                                    !covid.exposure
                                                                )
                                                            }
                                                        />
                                                    }></FormControlLabel>
                                            </div>
                                            <div className="column is-3">
                                                <FormControlLabel
                                                    label={'No'}
                                                    control={
                                                        <Checkbox
                                                            className="arch-checkbox"
                                                            checked={
                                                                covid.exposure ===
                                                                false
                                                            }
                                                            onChange={() =>
                                                                setExposure(
                                                                    !covid.exposure
                                                                )
                                                            }
                                                        />
                                                    }></FormControlLabel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pad-top">
                                        <p className="is-medium">
                                            In the past 14 days, have you had
                                            known exposure to a COVID-19
                                            patient?
                                        </p>
                                        <div className="columns">
                                            <div className="column is-3">
                                                <FormControlLabel
                                                    label={'Yes'}
                                                    control={
                                                        <Checkbox
                                                            className="arch-checkbox"
                                                            checked={covid.test}
                                                            onChange={
                                                                toggleTest
                                                            }
                                                        />
                                                    }></FormControlLabel>
                                            </div>
                                            <div className="column is-3">
                                                <FormControlLabel
                                                    label={'No'}
                                                    control={
                                                        <Checkbox
                                                            className="arch-checkbox"
                                                            checked={
                                                                !covid.test
                                                            }
                                                            onChange={
                                                                toggleTest
                                                            }
                                                        />
                                                    }></FormControlLabel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card rounded padded border margin-bottom">
                            <div>
                                <h5 className="is-bold">
                                    <span className="card-number margin-right-half">
                                        3
                                    </span>
                                    Medical Checklist
                                </h5>
                            </div>
                            <div className="pad-left-double margin-top">
                                <p className="is-medium">Age</p>

                                <div className="select">
                                    <select
                                        value={medical.age}
                                        onChange={(e) =>
                                            setAge(e.target.value)
                                        }>
                                        {[...Array(99).keys()].map((item) => (
                                            <option value={item + 1}>
                                                {item + 1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="pad-top">
                                    <p className="is-medium">
                                        Do you have any allergies?
                                    </p>
                                    <div
                                        className="columns"
                                        style={{ marginBottom: '0px' }}>
                                        <div className="column is-3">
                                            <FormControlLabel
                                                label={'Yes'}
                                                control={
                                                    <Checkbox
                                                        className="arch-checkbox"
                                                        checked={allergies}
                                                        onChange={() => {
                                                            if (!canEdit)
                                                                return;
                                                            setAllergies(
                                                                !allergies
                                                            );
                                                        }}
                                                    />
                                                }></FormControlLabel>
                                        </div>
                                        <div className="column is-3">
                                            <FormControlLabel
                                                label={'No'}
                                                control={
                                                    <Checkbox
                                                        className="arch-checkbox"
                                                        checked={!allergies}
                                                        onChange={() => {
                                                            if (!canEdit)
                                                                return;
                                                            setAllergies(
                                                                !allergies
                                                            );
                                                        }}
                                                    />
                                                }></FormControlLabel>
                                        </div>
                                    </div>
                                    {allergies && (
                                        <div>
                                            <div>
                                                {medical.allergies.map(
                                                    (item, idx) => (
                                                        <div className="tag margin-right-half margin-bottom">
                                                            {item}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {canEdit && (
                                                <InputEnterField
                                                    onEnter={addAllergy}
                                                    placeholder="List all Allergies"
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="pad-top">
                                    <p className="is-medium">
                                        Are you currently on any medications?
                                    </p>
                                    <div
                                        className="columns"
                                        style={{ marginBottom: '0px' }}>
                                        <div className="column is-3">
                                            <FormControlLabel
                                                label={'Yes'}
                                                control={
                                                    <Checkbox
                                                        className="arch-checkbox"
                                                        checked={med}
                                                        onChange={() => {
                                                            if (!canEdit)
                                                                return;
                                                            setMed(!med);
                                                        }}
                                                    />
                                                }></FormControlLabel>
                                        </div>
                                        <div className="column is-3">
                                            <FormControlLabel
                                                label={'No'}
                                                control={
                                                    <Checkbox
                                                        className="arch-checkbox"
                                                        checked={!med}
                                                        onChange={() => {
                                                            if (!canEdit)
                                                                return;
                                                            setMed(!med);
                                                        }}
                                                    />
                                                }></FormControlLabel>
                                        </div>
                                    </div>
                                    {med && (
                                        <div>
                                            <div>
                                                {medical.medication.map(
                                                    (item, idx) => (
                                                        <div className="tag margin-right-half margin-bottom">
                                                            {item}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {canEdit && (
                                                <InputEnterField
                                                    onEnter={addMedication}
                                                    placeholder="List all Medication"
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="pad-top">
                                    <p className="is-medium">
                                        Have your recently had any major
                                        surgeries or medical treatments?{' '}
                                    </p>
                                    <div
                                        className="columns"
                                        style={{ marginBottom: '0px' }}>
                                        <div className="column is-3">
                                            <FormControlLabel
                                                label={'Yes'}
                                                control={
                                                    <Checkbox
                                                        className="arch-checkbox"
                                                        checked={surg}
                                                        onChange={() => {
                                                            if (!canEdit)
                                                                return;
                                                            setSurg(!surg);
                                                        }}
                                                    />
                                                }></FormControlLabel>
                                        </div>
                                        <div className="column is-3">
                                            <FormControlLabel
                                                label={'No'}
                                                control={
                                                    <Checkbox
                                                        className="arch-checkbox"
                                                        checked={!surg}
                                                        onChange={() => {
                                                            if (!canEdit)
                                                                return;
                                                            setSurg(!surg);
                                                        }}
                                                    />
                                                }></FormControlLabel>
                                        </div>
                                    </div>
                                    {surg && (
                                        <div>
                                            <div>
                                                {medical.surgeries.map(
                                                    (item, idx) => (
                                                        <div className="tag margin-right-half margin-bottom">
                                                            {item}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {canEdit && (
                                                <InputEnterField
                                                    onEnter={addSurgery}
                                                    placeholder="List all Surgeries or Treatments"
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card rounded padded border margin-bottom">
                            <div>
                                <h5 className="is-bold">
                                    <span className="card-number margin-right-half completed">
                                        <Check size={16} />
                                    </span>
                                    COVID-19 Liability Waiver
                                </h5>
                                <textarea
                                    className="textarea margin-top-half"
                                    rows={10}
                                    value={covidLiability}
                                    readOnly
                                />
                                <div className="pad-top center-flex">
                                    <FormControlLabel
                                        label={`I agree to the terms of YourTeeth's COVID-19 Liability Waiver`}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={true}
                                            />
                                        }></FormControlLabel>
                                </div>
                            </div>
                        </div>
                        <div className="card rounded padded border margin-bottom">
                            <div>
                                <h5 className="is-bold">
                                    <span className="card-number margin-right-half completed">
                                        <Check size={16} />
                                    </span>
                                    Informed Consent
                                </h5>
                                <textarea
                                    className="textarea margin-top-half"
                                    rows={10}
                                    value={informedConsent}
                                    readOnly
                                />
                                <div className="pad-top center-flex">
                                    <FormControlLabel
                                        label={`I agree to the terms of YourTeeth's Informed Consent`}
                                        control={
                                            <Checkbox
                                                className="arch-checkbox"
                                                checked={true}
                                            />
                                        }></FormControlLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Loader>
        </div>
    );
};
