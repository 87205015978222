import React, { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import { Navbar } from '../../shared/Navbar';
import { axiosInstance } from '../../../axios';
import { Link, Redirect } from 'react-router-dom';
import { PhotoGalleryCard } from './Pictures';

export const ReviewPage = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('Alabama');
    const [numStars, setNumStars] = useState(0);
    const [title, setTitle] = useState('');
    const [descr, setDescr] = useState('');
    const [complete, setComplete] = useState('');
    const [attempted, setAttempted] = useState(false);
    const [gallery, setGallery] = useState([]);

    const [isFetching, setIsFetching] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [target, setTarget] = useState('');

    const username = props.match.params.username;
    const path = '/' + props.match.params.username;
    const username1 = props.username;

    const payload = () => {
        return {
            target_username: username,
            first_name: firstName,
            last_name: lastName,
            city: city,
            state: state,
            stars: numStars,
            title: title,
            description: descr,
            attachments: gallery,
        };
    };

    const isFormSubmitable = () => {
        return firstName && lastName && numStars && descr;
    };

    const submitForm = async () => {
        if (!isFormSubmitable()) {
            setAttempted(true);
            return;
        }
        setIsFetching(true);
        try {
            console.log(payload());
            await axiosInstance.post('/profile/reviews', payload());
            setIsFetching(false);
            setComplete(true);
        } catch (e) {
            setIsFetching(false);
            console.log(e);
        }
    };

    const deleteGalleryPhoto = (idx) => {
        const old = [...gallery];
        old.splice(idx, 1);
        console.log(old);
        setGallery(old);
    };

    const printPicture = (item) => {
        console.log(item);
        setGallery([...gallery, item]);
    };

    return (
        <div id="profile-page">
            {complete && <Redirect to={path} />}
            <Navbar filled dark />
            <div className="columns profile-container">
                <div className="column is-3"></div>
                <div className="column is-6">
                    <div className="card margin-top-double rounded">
                        <div className="card-content">
                            <div>
                                <h4 className="is-bold is-black">
                                    Write A Review
                                </h4>
                            </div>
                            <div className="row2 pad-top">
                                <div className="field">
                                    <h6>{target}</h6>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <h6 className="is-bold">First name</h6>
                                        <p className="control is-expanded">
                                            <input
                                                className={`input ${
                                                    attempted &&
                                                    !firstName &&
                                                    'is-danger'
                                                }`}
                                                type="text"
                                                placeholder="First"
                                                value={firstName}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                            />
                                            {attempted && !firstName && (
                                                <p className="help is-danger">
                                                    Please enter your first
                                                    name.
                                                </p>
                                            )}
                                        </p>
                                    </div>
                                    <div className="field">
                                        <h6 className="is-bold">Last name</h6>
                                        <p className="control is-expanded">
                                            <input
                                                className={`input ${
                                                    attempted &&
                                                    !lastName &&
                                                    'is-danger'
                                                }`}
                                                type="text"
                                                placeholder="Last"
                                                value={lastName}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                            />
                                            {attempted && !lastName && (
                                                <p className="help is-danger">
                                                    Please enter your last name.
                                                </p>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row3 pad-top">
                                <div className="field-body">
                                    <div className="field">
                                        <h6 className="is-bold">City</h6>
                                        <p className="control is-expanded">
                                            <input
                                                className={`input ${
                                                    attempted &&
                                                    !city &&
                                                    'is-danger'
                                                }`}
                                                type="text"
                                                placeholder=""
                                                value={city}
                                                onChange={(e) =>
                                                    setCity(e.target.value)
                                                }
                                            />
                                            {attempted && !firstName && (
                                                <p className="help is-danger">
                                                    Please type in your city.
                                                </p>
                                            )}
                                        </p>
                                    </div>
                                    <div className="field">
                                        <h6 className="is-bold">State</h6>
                                        <div className="control is-expanded">
                                            <div className="field">
                                                <div
                                                    className={`control select is-expanded ${
                                                        attempted &&
                                                        !state &&
                                                        'is-danger'
                                                    }`}>
                                                    <select
                                                        value={state}
                                                        onChange={(e) =>
                                                            setState(
                                                                e.target.value
                                                            )
                                                        }>
                                                        <option value="AL">
                                                            Alabama
                                                        </option>
                                                        <option value="AK">
                                                            Alaska
                                                        </option>
                                                        <option value="AZ">
                                                            Arizona
                                                        </option>
                                                        <option value="AR">
                                                            Arkansas
                                                        </option>
                                                        <option value="CA">
                                                            California
                                                        </option>
                                                        <option value="CO">
                                                            Colorado
                                                        </option>
                                                        <option value="CT">
                                                            Connecticut
                                                        </option>
                                                        <option value="DE">
                                                            Delaware
                                                        </option>
                                                        <option value="DC">
                                                            District Of Columbia
                                                        </option>
                                                        <option value="FL">
                                                            Florida
                                                        </option>
                                                        <option value="GA">
                                                            Georgia
                                                        </option>
                                                        <option value="HI">
                                                            Hawaii
                                                        </option>
                                                        <option value="ID">
                                                            Idaho
                                                        </option>
                                                        <option value="IL">
                                                            Illinois
                                                        </option>
                                                        <option value="IN">
                                                            Indiana
                                                        </option>
                                                        <option value="IA">
                                                            Iowa
                                                        </option>
                                                        <option value="KS">
                                                            Kansas
                                                        </option>
                                                        <option value="KY">
                                                            Kentucky
                                                        </option>
                                                        <option value="LA">
                                                            Louisiana
                                                        </option>
                                                        <option value="ME">
                                                            Maine
                                                        </option>
                                                        <option value="MD">
                                                            Maryland
                                                        </option>
                                                        <option value="MA">
                                                            Massachusetts
                                                        </option>
                                                        <option value="MI">
                                                            Michigan
                                                        </option>
                                                        <option value="MN">
                                                            Minnesota
                                                        </option>
                                                        <option value="MS">
                                                            Mississippi
                                                        </option>
                                                        <option value="MO">
                                                            Missouri
                                                        </option>
                                                        <option value="MT">
                                                            Montana
                                                        </option>
                                                        <option value="NE">
                                                            Nebraska
                                                        </option>
                                                        <option value="NV">
                                                            Nevada
                                                        </option>
                                                        <option value="NH">
                                                            New Hampshire
                                                        </option>
                                                        <option value="NJ">
                                                            New Jersey
                                                        </option>
                                                        <option value="NM">
                                                            New Mexico
                                                        </option>
                                                        <option value="NY">
                                                            New York
                                                        </option>
                                                        <option value="NC">
                                                            North Carolina
                                                        </option>
                                                        <option value="ND">
                                                            North Dakota
                                                        </option>
                                                        <option value="OH">
                                                            Ohio
                                                        </option>
                                                        <option value="OK">
                                                            Oklahoma
                                                        </option>
                                                        <option value="OR">
                                                            Oregon
                                                        </option>
                                                        <option value="PA">
                                                            Pennsylvania
                                                        </option>
                                                        <option value="RI">
                                                            Rhode Island
                                                        </option>
                                                        <option value="SC">
                                                            South Carolina
                                                        </option>
                                                        <option value="SD">
                                                            South Dakota
                                                        </option>
                                                        <option value="TN">
                                                            Tennessee
                                                        </option>
                                                        <option value="TX">
                                                            Texas
                                                        </option>
                                                        <option value="UT">
                                                            Utah
                                                        </option>
                                                        <option value="VT">
                                                            Vermont
                                                        </option>
                                                        <option value="VA">
                                                            Virginia
                                                        </option>
                                                        <option value="WA">
                                                            Washington
                                                        </option>
                                                        <option value="WV">
                                                            West Virginia
                                                        </option>
                                                        <option value="WI">
                                                            Wisconsin
                                                        </option>
                                                        <option value="WY">
                                                            Wyoming
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            {attempted && !state && (
                                                <p className="help is-danger">
                                                    Please enter your state.
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h6 className="is-bold pad-top">
                                    Overal Rating
                                </h6>
                                <StarRatings
                                    rating={numStars}
                                    changeRating={setNumStars}
                                    starHoverColor="#F5D04D"
                                    starRatedColor="#F5D04D"
                                    starEmptyColor="#E0E0E0"
                                    starDimension="25px"
                                    starSpacing="4px"
                                />
                                {attempted && !numStars && (
                                    <p className="help is-danger">
                                        Please select the number of stars.
                                    </p>
                                )}
                            </div>
                            <div className="field pad-top">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignitems: 'center',
                                    }}>
                                    <h6 className="is-bold">
                                        Title of your review
                                    </h6>
                                    <div className="pad-left"> (optional)</div>
                                </div>
                                <p className="control is-expanded">
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="Summarize your experience or highlight a detail"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                </p>
                            </div>
                            <div className="field pad-top">
                                <h6 className="is-bold">Your Review</h6>
                                <p className="control is-expanded">
                                    <textarea
                                        className={`textarea ${
                                            attempted && !descr && 'is-danger'
                                        }`}
                                        type="text"
                                        placeholder="Tell us about your experience"
                                        value={descr}
                                        onChange={(e) =>
                                            setDescr(e.target.value)
                                        }
                                    />
                                    {attempted && !descr && (
                                        <p className="help is-danger">
                                            Please type in your review.
                                        </p>
                                    )}
                                </p>
                            </div>
                            <PhotoGalleryCard
                                onSuccess={printPicture}
                                onDelete={deleteGalleryPhoto}
                                gallery={gallery}
                                username={username1}
                            />
                            <div className="bottom-buttons pad-top-double">
                                <div></div>
                                <div>
                                    <Link to={path}>
                                        <button
                                            className="button card-button"
                                            style={{ marginRight: '.5rem' }}>
                                            {'Cancel'}
                                        </button>
                                    </Link>
                                    <button
                                        className="button is-primary card-button"
                                        onClick={submitForm}>
                                        {'Post Review'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
