import React from 'react';
import { Navbar } from './shared/Navbar';

export const ErrorPage = (props) => {
    return (
        <div className="error-page">
            <Navbar />
            <div className="container pad-top">
                <h1 className="is-bold center-align pad-top">404</h1>
                <h2 className="center-align pad-top">Page Not Found</h2>
            </div>
        </div>
    );
};
