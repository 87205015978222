import React from 'react';
import { axiosInstance } from '../../../axios';
import { useState } from 'react';
import { Loader } from '../Loader';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const ConnectionsCard = (props) => {
    const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchRequests = async () => {
        setIsLoading(true);
        try {
            const res = await axiosInstance.get('/profile/connections');
            setRequests(res.data);
        } catch (e) {}
        setIsLoading(false);
    };

    useEffect(() => {
        fetchRequests();
    }, []);
    return (
        <Loader isLoading={isLoading} style={{ height: '200px' }}>
            <div>
                <h5 className="is-medium pad-top">
                    {requests.length}{' '}
                    {`${requests.length === 1 ? 'Connection' : 'Connections'}`}
                </h5>
                <ul className="profile-connection-list pad-top">
                    {requests.map((item, i) => (
                        <div
                            key={item}
                            className={`connection-item ${
                                i === requests.length - 1 && `last`
                            }`}>
                            <div className="connection-info">
                                <div
                                    className="connection-img-container"
                                    style={{
                                        backgroundImage: `url(${item.photo})`,
                                    }}></div>
                                <div className="connection-name">
                                    <a href={`/${item.username}`}>
                                        <p className="is-medium">{item.name}</p>
                                    </a>
                                    {item.tagline && (
                                        <p className="is-small">
                                            {item.tagline}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="connection-actions">
                                <div className="buttons">
                                    <button className="button is-primary is-small">
                                        Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </ul>
            </div>
        </Loader>
    );
};
