import React from 'react';
import '../../styles/footer.scss';

export const Footer = (props) => {
    return (
        <div className={`arch-footer`}>
            <div className="container">
                <div className="columns">
                    <div className="column is-full-mobile is-one-quarter">
                        <a href="/">
                            <h3 className="is-medium is-white">YourTeeth</h3>
                        </a>
                    </div>
                    <div className="column is-one-fifth">
                        <ul>
                            <li>
                                <h4 className="bold">Services</h4>
                            </li>
                            <li className="pad-top-half">
                                <a href="/services">Cleaning</a>
                            </li>
                            <li className="pad-top-half">
                                <a href="/services">Whitening</a>
                            </li>
                            <li className="pad-top-half">
                                <a href="/services">Smile scan</a>
                            </li>
                        </ul>
                    </div>
                    <div className="column is-one-fifth">
                        <ul>
                            <li>
                                <h4 className="bold">Support</h4>
                            </li>
                            <li className="pad-top-half">
                                <a href="/FAQ">FAQ</a>
                            </li>
                            <li className="pad-top-half">
                                <a href="/">Terms {'&'} Privacy</a>
                            </li>
                            <li className="pad-top-half">
                                <a href="/">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="column is-one-fifth">
                        <ul>
                            <li>
                                <h4 className="bold">Company</h4>
                            </li>
                            <li className="pad-top-half">
                                <a href="/">About</a>
                            </li>
                            <li className="pad-top-half">
                                <a href="/whyhu">Why YourTeeth?</a>
                            </li>
                            <li className="pad-top-half">
                                <a href="/">Careers</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
