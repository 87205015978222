import React, { useState, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import moment from 'moment';
import { Link, useHistory, Redirect } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

import { axiosInstance } from '../../../axios';
import Modal from '../../shared/Modal';
import { AutocompleteLocationField } from '../../FormFields';
import { Loader } from '../../profile/Loader';
import { validateEmail, validatePhone, validatePassword } from '../../../utils';
import { isAuthenticated, isProvider, login } from '../../../auth';
import parser from 'parse-address';

export const Details = (props) => {
    const [date, setDate] = useState(moment().startOf('day').toDate());
    const [openModal, setOpenModal] = useState(
        !isAuthenticated() || isProvider()
    );
    const [typeIdx, setTypeIdx] = useState(-1);
    const [roomNumber, setRoomNumber] = useState('');
    const [location, setLocation] = useState();
    const [fetching, setFetching] = useState(true);
    const [times, setTimes] = useState([]);
    const [hygienist, setHygienist] = useState(-1);
    const [selectedTime, setSelectedTime] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [signUp, setSignUp] = useState(true);
    const [special, setSpecial] = useState('');

    const type = ['Home', 'Apartment', 'Office', 'Hotel', 'Other'];
    const history = useHistory();

    const onDateSelect = (d) => {
        setDate(moment(d).startOf('day').toDate());
    };

    const fetchAvailability = async () => {
        setFetching(true);
        try {
            const res = await axiosInstance.get(
                `/appointments/find?date=${moment(date).format()}&length=${
                    props.apptType === 'bundle' ? 120 : 60
                }`
            );

            setTimes(res.data);
        } catch (e) {
            console.log(e);
        }
        setFetching(false);
    };

    const parseMinFromMid = (min) => {
        let hours = Math.floor(min / 60);
        const mins = min - hours * 60;
        const am = hours < 12;
        hours = hours === 0 ? 12 : hours;

        return `${am ? hours : hours - 12 == 0 ? 12 : hours - 12}:${
            mins < 10 ? '0' + mins : mins
        } ${am ? 'AM' : 'PM'}`;
    };

    const selectTime = (h, t) => {
        setHygienist(h);
        setSelectedTime(t);
    };

    const isStateValid = () => {
        return parser.parseLocation(location.address).state === 'PA';
    };

    useEffect(() => {
        selectTime(-1, -1);
        fetchAvailability();
    }, [date]);

    const isFormSubmitable = () => {
        return (
            location &&
            typeIdx !== -1 &&
            hygienist !== -1 &&
            selectedTime !== -1 &&
            isStateValid()
        );
    };

    const payload = () => {
        return {
            patients: props.patients.map((item) => {
                return {
                    first_name: item.first,
                    last_name: item.last,
                    service: item.service,
                    email: item.email,
                };
            }),
            duration: props.patients[0].service === 'bundle' ? 120 : 60,
            address1: location.address,
            address2: roomNumber,
            address_type: type[typeIdx],
            provider_id: times[hygienist].provider_id,
            date: moment(date).format(),
            time: times[hygienist].slots.start_times[selectedTime],
            special: special,
        };
    };

    const submitForm = async () => {
        if (!isFormSubmitable()) return;

        setIsLoading(true);

        // try {
        //     const res = await axiosInstance.post(
        //         '/appointments/book',
        //         payload()
        //     );

        //     const sessionId = res.data.session_id;

        //     const stripe = await loadStripe(
        //         'pk_live_1NBNGEeqHEJAB8AEENRxfGCG00tCAyJz0W'
        //     );
        //     stripe.redirectToCheckout({ sessionId: sessionId });
        // } catch (e) {
        //     console.log(e.message);
        //     setIsLoading(false);
        // }
        props.onComplete(payload());

        history.push('/appointment/book/health');
    };

    if (props.patients && props.patients.length === 0) {
        history.push('/appointment/book');
        return null;
    }

    return (
        <div>
            <Modal open={openModal} toggle={() => setOpenModal(!openModal)}>
                {signUp ? (
                    <SignUpForm
                        toggle={() => setOpenModal(!openModal)}
                        onLogin={() => setSignUp(false)}
                    />
                ) : (
                    <LoginForm
                        toggle={() => setOpenModal(isProvider())}
                        onSignUp={() => setSignUp(true)}
                    />
                )}
            </Modal>
            <div className="hu-hero">
                <p className="accent-underline">Step 3 of 4</p>
                <h3 className="is-bold pad-top-half">Appointment Details</h3>
            </div>
            <div className="container pad-bottom-double">
                <div className="pad-top-double">
                    <h6 className="is-bold pad-bottom-half">LOCATION</h6>
                    <label>Address</label>
                    <AutocompleteLocationField
                        onSelect={setLocation}
                        blockUserInput
                    />
                    {location && location.address && !isStateValid() && (
                        <p className="help is-danger">
                            Unfortunately, we can only provide in-home service
                            in the state of PA.
                        </p>
                    )}
                    <label className="margin-top">Type</label>
                    <div className="buttons margin-bottom">
                        {type.map((item, idx) => (
                            <div
                                className={`button is-info ${
                                    idx !== typeIdx && 'is-outlined'
                                } `}
                                onClick={() => setTypeIdx(idx)}>
                                {item}
                            </div>
                        ))}
                    </div>
                    <label>Room Number</label>
                    <input
                        className="input margin-bottom"
                        value={roomNumber}
                        onChange={(e) => setRoomNumber(e.target.value)}
                        placeholder="Apt, Unit, or Hotel Number"
                    />
                </div>
                <div className="pad-top-double">
                    <h6 className="is-bold pad-bottom-half">DATE AND TIME</h6>
                    <div className="columns">
                        <div className="column is-5">
                            <Calendar value={date} onChange={onDateSelect} />
                        </div>
                        <div className="column is-7">
                            <Loader
                                isLoading={fetching}
                                style={{ height: '200px' }}>
                                {times.length === 0 && (
                                    <div className="center-flex pad-top-double">
                                        <p className="is-medium center-align pad-top-double">
                                            Unfortunately, no providers are
                                            available for the selected date.
                                            Please try another date.
                                        </p>
                                    </div>
                                )}
                                {times.map((item, idx) => (
                                    <div className="card rounded border padded margin-bottom">
                                        <Link
                                            to={`/${item.username}`}
                                            target="_blank">
                                            <div className="provider-card-top">
                                                <div
                                                    className="provider-img margin-top-small"
                                                    style={{
                                                        backgroundImage: `url(${item.photo})`,
                                                        alignItems: 'center',
                                                    }}></div>
                                                <div className="provider-card-name pad-left">
                                                    <p className="is-bold pad-top-half">
                                                        {item.first_name}{' '}
                                                        {item.last_name}
                                                    </p>
                                                    <StarRatings
                                                        rating={item.rating}
                                                        starRatedColor="#F5D04D"
                                                        starEmptyColor="#E0E0E0"
                                                        starDimension="20px"
                                                        starSpacing="2px"
                                                    />
                                                    <span>
                                                        ({item.review_count})
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="buttons margin-top">
                                            {item.slots.start_times.map(
                                                (start, t) => (
                                                    <div
                                                        key={start}
                                                        className={`button is-info ${
                                                            idx === hygienist &&
                                                            t === selectedTime
                                                                ? undefined
                                                                : 'is-outlined'
                                                        } is-small`}
                                                        onClick={() =>
                                                            selectTime(idx, t)
                                                        }>
                                                        {parseMinFromMid(start)}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </Loader>
                        </div>
                    </div>
                </div>
                <div className="pad-top-double">
                    <h6 className="is-bold pad-bottom-half">
                        SPECIAL INSTRUCTIONS
                    </h6>
                    <textarea
                        className="textarea"
                        placeholder="Hard to find? Doorbell not working? Let us know here."
                        value={special}
                        onChange={(e) => setSpecial(e.target.value)}
                    />
                </div>
                <div className="columns pad-top-double">
                    <div className="column is-6 is-full-mobile center">
                        <div className="pad-bottom-double center-flex">
                            <div
                                className={`button is-primary ${
                                    isLoading && 'is-loading'
                                }
                        `}
                                onClick={submitForm}
                                disabled={!isFormSubmitable() || isLoading}>
                                Continue to Medical History
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SignUpForm = (props) => {
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [emailAvail, setEmailAvail] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPass] = useState('');
    const [viewPass, toggleViewPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [complete, setComplete] = useState(false);

    const checkEmail = async () => {
        if (validateEmail(email)) {
            setIsLoading(true);
            const res = await axiosInstance.get(
                `/onboard/email?email=${email}`
            );
            setEmailValid(true);
            setEmailAvail(res.data.is_avail);
            setIsLoading(false);
            return;
        }

        setEmailValid(false);
    };

    useEffect(() => {
        checkEmail();
    }, [email]);

    const isFormSubmitable = () => {
        return (
            first &&
            last &&
            email &&
            emailValid &&
            emailAvail &&
            phone &&
            validatePhone(phone) &&
            password &&
            validatePassword(password)
        );
    };

    const payload = {
        first_name: first,
        last_name: last,
        email,
        phone,
        password,
    };

    const submitForm = async () => {
        if (!isFormSubmitable()) return;

        try {
            const res = await axiosInstance.post(
                '/onboard/customer/signup',
                payload
            );
            login(res.data);
            props.toggle();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <h4 className="is-medium center-align">Sign Up</h4>
            <div className="field pad-top">
                <p className="is-small">Name</p>
                <input
                    className="input"
                    value={first}
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => setFirst(e.target.value)}
                />
                <input
                    className="input margin-top-half"
                    value={last}
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => setLast(e.target.value)}
                />
                <p className="is-small pad-top">Email</p>
                <input
                    className={`input ${
                        (!emailAvail || !emailValid) && email && 'is-danger'
                    }`}
                    placeholder="email@domain.com"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {email &&
                    (!emailValid ? (
                        <p className="help is-danger">
                            Please enter a valid email
                        </p>
                    ) : (
                        !emailAvail && (
                            <p className="help is-danger">
                                This email is already in use
                            </p>
                        )
                    ))}

                <p className="is-small pad-top">Phone</p>
                <input
                    className={`input ${
                        !validatePhone(phone) && phone && 'is-danger'
                    }`}
                    value={phone}
                    type="tel"
                    placeholder="000-000-0000"
                    onChange={(e) => setPhone(e.target.value)}
                />
                {phone && !validatePhone(phone) && (
                    <p className="help is-danger">
                        Please enter a valid phone number.
                    </p>
                )}

                <div className="field has-right-icons">
                    <p className="is-small pad-top">Password</p>
                    <div className="control has-icons-right">
                        <input
                            className={`input`}
                            type={viewPass ? 'text' : 'password'}
                            placeholder=""
                            value={password}
                            onChange={(e) => {
                                setPass(e.target.value);
                            }}
                        />

                        <p
                            className={`help ${
                                password &&
                                !validatePassword(password) &&
                                'is-danger'
                            }`}>
                            Minimum of 8 characters
                            <br />
                            Must contain a lowercase and uppercase letter,
                            <br />
                            number, and at least one symbol.
                        </p>

                        <span
                            className="icon is-small is-right"
                            onClick={() => toggleViewPass(!viewPass)}>
                            <i className="material-icons">
                                {viewPass ? 'visibility' : 'visibility_off'}
                            </i>
                        </span>
                    </div>
                </div>

                <div
                    className={`button is-fullwidth is-primary margin-top margin-bottom ${
                        isLoading ? 'is-loading' : undefined
                    }`}
                    disabled={!isFormSubmitable()}
                    onClick={submitForm}>
                    Sign Up
                </div>
                <hr className="dropdown-divider" />
                <p className="is-small center-align">
                    Already have an account?{' '}
                    <a onClick={props.onLogin}>Log in here</a>.
                </p>
            </div>
        </div>
    );
};

export const LoginForm = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const { redirectOnSuccess } = props;
    const [isLoading, setIsLoading] = useState(false);

    const loginUser = async () => {
        const payload = new FormData();
        payload.append('username', email);
        payload.append('password', password);
        try {
            setIsLoading(true);
            const res = await axiosInstance.post('/auth/login', payload);
            const account = res.data;
            login(account);
            setIsLoading(false);
            props.toggle();
        } catch (e) {
            setIsLoading(false);
            const error = e.response?.data?.detail;
            setLoginError(error);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            loginUser();
        }
    };

    return (
        <div>
            <h4 className="is-medium center-align">Login</h4>
            <div className="field pad-top">
                <p className="is-small">Email</p>
                <div className="control">
                    <input
                        className="input"
                        type="text"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </div>

            <div className="field">
                <p className="is-small">Password</p>
                <div className="control">
                    <input
                        className="input"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {loginError && <p className="help is-danger">{loginError}</p>}
            </div>
            <div
                className={`button is-primary is-fullwidth margin-top ${
                    isLoading ? 'is-loading' : undefined
                }`}
                disabled={!(email && password)}
                onClick={loginUser}>
                Login
            </div>
            <hr className="dropdown-divider" />
            <p className="is-small center-align">
                Don't have an account?{' '}
                <a onClick={props.onSignUp}>Sign up here</a>.
            </p>
        </div>
    );
};
