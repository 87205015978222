import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Navbar } from '../shared/Navbar';
import '../../styles/landing.scss';
import { Footer } from '../shared/Footer';
import { MailchimpField } from '../shared/MailchimpField';

export const LandingPage = () => {
    return (
        <div id="landing-page">
            <Helmet>
                <title>YourTeeth</title>
            </Helmet>
            <Navbar links />
            <section className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="columns is-centered is-center-horizontal">
                            <div className="column is-6 is-full-mobile">
                                <h1 className="is-bold is-white is-text-centered">
                                    Humans treat humans.
                                </h1>
                                <h1 className="is-bold is-white is-text-centered">
                                    We bring the dentist to you.
                                </h1>
                                <h5 className="pad-top-half is-white is-text-centered">
                                    In-home dental cleanings, doctor’s exams,
                                    and teeth whitening. Anytime, anywhere.
                                </h5>
                                <div className="buttons center-flex">
                                    <Link to="/appointment/book">
                                        <button className="button is-medium is-white margin-top-double">
                                            <h5 className="is-bold is-black">
                                                Book Now
                                            </h5>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body main">
                <div className="container">
                    <h3 className="is-bold has-text-centered is-dark-grey">
                        YourTeeth offers the perfect dental experience in the
                        comfort of your own home.
                    </h3>
                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/dont_miss_a_beat.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                            <h4 className="is-bold has-text-centered pad-top">
                                Don't miss a beat
                            </h4>
                            <p className="pad-top-half center-align">
                                The dentist comes to you. Keep working,
                                studying, or relaxing in your home while a Hu
                                provider treats you wherever you’re most
                                comfortable.
                            </p>
                        </div>
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/quality_care.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                            <h4 className="is-bold has-text-centered pad-top">
                                Quality, clean care
                            </h4>
                            <p className="pad-top-half center-align">
                                YourTeeth dental professionals are equipped with
                                the highest quality tools and employ leading
                                sanitation protocols to provide the best
                                possible care.
                            </p>
                        </div>
                        <div className="column">
                            <div className="center column-img">
                                <img
                                    className="img-fluid"
                                    src="img/expert_prof.svg"
                                    alt="Arch Dental"
                                />
                            </div>
                            <h4 className="is-bold has-text-centered pad-top">
                                Expert Professionals
                            </h4>
                            <p className="pad-top-half center-align">
                                Every dental professional is verified by
                                YourTeeth and put through rigorous testing
                                before being accepted into the YourTeeth
                                network.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="body grey">
                <div className="container">
                    <h2 className="is-bold has-text-centered is-dark-grey">
                        How it Works
                    </h2>
                    <div className="columns pad-top-double">
                        <div className="column">
                            <div className="center column-img pad-bottom">
                                <img
                                    className="img-fluid pad-left"
                                    src="img/step1.svg"
                                    alt="Arch Dental"
                                    style={{ height: '100px' }}
                                />
                            </div>
                            <p className="pad-top-half center-align">
                                Select which services to book from an easy a la
                                carte menu
                            </p>
                        </div>
                        <div className="column">
                            <div className="center column-img pad-bottom">
                                <img
                                    className="img-fluid"
                                    src="img/step2.svg"
                                    alt="Arch Dental"
                                    style={{ height: '100px' }}
                                />
                            </div>
                            <p className="pad-top-half center-align">
                                Set your location and preferred appointment date
                            </p>
                        </div>
                        <div className="column">
                            <div className="center column-img pad-bottom">
                                <img
                                    className="img-fluid"
                                    src="img/step3.svg"
                                    alt="Arch Dental"
                                    style={{ height: '100px' }}
                                />
                            </div>
                            <p className="pad-top-half center-align">
                                Choose from a selection of available YT-verified
                                hygienists and select a time
                            </p>
                        </div>
                        <div className="column">
                            <div className="center column-img pad-bottom">
                                <img
                                    className="img-fluid"
                                    src="img/step4.svg"
                                    alt="Arch Dental"
                                    style={{ height: '100px' }}
                                />
                            </div>
                            <p className="pad-top-half center-align">
                                Sit back, relax, and let the YourTeeth team come
                                to you!
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="body white">
                <div className="container pad-top-double pad-bottom-double">
                    <div className="columns is-centered is-center-horizontal">
                        <div className="column is-6 is-full-mobile">
                            <h1 className="is-bold is-black is-text-centered">
                                Be the first to hear about the launch
                            </h1>
                            <h5 className="pad-top-half is-text-centered">
                                Join our wait list and receive priority access
                                to book your
                            </h5>
                            <h5 className="pad-top-half is-text-centered">
                                appointment through YourTeeth!
                            </h5>
                            <MailchimpField />
                        </div>
                    </div>
                </div>
            </section> */}
            <div>
                <Footer />
            </div>
        </div>
    );
};
