import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Navbar } from './shared/Navbar';
import { axiosInstance } from '../axios';
import '../styles/login.scss';
import { login, isAuthenticated } from '../auth';

export const Login = () => {
    return (
        <div className="register-page">
            <Helmet>
                <title>Login - YourTeeth</title>
            </Helmet>
            <Navbar links />
            <div className="container">
                <LoginForm redirectOnSuccess="/appointments" />
            </div>
        </div>
    );
};

export const LoginForm = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const { redirectOnSuccess } = props;
    const [isLoading, setIsLoading] = useState(false);

    const loginUser = async () => {
        const payload = new FormData();
        payload.append('username', email);
        payload.append('password', password);
        try {
            setIsLoading(true);
            const res = await axiosInstance.post('/auth/login', payload);
            const account = res.data;
            login(account);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            const error = e.response?.data?.detail;
            setLoginError(error);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            loginUser();
        }
    };

    return (
        <div>
            {isAuthenticated() ? (
                <Redirect to={redirectOnSuccess} />
            ) : (
                <div className="columns center-flex">
                    <div className="column login-form">
                        <div className="login-page">
                            <h3 className="is-bold">Login</h3>
                        </div>
                        <div className="card card-border">
                            <div className="card-content">
                                <Tabs>
                                    <TabList>
                                        <Tab>Provider</Tab>
                                        <Tab>Patient</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="field pad-top">
                                            <label className="label">
                                                Username
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="Username"
                                                    onChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="field">
                                            <label className="label">
                                                Password
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    type="password"
                                                    onChange={(e) =>
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                            {loginError && (
                                                <p className="help is-danger">
                                                    {loginError}
                                                </p>
                                            )}
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="field pad-top">
                                            <label className="label">
                                                Email
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    type="text"
                                                    placeholder="Email"
                                                    onChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="field">
                                            <label className="label">
                                                Password
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    type="password"
                                                    onChange={(e) =>
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                            {loginError && (
                                                <p className="help is-danger">
                                                    {loginError}
                                                </p>
                                            )}
                                        </div>
                                    </TabPanel>
                                </Tabs>

                                <div className="bottom-buttons pad-top">
                                    <button
                                        className={`button is-primary ${
                                            isLoading ? 'is-loading' : undefined
                                        }`}
                                        disabled={!(email && password)}
                                        onClick={loginUser}>
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
