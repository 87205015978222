import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { axiosInstance, currentUrl } from '../../../axios';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { useEffect } from 'react';

export const ProviderNetwork = (props) => {
    const platforms = [
        'Facebook',
        'Youtube',
        'Instagram',
        'Tik Tok',
        'Snapchat',
    ];
    const [social, setSocial] = useState([false, false, false, false, false]);
    const [link, setLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const history = useHistory();

    const toggleSocial = (idx) => {
        const copy = [...social];
        copy[idx] = !social[idx];
        setSocial(copy);
    };

    const payload = () => {
        return {
            platforms: platforms.filter((item, idx) => social[idx]),
            primary_link: link,
        };
    };

    const submitForm = async () => {
        setIsLoading(true);
        // window.location.replace(`${currentUrl}​/onboard​/provider​/payments`);
        try {
            const res = await axiosInstance.post(
                '/onboard/provider/network',
                payload()
            );
            window.location.assign(res.data);
            setComplete(true);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    };
    return (
        <div>
            {complete && <Redirect to="" />}
            <div className="hu-hero">
                <p className="accent-underline">Step 3 of 3</p>
                <h3 className="is-bold pad-top-half">Network</h3>
            </div>
            <div className="pad-top-double container">
                <div className="pad-bottom">
                    <h6 className="is-medium">
                        What social media platform(s) do you use regularly?
                    </h6>
                    <p className="is-small">Select all that apply</p>
                    <FormGroup>
                        {platforms.map((item, idx) => (
                            <FormControlLabel
                                label={platforms[idx]}
                                control={
                                    <Checkbox
                                        className="arch-checkbox"
                                        checked={social[idx]}
                                        onChange={() => toggleSocial(idx)}
                                    />
                                }></FormControlLabel>
                        ))}
                    </FormGroup>
                </div>
                <div className="pad-bottom">
                    <h6 className="is-medium">
                        Please your primary social media handle where we may be
                        able to see your work or learn more about you
                    </h6>
                    <div className="columns pad-top">
                        <div className="column is-6 is-full-mobile">
                            <input
                                className="input"
                                placeholder="Link"
                                type="text"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="bottom-buttons margin-bottom">
                    <div></div>
                    <div
                        className={`button is-primary ${
                            isLoading ? 'is-loading' : ''
                        }`}
                        onClick={submitForm}
                        disabled={isLoading}>
                        Continue to Payment Setup
                    </div>
                </div>
            </div>
        </div>
    );
};
